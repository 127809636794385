export const formatDate = (inputDateString, isYear = true) => {
  const inputDate = new Date(inputDateString);
  const year = inputDate.getFullYear();
  const month = inputDate.toLocaleString("default", { month: "short" });
  const day = inputDate.getDate();
  if (!isYear) {
    return `${month} ${day}`;
  }
  return `${year} ${month} ${day}`;
};

//7-1-2024 to Jul 1, 2024
export const formatDateString = (inputDateString, isYear = true) => {
  if (!inputDateString) {
    return;
  }
  const dateParts = inputDateString.split("/");
  if (dateParts.length !== 3) {
    console.error("Input date string is not in the expected format.");
    return "Invalid date";
  }
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    console.error("Invalid date string provided:", inputDateString);
    return "Invalid date";
  }
  const inputDate = new Date(year, month - 1, day);

  if (isNaN(inputDate.getTime())) {
    console.error("Invalid date string provided:", inputDateString);
    return "Invalid date";
  }

  const monthName = inputDate.toLocaleString("default", { month: "short" });

  if (!isYear) {
    return `${monthName}${day}`;
  }

  return `${year} ${monthName} ${day}`;
};

//format date in 2024, Ju1 1
export const formatDateHeader = (inputDateString, lastYearPosition = false) => {
  if (inputDateString !== null) {
    const dateParts = inputDateString.split("/");
    if (dateParts.length !== 3) {
      console.error("Input date string is not in the expected format.");
      return "Invalid date";
    }
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    const inputDate = new Date(year, month - 1, day);

    if (isNaN(inputDate.getTime())) {
      console.error("Invalid date string provided:", inputDateString);
      return "Invalid date";
    }

    const monthName = inputDate.toLocaleString("default", { month: "short" });
    if (lastYearPosition) {
      return `${monthName} ${day}, ${year}`;
    }
    return `${year}, ${monthName}${day}`;
  }
  return null;
};

//formated date in 1-2-2024
export const storeFormatDate = (inputDate) => {
  if (inputDate !== null) {
    const year = inputDate.getFullYear().toString().padStart(4, "0"); // Ensure 4 digits
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-indexed
    const day = inputDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  }
  return null;
};
