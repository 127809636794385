export const Popup = ({ children, classes }) => {
  return <div className={classes}>{children}</div>;
};

export const PopupTrigger = ({ children, classes, onClick }) => {
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export const PopupContent = ({ isShow, classes, children }) => {
  if (!isShow) return null;
  return <div className={classes}>{children}</div>;
};
