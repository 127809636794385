export const TabList = ({ children, classes }) => {
  return <div className={`w-full h-full ${classes}`}>{children}</div>;
};

export const TabTrigger = ({ onClick, children, classes }) => {
  return (
    <button className={`w-full h-full ${classes}`} onClick={onClick}>
      {children}
    </button>
  );
};

export const TabContent = ({ isShow, children }) => {
  if (isShow) return <div className="relative w-full h-full">{children}</div>;
};
