import AirAsia from "../assets/icons/air-asia.svg";
import Mai from "../assets/icons/mai-logo.svg";
import ChinaEastern from "../assets/icons/china_eastern.svg";
import { nrcData } from "../data/nrc_data";
import { storeFormatDate } from "./format";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const chooseLogo = (airline) => {
  if (airline === "Air Asia") {
    return <img src={AirAsia} alt="air-asia" className="w-full h-full" />;
  } else if (airline === "China Eastern") {
    return <img src={ChinaEastern} alt="mai" className="w-full h-full" />;
  } else {
    return <img src={Mai} alt="mai" className="w-full h-full" />;
  }
};

export const getDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();

  const difference = Math.abs(end - start);

  const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));

  const dates = [];
  for (let i = 0; i <= daysDifference; i++) {
    const currentDate = new Date(start + i * 1000 * 3600 * 24);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Add 1 because getMonth() returns 0-based index
    const day = currentDate.getDate().toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    const formattedDate = `${year}/${month}/${day}`;
    dates.push(formattedDate);
  }

  return dates;
};

//round trip
export const daysFormatForSelectedDates = (startDate, endDate) => {
  const ISFLIGHT = [true, false];
  const COST = [100, 200, 300];
  const PRICE = ["usd", "mmk", "rmb"];

  const dayArrays = getDaysBetweenDates(startDate, endDate);
  return dayArrays?.map((item, index) => {
    const randomIndex = Math.floor(Math.random() * 3);
    return {
      date: item,
      type: PRICE[randomIndex],
      cost: COST[randomIndex],
      isFlight:
        index % 2 === 0 || index + 1 === dayArrays?.length || index === 5
          ? ISFLIGHT[0]
          : ISFLIGHT[1],
    };
  });
};

//for one way
export const getDate = (dateString) => {
  const parts = dateString.split("/");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);
  const date = new Date(year, month - 1, day);

  let fiveDaysBefore = new Date(date);
  fiveDaysBefore.setDate(date.getDate() - 5);
  let fiveDaysAfter = new Date(date);
  fiveDaysAfter.setDate(date.getDate() + 5);
  fiveDaysBefore = storeFormatDate(fiveDaysBefore);
  fiveDaysAfter = storeFormatDate(fiveDaysAfter);
  return daysFormatForSelectedDates(fiveDaysBefore, fiveDaysAfter);
};

export const getNrcRegions = (nrcCode) => {
  const data = nrcData.filter((item) => item.nrc_code === nrcCode);
  const rebuild_data = data.map((item) => {
    return {
      label: item.name_mm,
      value: item.name_mm,
      name: "nrc_middle",
    };
  });
  return rebuild_data;
};
