import { quantity_picker_radio_buttons } from "./const";

export const airports = [
  {
    country: "Myanmar",
    country_code: "MM",
    city: "Yangon",
    name: "Yangon Airport",
    airport_code: "RGN",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Mandalay",
    name: "Mandalay Airport",
    airport_code: "MDL",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Naypyitaw",
    name: "Naypyitaw Airport",
    airport_code: "NYT",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Ann",
    name: "Ann Airport",
    airport_code: "VBA",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Nyaung U",
    name: "Bangan, Nyaung U Airport",
    airport_code: "NYU",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Bhamo",
    name: "Bhamo Airport",
    airport_code: "BMO",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Bokpyin",
    name: "Bokpyin Airport",
    airport_code: "VBP",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Dawei",
    name: "Dawei Airport",
    airport_code: "TVY",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Homalin",
    name: "Homalin Airport",
    airport_code: "HOX",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Inle, Heho",
    name: "Inle, Heho Airport",
    airport_code: "HEH",
  },
  {
    country: "Myanmar",
    country_code: "MM",
    city: "Kalaymyo",
    name: "Kalaymyo Airport",
    airport_code: "KMV",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Kawthaung",
    name: "Kawthaung Airport",
    airport_code: "KAW",
  },
  {
    country: "Myanmar",
    country_code: "MM",
    city: "Khamti",
    name: "Khamti Airport",
    airport_code: "KHM",
  },
  {
    country: "Myanmar",
    country_code: "MM",
    city: "Kyaingtong",
    name: "Kyaingtong Airport",
    airport_code: "KET",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Kyaukphyu",
    name: "Kyaukphyu Airport",
    airport_code: "KHM",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Lashio",
    name: "Lashio Airport",
    airport_code: "LSH",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Loikaw",
    name: "Loikaw Airport",
    airport_code: "LIW",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Magway",
    name: "Magway Airport",
    airport_code: "MWQ",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Manaung",
    name: "Manaung Airport",
    airport_code: "MGU",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Mawlamyine",
    name: "Mawlamyine Airport",
    airport_code: "MNU",
  },
  {
    country: "Myanmar",
    country_code: "MM",
    city: "Mong Hsat",
    name: "Mong Hsat Airport",
    airport_code: "MOG",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Monywa",
    name: "Monywa Airport",
    airport_code: "NYW",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Myeik",
    name: "Myeik Airport",
    airport_code: "MGZ",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Myitkyina",
    name: "Myitkyina Airport",
    airport_code: "MYT",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Thandwe",
    name: "Ngapali, Thandwe Airport",
    airport_code: "SNW",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Pathein",
    name: "Pathein Airport",
    airport_code: "BSX",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Putao",
    name: "Putao Airport",
    airport_code: "PBU",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Sittwe",
    name: "Sittwe Airport",
    airport_code: "AKY",
  },

  {
    country: "Myanmar",
    country_code: "MM",
    city: "Tachileik",
    name: "Tachileik Airport",
    airport_code: "THL",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Beijing",
    name: "Beijing Capital International Airport",
    airport_code: "PEK",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Chengdu",
    name: "Chengdu Shuangliu International Airport",
    airport_code: "CTU",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Shanghai",
    name: "Shanghai Pudong International Airport",
    airport_code: "PVG",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Fuzhou",
    name: "Fuzhou Changle International Airport",
    airport_code: "FOC",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Guangzhou",
    name: "Guangzhou Baiyun International Airport",
    airport_code: "CAN",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Jieyang",
    name: "Jieyang Chaoshan International Airport",
    airport_code: "SWA",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Jinan",
    name: "Jinan Yaoqiang International Airport",
    airport_code: "TNA",
  },

  {
    country: "China",
    country_code: "CN",
    city: "Kunming",
    name: "Kunming Changshui International Airport",
    airport_code: "KMG",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Ahmedabad",
    name: "Sardar Vallabhbhai Patel International Airport",
    airport_code: "AMD",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Chennai",
    name: "Chennai International Airport",
    airport_code: "MAA",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Mumbai",
    name: "Chhatrapati Shivaji Maharaj International Airport",
    airport_code: "BOM",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Kochi",
    name: "Cochin International Airport",
    airport_code: "COK",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Gaya",
    name: "Gaya Airport",
    airport_code: "GAY",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Hyderabad",
    name: "Rajiv Gandhi International Airport",
    airport_code: "HYD",
  },

  {
    country: "India",
    country_code: "IN",
    city: "New Delhi",
    name: "Indira Gandhi International Airport",
    airport_code: "DEL",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Jaipur",
    name: "Jaipur International Airport",
    airport_code: "JAI",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Kolkata",
    name: "Netaji Subhas Chandra Bose International Airport",
    airport_code: "CCU",
  },

  {
    country: "India",
    country_code: "IN",
    city: "Vadodara",
    name: "Vadodara Airport",
    airport_code: "BDQ",
  },

  {
    country: "Indonesia",
    country_code: "ID",
    city: "Denpasar",
    name: "Ngurah Rai International Airport",
    airport_code: "DPS",
  },

  {
    country: "Indonesia",
    country_code: "ID",
    city: "Surabaya",
    name: "Juanda International Airport",
    airport_code: "SUB",
  },

  {
    country: "Indonesia",
    country_code: "ID",
    city: "Jakarta",
    name: "Soekarno–Hatta International Airport",
    airport_code: "CGK",
  },

  {
    country: "Indonesia",
    country_code: "ID",
    city: "Yogyakarta",
    name: "Yogyakarta International Airport",
    airport_code: "YIA",
  },

  {
    country: "Italy",
    country_code: "IT",
    city: "Milan",
    name: "Milan Malpensa Airport",
    airport_code: "MXP",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Nagoya",
    name: "Chubu Centrair International Airport",
    airport_code: "NGO",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Fukuoka",
    name: "Fukuoka Airport",
    airport_code: "FUK",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Tokyo",
    name: "Tokyo Haneda Airport",
    airport_code: "HND",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Osaka",
    name: "Kansai International Airport",
    airport_code: "KIX",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Kushiro",
    name: "Kushiro Airport",
    airport_code: "KUH",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Tokyo",
    name: "Narita International Airport",
    airport_code: "NRT",
  },

  {
    country: "Japan",
    country_code: "JP",
    city: "Okinawa",
    name: "Naha Airport",
    airport_code: "OKA",
  },

  {
    country: "Kenya",
    country_code: "KE",
    city: "Nairobi",
    name: "Jomo Kenyatta International Airport",
    airport_code: "NBO",
  },

  {
    country: "Laos",
    country_code: "KE",
    city: "Luang Prabang",
    name: "Luang Prabang International Airport",
    airport_code: "LPQ",
  },

  {
    country: "Laos",
    country_code: "KE",
    city: "Vientiane",
    name: "Wattay International Airport",
    airport_code: "VTE",
  },

  {
    country: "Malaysia",
    country_code: "MY",
    city: "Johor Bahru",
    name: "Senai International Airport",
    airport_code: "JHB",
  },

  {
    country: "Malaysia",
    country_code: "MY",
    city: "Kuala Lumpur",
    name: "Kuala Lumpur International Airport",
    airport_code: "KUL",
  },

  {
    country: "Malaysia",
    country_code: "MY",
    city: "Langkawi",
    name: "Langkawi International Airport",
    airport_code: "LGK",
  },

  {
    country: "Malaysia",
    country_code: "MY",
    city: "Miri",
    name: "Miri Airport",
    airport_code: "MYY",
  },

  {
    country: "Malaysia",
    country_code: "MY",
    city: "Penang",
    name: "Penang International Airport",
    airport_code: "PEN",
  },

  {
    country: "Nepal",
    country_code: "NP",
    city: "Kathmandu",
    name: "Tribhuvan International Airport",
    airport_code: "KTM",
  },

  {
    country: "Netherlands",
    country_code: "NL",
    city: "Amsterdam",
    name: "Amsterdam Airport Schiphol",
    airport_code: "AMS",
  },

  {
    country: "New Zealand",
    country_code: "NZ",
    city: "Christchurch",
    name: "Christchurch International Airport",
    airport_code: "CHC",
  },

  {
    country: "New Zealand",
    country_code: "NZ",
    city: "Nelson",
    name: "Nelson Airport",
    airport_code: "NSN",
  },

  {
    country: "Nigeria",
    country_code: "NG",
    city: "Lagos",
    name: "Murtala Muhammed International Airport",
    airport_code: "LOS",
  },

  {
    country: "Pakistan",
    country_code: "PK",
    city: "Lahore",
    name: "Allama Iqbal International Airport",
    airport_code: "LHE",
  },

  {
    country: "Pakistan",
    country_code: "PK",
    city: "Islamabad",
    name: "Islamabad International Airport",
    airport_code: "ISB",
  },

  {
    country: "Philippines",
    country_code: "PH",
    city: "Dumaguete",
    name: "Sibulan Airport",
    airport_code: "DGT",
  },

  {
    country: "Philippines",
    country_code: "PH",
    city: "Manila",
    name: "Ninoy Aquino International Airport",
    airport_code: "MNL",
  },

  {
    country: "Portugal",
    country_code: "PT",
    city: "Lisbon",
    name: "Lisbon Humberto Delgado Airport",
    airport_code: "LIS",
  },

  {
    country: "Russia",
    country_code: "RU",
    city: "Novosibirsk",
    name: "Novosibirsk Tolmachevo Airport",
    airport_code: "OVB",
  },

  {
    country: "Russia",
    country_code: "RU",
    city: "Moscow",
    name: "Sheremetyevo International Airport",
    airport_code: "SVO",
  },

  {
    country: "Seychelles",
    country_code: "SC",
    city: "Mahé",
    name: "Seychelles International Airport",
    airport_code: "SEZ",
  },

  {
    country: "Singapore",
    country_code: "SG",
    city: "Singapore",
    name: "Singapore Changi Airport",
    airport_code: "SIN",
  },

  {
    country: "South Korea",
    country_code: "KR",
    city: "Seoul",
    name: "Incheon International Airport",
    airport_code: "ICN",
  },

  {
    country: "Sri Lanka",
    country_code: "LK",
    city: "Colombo",
    name: "Bandaranaike International Airport",
    airport_code: "CMB",
  },

  {
    country: "Sweden",
    country_code: "SE",
    city: "Stockholm",
    name: "Stockholm Arlanda Airport",
    airport_code: "ARN",
  },

  {
    country: "Switzerland",
    country_code: "CH",
    city: "Zurich",
    name: "Zurich Airport",
    airport_code: "ZRH",
  },

  {
    country: "Taiwan",
    country_code: "TW",
    city: "Kaohsiung",
    name: "Kaohsiung International Airport",
    airport_code: "KHH",
  },

  {
    country: "Taiwan",
    country_code: "TW",
    city: "Taipei",
    name: "Taiwan Taoyuan International Airport",
    airport_code: "TPE",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Bangkok",
    name: "Don Mueang International Airport",
    airport_code: "DMK",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Bangkok",
    name: "Suvarnabhumi Airport",
    airport_code: "BKK",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Chiang Mai",
    name: "Chiang Mai International Airport",
    airport_code: "CNX",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Chiang Rai",
    name: "Chiang Rai International Airport",
    airport_code: "CEI",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Hat Yai",
    name: "Hat Yai International Airport",
    airport_code: "HDY",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Khon Kaen",
    name: "Khon Kaen Airport",
    airport_code: "KKC",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Mae Sot",
    name: "Mae Sot Airport",
    airport_code: "MAQ",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Nakhon Si Thammarat",
    name: "Nakhon Si Thammarat Airport",
    airport_code: "NST",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Phitsanulok",
    name: "Phitsanulok Airport",
    airport_code: "PHS",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Phuket",
    name: "Phuket International Airport",
    airport_code: "HKT",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Ranong",
    name: "Ranong Airport",
    airport_code: "UNN",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Koh Samui",
    name: "Samui Airport",
    airport_code: "USM",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Trang",
    name: "Trang Airport",
    airport_code: "TST",
  },

  {
    country: "Thailand",
    country_code: "TH",
    city: "Udon Thani",
    name: "Udon Thani International Airport",
    airport_code: "UTH",
  },

  {
    country: "Vietnam",
    country_code: "VN",
    city: "Da Nang",
    name: "Da Nang International Airport",
    airport_code: "DAD",
  },

  {
    country: "Vietnam",
    country_code: "VN",
    city: "Hanoi",
    name: "Noi Bai International Airport",
    airport_code: "HAN",
  },

  {
    country: "Vietnam",
    country_code: "VN",
    city: "Ho Chi Minh City",
    name: "Tan Son Nhat International Airport",
    airport_code: "SGN",
  },
];

export const available_flights_dates = [
  {
    date: "7-1-2024",
    type: "$",
    cost: "38",
    isFlight: true,
  },
  {
    date: "7-2-2024",
    type: "$",
    cost: "58",
    isFlight: true,
  },
  {
    date: "7-3-2024",
    type: "$",
    cost: "80",
    isFlight: true,
  },
  {
    date: "7-4-2024",
    type: "$",
    cost: "80",
    isFlight: true,
  },
  {
    date: "7-5-2024",
    type: "$",
    cost: "100",
    isFlight: false,
  },
  {
    date: "7-6-2024",
    type: "Ks",
    cost: "80000000",
    isFlight: true,
  },
  {
    date: "7-7-2024",
    type: "Ks",
    cost: "1000000",
    isFlight: false,
  },
];

export const filters_values = {
  class: {
    name: "Class",
    values: ["All", "Economy", "Business", "First Class"],
  },
  price_range: {
    name: "Price Range",
    values: ["All", "< $100", "$100-$300", "> $300"],
  },
  flight_duration: {
    name: "Flight Duration",
    values: ["All", "> 6hr", "3-6hr", "1-3hr"],
  },
  time_of_flight: {
    name: "Time of Flight",
    values: ["All", "Morning", "Evening", "Night"],
  },
  layover_time: {
    name: "Layove Time",
    values: ["All", "No Layover", "2-4hr", "> 4hr"],
  },
};

export const departure_flights_data = [
  {
    id: 101,
    date: "3-7-2024",
    departure: {
      time: "10:30",
      time_indication: "Day",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    arrival: {
      time: "11:50",
      time_indication: "Day",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    airline: "Air Asia",
    airline_shortname: "Air Asia",
    class: "Economy",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: false,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: false,
          cost: {
            usd: 40,
            mmk: 100000,
            rmb: 140,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 50,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 40,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
  {
    id: 102,
    date: "3-7-2024",
    departure: {
      time: "11:30",
      time_indication: "Day",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    arrival: {
      time: "12:10",
      time_indication: "Night",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Eco Saver",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 126.49,
      mmk: 30000000,
      rmb: 1000,
    },
    discount_price: null,
    total_price: {
      usd: 226.49,
      mmk: 40000000,
      rmb: 2000,
    },
    tax: "10%",
  },
  {
    id: 103,
    date: "3-7-2024",
    departure: {
      time: "14:50",
      time_indication: "Night",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    arrival: {
      time: "17:10",
      time_indication: "Night",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Business J",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 397.65,
      mmk: 150000000,
      rmb: 3000,
    },
    discount_price: null,
    total_price: {
      usd: 426.49,
      mmk: 180000000,
      rmb: 400,
    },
    tax: "10%",
  },
];

export const return_flights_data = [
  {
    id: 104,
    date: "3-7-2024",
    departure: {
      time: "5:10",
      time_indication: "Day",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    arrival: {
      time: "6:30",
      time_indication: "Day",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Eco Saver",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 70,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 50,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
  {
    id: 105,
    date: "3-7-2024",
    departure: {
      time: "9:10",
      time_indication: "Day",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    arrival: {
      time: "10:30",
      time_indication: "Day",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Eco Saver",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 100,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 80,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
  {
    id: 106,
    date: "3-7-2024",
    departure: {
      time: "14:20",
      time_indication: "Night",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    arrival: {
      time: "16:30",
      time_indication: "Night",
      airport_code: "DMK",
      airport: "Don Mueang International Airport",
      city: "Bangkok",
      country: "Thailand",
    },
    airline: "Air Asia",
    airline_shortname: "Air Asia",
    class: "First Class",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: false,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: false,
          cost: {
            usd: 40,
            mmk: 100000,
            rmb: 140,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 50,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 40,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
];

export const one_way_flights_data = [
  {
    id: 107,
    date: "3-7-2024",
    departure: {
      time: "12:30",
      time_indication: "Night",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    arrival: {
      time: "14:20",
      time_indication: "Night",
      airport: "Yangon International Airport",
      city: "Yangon",
      airport_code: "RGN",
      country: "Myanmar",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Eco Saver",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 70,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 50,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
  {
    id: 108,
    date: "3-7-2024",
    departure: {
      time: "12:30",
      time_indication: "Night",
      airport_code: "MDL",
      airport: "Mandalay International Airport",
      city: "Mandalay",
      country: "Myanmar",
    },
    arrival: {
      time: "14:20",
      time_indication: "Night",
      city: "Yangon",
      airport: "Yangon International Airport",
      country: "Myanmar",
      airport_code: "RGN",
    },
    airline: "Myanmar Airway International",
    airline_shortname: "MAI",
    class: "Business J",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "1h 20m",
    layovers: [],
    price: {
      usd: 70,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 50,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 150,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
];

export const upcoming_history = [
  {
    trip: {
      from: {
        airport: "Mandalay,Mandalay Airport",
        city: "Mandalay",
        country: "Myanmar",
      },
      to: {
        airport: "Beijing,Beijing Capital International Airport",
        city: "Beijing",
        country: "Myanmar",
      },
    },
    tripType: "round trip",
    startDate: "2024/07/01",
    endDate: "2024/07/07",
    persons: {
      adult: 1,
      child: 1,
      infant: 0,
    },
    citizens: quantity_picker_radio_buttons,
  },
];

export const history_detail_data = {
  persons: [
    {
      firstName: "John",
      lastName: "Doe",
      passport: "AB123456",
      contactPhone: "+95912345678",
      contactMail: "johndoe123@gmail.com",
    },
    {
      firstName: "John",
      lastName: "Doe",
      passport: "CD456789",
    },
  ],
  isDomestic: false,
  isResident: false,
  flights: {
    departure: {
      date: "3-7-2024",
      departure: {
        time: "10:30",
        time_indication: "Day",
        airport_code: "DMK",
        airport: "Don Mueang International Airport",
        city: "Bangkok",
        country: "Thailand",
      },
      arrival: {
        time: "11:50",
        time_indication: "Day",
        airport_code: "MDL",
        airport: "Mandalay International Airport",
        city: "Mandalay",
        country: "Myanmar",
      },
      airline: "Air Asia",
      airline_shortname: "Air Asia",
      class: "Economy",
      services: {
        included: {
          luggage: {
            isAllowed: true,
            description: "luggage_content",
          },
          meal: {
            isIncluded: false,
            description: "meal_content",
          },
        },
        addons: {
          additionalLuggage: {
            isAllowed: false,
            cost: {
              usd: 40,
              mmk: 100000,
              rmb: 120,
            },
            description: "additional_luggage_content",
          },
          wheelChairService: {
            isIncluded: false,
            cost: {
              usd: 25,
              mmk: 80000,
              rmb: 100,
            },
            description: "chair_service_content",
          },
        },
      },
      flight_duration: "1h 20m",
      layovers: [],
      price: {
        usd: 50,
        mmk: 150000,
        rmb: 500,
      },
      discount_price: {
        usd: 40,
        mmk: 80000,
        rmb: 100,
      },
      total_price: {
        usd: 150,
        mmk: 180000,
        rmb: 1000,
      },
      tax: "10%",
    },
    return: {
      date: "3-7-2024",
      departure: {
        time: "5:10",
        time_indication: "Day",
        airport_code: "MDL",
        airport: "Mandalay International Airport",
        city: "Mandalay",
        country: "Myanmar",
      },
      arrival: {
        time: "6:30",
        time_indication: "Day",
        airport_code: "DMK",
        airport: "Don Mueang International Airport",
        city: "Bangkok",
        country: "Thailand",
      },
      airline: "Myanmar Airway International",
      airline_shortname: "MAI",
      class: "Eco Saver",
      services: {
        included: {
          luggage: {
            isAllowed: true,
            description: "luggage_content",
          },
          meal: {
            isIncluded: true,
            description: "meal_content",
          },
        },
        addons: {
          additionalLuggage: {
            isAllowed: true,
            cost: {
              usd: 0,
              mmk: 0,
              rmb: 0,
            },
            description: "additional_luggage_content",
          },
          wheelChairService: {
            isIncluded: false,
            cost: {
              usd: 25,
              mmk: 80000,
              rmb: 100,
            },
            description: "chair_service_content",
          },
        },
      },
      flight_duration: "1h 20m",
      layovers: [],
      price: {
        usd: 70,
        mmk: 150000,
        rmb: 500,
      },
      discount_price: {
        usd: 50,
        mmk: 8000,
        rmb: 100,
      },
      total_price: {
        usd: 150,
        mmk: 180000,
        rmb: 1000,
      },
      tax: "10%",
    },
  },
  dateInfo: {
    departure: "2024/07/03",
    return: "2024/07/06",
  },
};

export const layover_data = [
  {
    id: 1027,
    date: "3-7-2024",
    departure: {
      time: "15:10",
      time_indication: "Night",
      airport: "Yangon International Airport",
      city: "Yangon",
      airport_code: "RGN",
      country: "Myanmar",
    },
    arrival: {
      time: "13:20",
      time_indication: "Night",
      airport: "Kansai International Airport",
      country: "Japan",
      city: "Osaka",
      airport_code: "KIX",
      date: "2024/07/06",
    },
    airline: ["Myanmar Airway International", "China Eastern", "China Eastern"],
    class: "Economy",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "22h 10m",
    layovers: [
      {
        id: 1000,
        date: ["2024/07/05"],
        departure: {
          time: "15:10",
          time_indication: "Night",
          airport: "Yangon International Airport",
          city: "Yangon",
          airport_code: "RGN",
          country: "Myanmar",
        },
        arrival: {
          time: "18:40",
          time_indication: "Night",
          airport: "Kunming Changshui International Airport",
          country: "China",
          city: "Kunming",
          airport_code: "KMG",
        },
        airline: "Myanmar Airway International",
        airline_shortname: "MAI",
        class: "Eco Saver",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "2h",
        layovers: [],
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        layover_time: "3h 20m",
        tax: "10%",
      },
      {
        id: 1001,
        date: ["2024/07/06", "2024/07/05"],
        departure: {
          time: "22:00",
          time_indication: "Night",
          airport: "Kunming Changshui International Airport",
          country: "China",
          city: "Kunming",
          airport_code: "KMG",
        },
        arrival: {
          time: "1:10",
          time_indication: "Day",
          airport: "Shanghai Pudong International Airport",
          country: "China",
          city: "Shanghai",
          airport_code: "PVG",
        },
        airline: "China Eastern",
        airline_shortname: "China Eastern",
        class: "Economy",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "1h 20m",
        layovers: [],
        layover_time: "8h 45m",
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        tax: "10%",
      },
      {
        id: 1003,
        date: ["2024/07/06"],
        departure: {
          time: "9:55",
          time_indication: "Day",
          airport: "Shanghai Pudong International Airport",
          country: "China",
          city: "Shanghai",
          airport_code: "PVG",
        },
        arrival: {
          time: "13:20",
          time_indication: "Night",
          airport: "Kansai International Airport",
          country: "Japan",
          city: "Osaka",
          airport_code: "KIX",
          date: "2024/07/06",
        },
        airline: "China Eastern",
        airline_shortname: "China Eastern",
        class: "Economy",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "1h 20m",
        layovers: [],
        layover_time: null,
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        tax: "10%",
      },
    ],
    price: {
      usd: 150,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 70,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 70,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
  {
    id: 1028,
    date: "3-7-2024",
    departure: {
      time: "15:10",
      time_indication: "Night",
      airport: "Yangon International Airport",
      city: "Yangon",
      airport_code: "RGN",
      country: "Myanmar",
    },
    arrival: {
      time: "13:20",
      time_indication: "Night",
      airport: "Kansai International Airport",
      country: "Japan",
      city: "Osaka",
      airport_code: "KIX",
      date: "2024/07/06",
    },
    airline: ["Myanmar Airway International", "China Eastern", "China Eastern"],
    class: "Economy",
    services: {
      included: {
        luggage: {
          isAllowed: true,
          description: "luggage_content",
        },
        meal: {
          isIncluded: true,
          description: "meal_content",
        },
      },
      addons: {
        additionalLuggage: {
          isAllowed: true,
          cost: {
            usd: 0,
            mmk: 0,
            rmb: 0,
          },
          description: "additional_luggage_content",
        },
        wheelChairService: {
          isIncluded: false,
          cost: {
            usd: 25,
            mmk: 80000,
            rmb: 100,
          },
          description: "chair_service_content",
        },
      },
    },
    flight_duration: "22h 10m",
    layovers: [
      {
        id: 1000,
        date: ["2024/07/05"],
        departure: {
          time: "15:10",
          time_indication: "Night",
          airport: "Yangon International Airport",
          city: "Yangon",
          airport_code: "RGN",
          country: "Myanmar",
        },
        arrival: {
          time: "18:40",
          time_indication: "Night",
          airport: "Kunming Changshui International Airport",
          country: "China",
          city: "Kunming",
          airport_code: "KMG",
        },
        airline: "Myanmar Airway International",
        airline_shortname: "MAI",
        class: "Eco Saver",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "2h",
        layovers: [],
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        layover_time: "3h 20m",
        tax: "10%",
      },
      {
        id: 1001,
        date: ["2024/07/06", "2024/07/05"],
        departure: {
          time: "22:00",
          time_indication: "Night",
          airport: "Kunming Changshui International Airport",
          country: "China",
          city: "Kunming",
          airport_code: "KMG",
        },
        arrival: {
          time: "1:10",
          time_indication: "Day",
          airport: "Shanghai Pudong International Airport",
          country: "China",
          city: "Shanghai",
          airport_code: "PVG",
        },
        airline: "China Eastern",
        airline_shortname: "China Eastern",
        class: "Economy",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "1h 20m",
        layovers: [],
        layover_time: "8h 45m",
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        tax: "10%",
      },
      {
        id: 1003,
        date: ["2024/07/06"],
        departure: {
          time: "9:55",
          time_indication: "Day",
          airport: "Shanghai Pudong International Airport",
          country: "China",
          city: "Shanghai",
          airport_code: "PVG",
        },
        arrival: {
          time: "13:20",
          time_indication: "Night",
          airport: "Kansai International Airport",
          country: "Japan",
          city: "Osaka",
          airport_code: "KIX",
          date: "2024/07/06",
        },
        airline: "China Eastern",
        airline_shortname: "China Eastern",
        class: "Economy",
        services: {
          included: {
            luggage: {
              isAllowed: true,
              description: "luggage_content",
            },
            meal: {
              isIncluded: true,
              description: "meal_content",
            },
          },
          addons: {
            additionalLuggage: {
              isAllowed: false,
              cost: {
                usd: 40,
                mmk: 100000,
                rmb: 140,
              },
              description: "additional_luggage_content",
            },
            wheelChairService: {
              isIncluded: false,
              cost: {
                usd: 25,
                mmk: 80000,
                rmb: 100,
              },
              description: "chair_service_content",
            },
          },
        },
        flight_duration: "1h 20m",
        layovers: [],
        layover_time: null,
        price: {
          usd: 50,
          mmk: 150000,
          rmb: 500,
        },
        discount_price: {
          usd: 40,
          mmk: 80000,
          rmb: 100,
        },
        total_price: {
          usd: 150,
          mmk: 180000,
          rmb: 1000,
        },
        tax: "10%",
      },
    ],
    price: {
      usd: 300,
      mmk: 150000,
      rmb: 500,
    },
    discount_price: {
      usd: 200,
      mmk: 80000,
      rmb: 100,
    },
    total_price: {
      usd: 70,
      mmk: 180000,
      rmb: 1000,
    },
    tax: "10%",
  },
];
