import React, { useState } from "react";
import InfoHeader from "../info-header/InfoHeader";

import flightFrom from "../../assets/icons/flight-takeoff-line.svg";
import flightTo from "../../assets/icons/flight-land-line.svg";

import "./Layover.scss";
import FlightCard from "../flight-card/FlightCard";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SelectedFlight from "../../pages/flightlist/components/selected-flight/SelectedFlight";

const Layover = ({ onClose, flight, onClick }) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.currency);
  const [flightDetailOpen, setFlightDetailOpen] = useState(false);
  const [currentFlight, setCurrencyFlight] = useState(null);

  return (
    <div className="layover w-full relative h-full flex flex-col">
      <div className="py-5 px-4">
        <InfoHeader
          onClick={onClose}
          title={`${flight?.departure?.city} - ${flight?.arrival?.city}`}
          styles={"flex justify-center"}
        />
      </div>
      <div className="layover-body-wrapper relative">
        <div className="relative scrollable-container flex px-4 flex-col pb-4">
          <div className="flex relative w-fit gap-[10px] p-2 ml-5 pl-6 pb-8">
            <div className="absolute -bottom-0.5 h-[80%] w-full left-0 border-l-[1px] border-dashed border-[var(--black)]">
              <div className="relative -left-1.5 top-0 w-[11px] h-[11px] bg-[var(--black)] rounded-lg" />
            </div>
            <img src={flightFrom} alt="flight-from" />
            <h2 className="text-[length:--title] font-[600] text-[var(--black)]">
              {t("progress.start_from", {
                city: flight?.departure?.city,
              })}
            </h2>
          </div>
          <div className="flex flex-col">
            {flight?.layovers?.map((layover, index) => {
              return (
                <div key={layover?.id} className="flex flex-col">
                  <div className="relative z-[99]">
                    <FlightCard
                      key={index}
                      isLayoverFlight={true}
                      data={layover}
                      currency={currency}
                      onClick={() => {
                        setFlightDetailOpen(true);
                        setCurrencyFlight(layover);
                      }}
                    />
                  </div>
                  {layover?.layover_time !== null && (
                    <div className="flex relative w-fit gap-[10px] p-2 ml-5 pl-6 py-8 border-l-[1px] border-[var(--black)] border-dashed">
                      <div className="absolute -left-1.5 top-[44%] w-[11px] h-[11px] bg-[var(--black)] rounded-lg" />
                      <h2 className="text-[length:--title-s] font-[600] text-[var(--black)]">
                        {layover?.layover_time} {t("progress.layover")}
                      </h2>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="flex relative w-fit gap-[10px] p-2 ml-5 pl-6 pt-8">
            <div className="absolute top-0 h-[80%] w-full left-0 border-l-[1px] border-dashed border-[var(--black)]">
              <div className="relative -left-1.5 top-10 w-[11px] h-[11px] bg-[var(--black)] rounded-lg" />
            </div>
            <img src={flightTo} alt="flight-to" />
            <h2 className="text-[length:--title] font-[600] text-[var(--black)]">
              {t("progress.arrive_to", {
                city: flight?.arrival?.city,
              })}
            </h2>
          </div>
        </div>
        <div className="flex relative button-wrapper pt-4 z-[99]  w-full justify-center items-center pb-5  px-6">
          <button
            className="flex box-border relative h-[48px] w-full items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
            onClick={(e) => {
              e.preventDefault();
              onClick(flight);
            }}
          >
            {t(`progress.select_flight_for`, {
              price: `${
                currency.key !== "Ks" ? currency.key : ""
              }${flight?.total_price[
                currency?.value?.toLowerCase()
              ]?.toLocaleString()}${
                currency.key === "Ks" ? currency?.key : ""
              }`,
            })}
          </button>
        </div>
      </div>
      {flightDetailOpen && (
        <div className="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full">
          <div className="flight-detail-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
            <div
              className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
              onClick={() => setFlightDetailOpen(false)}
            />
            <div className="absolute px-[22px] py-[20px] rounded-t-[12px] shadow-lg bottom-0 w-full h-[60%] overflow-y-scroll overflow-x-hidden bg-[var(--white)]">
              <SelectedFlight
                currency={currency}
                data={currentFlight}
                isLayover={true}
                onClick={() => {
                  setFlightDetailOpen(false);
                }}
                onClose={() => setFlightDetailOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layover;
