import React from "react";
import { useTranslation } from "react-i18next";

export const RadioButton = ({ item, onClick, color, translation_key }) => {
  const { t } = useTranslation();
  return (
    <button className="radio-button flex gap-1 items-center" onClick={onClick}>
      <Radio color={color} />
      <p className="text-[length:--title-s] font-[600]">
        {t(`${translation_key}.${item.name}`)}
      </p>
    </button>
  );
};

const Radio = ({ color }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="8" fill={color} stroke="#BABABA" />
      <circle cx="8.5" cy="8.5" r="3.5" fill="white" />
    </svg>
  );
};
