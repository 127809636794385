import React from "react";

const Filter = ({ data, onClick, value }) => {
  return (
    <div className="w-full h-fit flex gap-[10px] flex-col">
      <h1 className="font-[600] text-[13px] text-[var(--black)]">
        {data?.name}
      </h1>
      <div className="flex gap-[9px]">
        {data?.filters?.map((item, index) => (
          <button
            key={index}
            value={item}
            onClick={onClick}
            className={`${
              item === value
                ? "bg-[var(--accent)] text-[var(--white)]"
                : "border-[1px] border-[var(--gray2)] text-[var(--gray7)]"
            } px-[9px] py-[5px] text-[length:--body-s] rounded-[3px]`}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Filter;
