import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SearchFlightHeader from "../../../components/info-header/InfoHeader";
import HomeInputBox from "../components/home-inputbox/HomeInputBox";
import AirportCard from "../../../components/airport-card/AirportCard";

//icons
import flightFrom from "../../../assets/icons/flight-takeoff-line.svg";
import flightTo from "../../../assets/icons/flight-land-line.svg";

import "./SearchFlight.scss";

//const
import { airports } from "../../../data/data";

const SearchFlight = ({ flightType, handleBack, handleChange }) => {
  const {t} = useTranslation();
  const headerText = flightType === "to" ? `${t("search_page.arrive_to")}` : `${t("search_page.depart_from")}`;
  const [airportList, setAirportList] = useState(airports);
  const icon = flightType === "to" ? flightTo : flightFrom;
  const [value, setValue] = useState("");

  const handleSearchFlight = (e) => {
    let filteredAirports = [];
    const searchText = e.target.value.toLowerCase();
    setValue(searchText);

    if (searchText === "") {
      setAirportList(airports);
      return;
    }
    filteredAirports = airports.filter((airport) => {
      const { name, country_code, city, country, airport_code } = airport;
      return (
        name.toLowerCase().includes(searchText) ||
        country_code.toLowerCase().includes(searchText) ||
        city.toLowerCase().includes(searchText) ||
        country.toLowerCase().includes(searchText) ||
        airport_code.toLowerCase().includes(searchText)
      );
    });
    setAirportList([...filteredAirports]);
  };

  return (
    <div className="search-flight relative bg-[var(--white1)] flex flex-col h-full w-full">
      <div className="relative  p-[20px] ml-[2px]">
        <SearchFlightHeader title={headerText} onClick={handleBack} />
      </div>
      <div className="w-full relative flex justify-center px-5">
        <HomeInputBox
          autoFocus={true}
          icon={icon}
          value={value}
          handleChange={handleSearchFlight}
          name={flightType}
          classes={"box-border w-full h-[52px] p-3 rounded-[5px] bg-[#E6E6E6]"}
        />
      </div>
      <div className="flight-list overflow-auto p-5 pt-2 relative flex-col justify-center items-center">
        {airportList?.length > 0 &&
          airportList.map((item, index) => (
            <div key={index} className="relative border-box w-[319px]">
              <AirportCard
                airport={item}
                border={index + 1 !== airports.length}
                name={flightType}
                handleClick={handleChange}
                handleBack={handleBack}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchFlight;
