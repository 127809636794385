import React from "react";
import { useNavigate } from "react-router-dom";

import Profile from "../../assets/icons/profile.svg";

import "./ProfileCard.scss";
import { ProfileSvg } from "../../data/icon";
import { useTranslation } from "react-i18next";

const ProfileCard = ({ account, onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="profile-card rounded-[8px] flex flex-col w-full h-full">
      {account === null ? (
        <div
          className="flex flex-col w-full h-full items-center justify-center gap-6 cursor-pointer"
          onClick={onClick}
        >
          <div>
            <img src={Profile} alt="profile" />
          </div>
          <p className="text-[var(--accent)] font-[600] text-[length:--title-s]">
            {t("profile.tap_here")}
          </p>
        </div>
      ) : (
        <div className="w-full flex h-full p-4">
          <div className="w-full">
            <div className="flex justify-between">
              <ProfileSvg color={"var(--black)"} />
              <button
                className="text-[length:--title-s] font-[600] text-[var(--accent)]"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/profile/edit");
                }}
              >
                {t("edit")}
              </button>
            </div>
            <div className="flex flex-col gap-2 mt-5">
              <h1 className="text-[length:--title] font-[700]">
                {account?.firstName} {account?.lastName}
              </h1>
              <div className="flex flex-col">
                <div className="flex gap-2">
                  <p className="text-[14px] text-[var(--gray8)] leading-5">
                    {t("traveller-info.passport")} :
                  </p>
                  <p className="text-[14px] text-[var(--gray8)] leading-5">
                    {account?.passport}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className="text-[14px] text-[var(--gray8)] leading-5">
                    {t("traveller-info.nrc")} :
                  </p>
                  <p className="text-[14px] text-[var(--gray8)] leading-5">
                    {`${account?.nrc_prefix?.value}${account?.nrc_middle?.value} (${account?.nrc_suffix?.value}) ${account?.nrcNumber}`}
                  </p>
                </div>
                <div className="flex w-full items-center">
                  <p className="text-[14px] text-[var(--black)] leading-5">
                    {account?.contactPhone}
                  </p>
                  <div className="flex w-[1px] mx-1 border-l-[1px] border-[var(--black)] h-[15px]" />
                  <p className="text-[14px] text-[var(--black)] leading-5">
                    {account?.contactMail}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
