import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import PassengerCard from "../../../../components/pessenger-card/PassengerCard";
import FlightPaymentInfo from "../flight-payment/FlightPaymentInfo";

import "./Payment.scss";
import { useTranslation } from "react-i18next";

const Payment = ({
  isDomestic,
  travellerInfo,
  flightInfo,
  dateInfo,
  currency,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { citizens, persons, tripType } = useSelector(
    (state) => state.flightInfo.flightInfo
  );
  const totalPrice = Object.entries(flightInfo)?.reduce(
    (price, [key, value]) => {
      if (value !== null) {
        return price + value?.total_price?.["mmk"];
      } else {
        return price + 0;
      }
    },
    0
  );
  const isResident = useMemo(() => {
    const isChecked = citizens?.find((item) => item?.key === "myanmar citizen");
    return isChecked?.isChecked;
  }, [citizens]);

  return (
    <div className="payment relative w-full h-full flex flex-col">
      <h2 className="text-[18px] font-[600] mt-6 mb-[12px] px-4">
        {t("confirm_ticker.confirm_ticket")}
      </h2>
      <div className="relative passender-information w-full overflow-y-scroll">
        <div className="w-full flex flex-col p-4">
          <h4 className="text-[length:--body-s] text-[var(--gray7)] pb-3">
            {t("confirm_ticker.passenger_info")}
          </h4>
          <div className="w-full flex flex-col mb-[56px]">
            {travellerInfo?.map((item, index) => (
              <PassengerCard
                info={item}
                key={index}
                isNrc={isDomestic && isResident}
                border={index !== travellerInfo?.length - 1}
              />
            ))}
          </div>
          <div className="w-full flex flex-col gap-[56px] mb-[120px]">
            {Object.entries(flightInfo)?.map(([key, value]) => {
              if (value !== null) {
                return (
                  <FlightPaymentInfo
                    key={key}
                    flight_type={key}
                    flight={value}
                    selectedDates={dateInfo}
                    tripType={tripType}
                    currency={currency}
                    quantity={Object.values(persons).reduce(
                      (count, item) => count + item,
                      0
                    )}
                  />
                );
              }
              return null;
            })}
          </div>
          <div className="w-full flex flex-col gap-4">
            <div className="flex w-full justify-between">
              <p className="text-[length:--body] text-[var(--gray7)]">
                {t("confirm_ticker.payment_total")}
              </p>
              <p className="text-[length:--body] font-[700] text-[var(--gray7)]">
                {totalPrice?.toLocaleString()}
              </p>
            </div>
            <button
              className="flex box-border h-[48px] w-full items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
              onClick={onSubmit}
            >
              {t("confirm_ticker.continue_payment")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
