export const DropDownMenu = ({ classes, children }) => {
  return <div className={classes}>{children}</div>;
};

export const DropDownMenuTrigger = ({ classes, children, onClick }) => {
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );
};

export const DropDownMenuContent = ({ isShow, classes, children }) => {
  if (!isShow) return null;
  return <div className={classes}>{children}</div>;
};

export const DropDownMenuItem = ({ onClick, classes, children }) => {
  return (
    <div className={classes} onClick={onClick}>
      {children}
    </div>
  );
};
