import { createSlice } from "@reduxjs/toolkit";
import { quantity_picker_radio_buttons } from "../data/const";

const initialState = {
  flightInfo: {
    trip: {
      from: {
        airport: "Bangkok,Don Mueang International Airport",
        city: "Bangkok",
        country: "Thailand",
        airport_code: "DMK",
      },
      to: {
        airport: "Mandalay,Mandalay Airport",
        city: "Mandalay",
        country: "Myanmar",
        airport_code: "MDL",
      },
    },
    tripType: "round trip",
    startDate: "2024/7/1",
    endDate: "2024/7/7",
    persons: {
      adult: 1,
      child: 0,
      infant: 0,
    },
    citizens: quantity_picker_radio_buttons,
  },
};

export const flightInfoSlice = createSlice({
  name: "flightInfo",
  initialState: initialState,
  reducers: {
    setFlightInfo: (state, action) => {
      state.flightInfo = action.payload;
    },
  },
});

export const { setFlightInfo } = flightInfoSlice.actions;

export default flightInfoSlice.reducer;
