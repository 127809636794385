import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setUser } from "../../reducers/userReducer";

import InfoHeader from "../../components/info-header/InfoHeader";
import UserForm from "../../components/user-form/UserForm";
import { useTranslation } from "react-i18next";

//scss
import "./EditProfile.scss";

const EditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    passport: "",
    nrc_prefix: null,
    nrc_middle: null,
    nrc_suffix: null,
    nrcNumber: "",
    gender: null,
    contactPhone: "",
    contactMail: "",
  });

  const handleChange = (e) => {
    setProfile((prev) => {
      if (e.target.name === "gender" || e.target.name.includes("nrc_")) {
        if (e.target.name === "nrc_prefix") {
          return {
            ...prev,
            nrc_middle: null,
            [e.target.name]: e.target,
          };
        }
        return {
          ...prev,
          [e.target.name]: e.target,
        };
      }
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(setUser(profile));
    navigate("/profile");
  };

  const checkValidations = () => {
    for (const value of Object.values(profile)) {
      if (value === "" || value === undefined || value === null) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (user !== null) {
      setProfile(user);
    }
  }, [user]);

  return (
    <div className="edit-profile flex flex-col w-full h-full bg-[var(--white1)]">
      <div className="py-6 px-5">
        <InfoHeader
          title={t(`profile.edit_profile`)}
          onClick={() => navigate("/profile")}
        />
      </div>
      <div className="w-full flex px-4 user-form pb-4">
        <div className="h-full overflow-scroll">
          <UserForm
            data={profile}
            isValidate={checkValidations()}
            onChange={handleChange}
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
