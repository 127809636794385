import { configureStore } from "@reduxjs/toolkit";
import flightInfoReducer from "./reducers/flightInfoReducer";
import userReducer from "./reducers/userReducer";
import currencyReducer from "./reducers/currencyReducer";
import languageReducer from "./reducers/languageReducer";

export default configureStore({
  reducer: {
    flightInfo: flightInfoReducer,
    user: userReducer,
    currency: currencyReducer,
    language: languageReducer
  },
});
