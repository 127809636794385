import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//components
import Header from "../../components/header/Header";
import TravellerInfo from "./components/traveller-info/TravellerInfo";
import Payment from "./components/payment/Payment";
import { TabContent, TabList, TabTrigger } from "../../components/tabs/Tabs";
import AvailableFlights from "./components/available-flights/AvailableFlights";
import Loader from "../../components/Loader/Loader";

import "./FlightList.scss";

//utils
import { formatDateHeader } from "../../utils/format";

import { available_flights_dates } from "../../data/data";

//icons
import backArrow from "../../assets/icons/back-arrow-white.svg";

const TABS = {
  "round trip": [
    "departure_flight",
    "return_flight",
    "traveler_info",
    "make_payment",
  ],
  "one way": ["select_flight", "traveler_info", "make_payment"],
};

const FlightList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { trip, tripType, startDate, endDate, persons } = useSelector(
    (state) => state.flightInfo.flightInfo
  );
  const currencySetter = useSelector((state) => state.currency);
  const [selectedFlights, setSelectedFlights] = useState({
    departure: null,
    return: null,
  });
  const tabs = TABS[tripType];
  const [tabValue, setTabValue] = useState(tabs[0]);
  const [selectedDates, setSelectedDates] = useState({
    departure: startDate,
    return: endDate,
  });
  const [currency, setCurrency] = useState(currencySetter);
  const [travellerInfo, setTravellerInfo] = useState([]);
  // eslint-disable-next-line
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isDomestic = useCallback(() => {
    return (
      trip?.from?.country === trip?.to?.country &&
      trip?.from?.country === "Myanmar"
    );
  }, [trip]);

  const handleActiveItem = (item) => {
    if (tripType === "round trip") {
      if (
        (tabs[1] === item && selectedFlights.departure === null) ||
        (tabs[2] === item &&
          (selectedFlights.return === null ||
            selectedFlights.departure === null)) ||
        (tabs[3] === item &&
          (travellerInfo?.length === 0 ||
            selectedFlights.return === null ||
            selectedFlights.departure === null))
      ) {
        return;
      }
      if ((tabs[0] === item || tabs[1] === item) && travellerInfo?.length > 0) {
        setTravellerInfo([]);
      }
      if (tabs[0] === item && selectedFlights.return !== null) {
        setSelectedFlights((prev) => {
          return {
            ...prev,
            return: null,
          };
        });
      }
    } else {
      if (
        (tabs[1] === item && selectedFlights.departure === null) ||
        (tabs[2] === item && travellerInfo?.length === 0) ||
        selectedFlights.departure === null
      ) {
        return;
      }
      if (tabs[0] === item && travellerInfo?.length > 0) {
        setTravellerInfo([]);
      }
    }
    setTabValue(item);
  };

  const isCompleteTab = (item) => {
    if (tripType === "round trip") {
      if (
        tabValue === item ||
        (item === tabs[0] && selectedFlights.departure !== null) ||
        (item === tabs[1] && selectedFlights.return !== null) ||
        (item === tabs[2] && travellerInfo?.length > 0) ||
        (item === tabs[3] && paymentInfo !== null)
      ) {
        return true;
      }
    } else {
      if (
        tabValue === item ||
        (item === tabs[0] && selectedFlights.departure !== null) ||
        (item === tabs[1] && travellerInfo?.length > 0) ||
        (item === tabs[2] && paymentInfo !== null)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const timer = setInterval(() => {
      setIsLoading(false);
      navigate("/payment");
      clearInterval(timer);
    }, 3000);
  };

  const isLayover = () => {
    if (
      trip?.from?.airport_code === "RGN" &&
      trip?.to?.airport_code === "KIX"
    ) {
      return true;
    }
    return false;
  };

  return (
    <section id="flight-list">
      {isLoading ? (
        <div>
          <div className="flex py-6 px-5">
            <img
              src={backArrow}
              alt="back-arrow"
              className="cursor-pointer"
              onClick={() => setIsLoading(false)}
            />
          </div>
          <Loader
            classes={"absolute bottom-[104px]"}
            text={t("loading.please_wait")}
          />
        </div>
      ) : (
        <>
          <div className="py-3">
            <Header
              data={{
                from: trip?.from?.city,
                to: trip?.to?.city,
                tripType,
                startDate: formatDateHeader(startDate),
                endDate: formatDateHeader(endDate),
                count: Object.values(persons).reduce(
                  (total, value) => total + value,
                  0
                ),
              }}
              onBack={() => navigate("/")}
              currency={currency}
              onChange={(value) => setCurrency(value)}
            />
          </div>
          <div className="flight-list-body relative shadow-lg bg-[var(--white)]">
            <TabList classes="flex flex-col justify-between">
              <div className="flex justify-between w-full h-fit">
                {tabs.map((item, index) => (
                  <div
                    key={index}
                    className={`flex box-border w-full h-[48px] border-b-[1px] flex-wrap items-center justify-center 
                 ${index !== 0 && "border-l-[1px]"} ${
                      isCompleteTab(item)
                        ? "bg-[var(--gray7)] border-[#474747]"
                        : "border-[var(--gray6)]"
                    }`}
                  >
                    <TabTrigger
                      classes={"flex gap-2 items-center justify-center"}
                      key={index}
                      onClick={() => handleActiveItem(item)}
                    >
                      <span
                        className={`flex font-[700]  items-center justify-center text-[9px] h-[16px] w-[16px] rounded-[50%] bg-[var(--gray4)]
                      ${
                        isCompleteTab(item)
                          ? "text-[var(--gray2)] bg-[var(--white)]"
                          : "text-[var(--gray6)]"
                      }`}
                      >
                        {index + 1}
                      </span>
                      <span
                        className={`flex w-[47px] font-[500] text-left text-[9px]
                  ${
                    isCompleteTab(item)
                      ? "text-[var(--white)]"
                      : "text-[var(--gray4)]"
                  }
                `}
                      >
                        {t(`progress.${item}`)}
                      </span>
                    </TabTrigger>
                  </div>
                ))}
              </div>
              {(trip?.from?.airport_code === "DMK" &&
                trip?.to?.airport_code === "MDL") ||
              (trip?.from?.airport_code === "MDL" &&
                trip?.to?.airport_code === "RGN" &&
                tripType === "one way") ||
              isLayover() ? (
                <div className="relative tab-content-wrapper">
                  <TabContent
                    isShow={
                      tabValue === TABS["round trip"][0] ||
                      tabValue === TABS["one way"][0]
                    }
                  >
                    <AvailableFlights
                      availableDates={available_flights_dates}
                      selectedFlights={selectedFlights}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                      isLayover={isLayover()}
                      flight_type="departure"
                      setSelectedFlights={setSelectedFlights}
                      currency={currency}
                      tabs={tabs}
                      activeTab={tabValue}
                      changeTab={(value) => setTabValue(value)}
                    />
                  </TabContent>
                  <TabContent isShow={tabValue === TABS["round trip"][1]}>
                    <AvailableFlights
                      availableDates={available_flights_dates}
                      isLayover={isLayover()}
                      selectedFlights={selectedFlights}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                      flight_type="return"
                      setSelectedFlights={setSelectedFlights}
                      currency={currency}
                      tabs={tabs}
                      activeTab={tabValue}
                      changeTab={(value) => setTabValue(value)}
                    />
                  </TabContent>
                  <TabContent isShow={tabValue === TABS["round trip"][2]}>
                    <TravellerInfo
                      setData={setTravellerInfo}
                      data={travellerInfo}
                      changeTab={(value) => {
                        setTabValue(value);
                      }}
                      isDomestic={isDomestic()}
                    />
                  </TabContent>
                  <TabContent isShow={tabValue === TABS["round trip"][3]}>
                    <Payment
                      isDomestic={isDomestic()}
                      travellerInfo={travellerInfo}
                      flightInfo={selectedFlights}
                      dateInfo={selectedDates}
                      tripType={tripType}
                      currency={currency}
                      onSubmit={handleSubmit}
                    />
                  </TabContent>
                </div>
              ) : (
                <div className="absolute top-[50%] flex w-full justify-center text-[var(--gray4)] text-[length:--title]">
                  {t("progress.no_flight_found")}
                </div>
              )}
            </TabList>
          </div>
        </>
      )}
    </section>
  );
};

export default FlightList;
