import { Route, Routes } from "react-router-dom";

import Home from "./pages/home/Home";
import FlightList from "./pages/flightlist/FlightList";
import PaymentInfo from "./pages/payment-info/PaymentInfo";
import Profile from "./pages/profile/Profile";
import EditProfile from "./pages/edit-profile/EditProfile";
import Link from "./pages/link/Link";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/flight-list" element={<FlightList />} />
      <Route path="/payment" element={<PaymentInfo />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/edit" element={<EditProfile />} />
      <Route path="/link" element={<Link />} />
    </Routes>
  );
};

export default Routers;
