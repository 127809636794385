import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrency } from "../../reducers/currencyReducer";

import backArrow from "../../assets/icons/back-arrow-white.svg";
import people from "../../assets/icons/people.svg";
import money from "../../assets/icons/money.svg";
import check from "../../assets/icons/check-fill.svg";

import { currency_data } from "../../data/const";

import {
  DropDownMenu,
  DropDownMenuContent,
  DropDownMenuItem,
  DropDownMenuTrigger,
} from "../dropdown-menu/dropdown-menu";
import Flag from "react-world-flags";
import { Popup, PopupContent, PopupTrigger } from "../popup/popup";
import { useTranslation } from "react-i18next";

const Header = ({ data, onBack, onChange, currency }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);
  const headerText = () => {
    if (data?.tripType === "one way") {
      return `${data?.from} > ${data?.to}`;
    } else {
      return `${data?.from} > ${data?.to} > ${data?.from}`;
    }
  };

  const setTripType = () => {
    if (data?.tripType === "one way") {
      return `one_way`;
    } else {
      return `round_trip`;
    }
  };

  return (
    <div className="w-full flex justify-between h-fit px-5">
      <Popup>
        <PopupTrigger
          onClick={() => setIsPopUp((prev) => !prev)}
          classes={"mr-2 flex items-center"}
        >
          <img src={backArrow} alt="back-arrow" className="cursor-pointer" />
        </PopupTrigger>
        <PopupContent
          isShow={isPopUp}
          classes={
            "fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
          }
        >
          <div className="home-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
            <div className="absolute z-[-1] w-full h-full bg-[var(--black)] opacity-[50%]" />
            <div className="box-border px-[46px] pt-[36px] w-[311px] h-[324px] bg-[var(--white)] rounded-[4px]">
              <h1 className="font-[700] mb-[32px] flex justify-center text-[length:--title]">
                {t(`sure_alert.title`)}
              </h1>
              <p
                className="flex flex-wrap text-center text-[var(--black)] text-[length:--body] font-[400] 
                                items-center pb-[30px] justify-center"
              >
                {t("sure_alert.content")}
              </p>
              <div className="flex w-full justify-center mb-3">
                <button
                  className="flex box-border h-[48px] w-[163px] items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPopUp(false);
                    onBack();
                  }}
                >
                  {t("sure_alert.yes")}
                </button>
              </div>
              <div className="flex w-full justify-center">
                <button
                  className="flex box-border h-[48px] w-[163px] items-center justify-center
                    bg-[var(--white)] border-[1px] border-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--accent)] font-[600]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPopUp(false);
                  }}
                >
                  {t("sure_alert.cancel")}
                </button>
              </div>
            </div>
          </div>
        </PopupContent>
      </Popup>
      <div className="flex flex-col">
        <p className="font-[600] text-[var(--white)] text-[length:--title-s]">
          {headerText()}
        </p>
        <div className="flex items-center font-[400] text-[var(--white)] justify-center text-[length:--body-s]">
          <span className="border-r-[1px] border-[var(--white)] pr-2 mr-2">{`${t(
            `search_page.${setTripType()}`
          )} (${data?.startDate} ${
            data?.endDate !== null ? ` - ${data?.endDate}` : ""
          })`}</span>
          <div className="flex items-center gap-[4px] justify-center">
            <img src={people} alt="people" />
            <p className="relative">{data?.count}</p>
          </div>
        </div>
      </div>
      <DropDownMenu classes="flex items-center justify-center relative">
        <DropDownMenuTrigger
          classes="flex relative items-center gap-[5px]"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <div className="pl-4 flex justify-center items-center">
            <img src={money} alt="money" />
          </div>
        </DropDownMenuTrigger>
        <DropDownMenuContent
          classes="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
          isShow={isOpen}
        >
          <div className="currency-picker w-[375px] relative  h-full">
            <div
              className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
              onClick={() => setIsOpen(false)}
            />
            <div className="absolute px-[24px] pt-[30px] pb-[55px] rounded-t-[12px] shadow-lg bottom-0 w-full h-[294px] bg-[var(--white)]">
              <div className="flex flex-col gap-3">
                {currency_data.map((item) => (
                  <DropDownMenuItem
                    key={item.key}
                    classes={`relative hover:cursor-pointer text-[length:--title-s] box-border px-[24px] py-[14px] h-[52px]
                                rounded-[5px] flex items-center justify-between
                               ${
                                 item.value === currency?.value
                                   ? "border-[1px] bg-[#fed8cb] border-[var(--accent)] text-[var(--accent)]"
                                   : "bg-[var(--gray6)]"
                               }`}
                    onClick={() => {
                      dispatch(setCurrency(item));
                      onChange(item);
                      setIsOpen((prev) => !prev);
                    }}
                  >
                    <div className="flex gap-[16px] ">
                      <Flag code={item.flag} className="h-[24px] w-[24px]" />
                      <p>{t(`currency.${item.name}`)}</p>
                    </div>
                    {item?.value === currency?.value && (
                      <img src={check} alt="check" />
                    )}
                  </DropDownMenuItem>
                ))}
              </div>
            </div>
          </div>
        </DropDownMenuContent>
      </DropDownMenu>
    </div>
  );
};

export default Header;
