import React from "react";
import { useTranslation } from "react-i18next";

const TravellerInfoHeader = ({ persons, onAdd }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-between">
      <h4 className="text-[length:--title-s] font-[600]">
        ({persons?.adult}){" "}
        {t(`traveller-info.adults`, { count: persons?.adult })} • (
        {persons?.child}){" "}
        {t(`traveller-info.children`, { count: persons?.child })} • (
        {persons?.infant}){" "}
        {t(`traveller-info.infants`, { count: persons?.infant })}
      </h4>
      <button
        className="text-[length:--title-s] font-[600] text-[var(--accent)]"
        onClick={(e) => {
          e.preventDefault();
          onAdd();
        }}
      >
        {t("add_passenger.add")}
      </button>
    </div>
  );
};

export default TravellerInfoHeader;
