import React from "react";
import DatePicker from "react-datepicker";

import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

import "./DatePicker.scss";

const DateRangePicker = ({ selectRange, onChange, startDate, endDate }) => {
  return (
    <div className="date-picker-wrapper flex w-full h-fit justify-center">
      <DatePicker
        selectsRange={selectRange}
        startDate={startDate}
        endDate={endDate}
        showPopperArrow={false}
        dateFormat="yyyy/MM/dd"
        inline
        selected={startDate}
        onChange={onChange}
      />
    </div>
  );
};

export default DateRangePicker;
