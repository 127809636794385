import React from "react";

import Logo from "../../assets/images/logo.svg";

const HeaderLogo = () => {
  return (
    <div className="flex gap-[10px] items-center">
      <div className="logo-img-div">
        <img src={Logo} alt="logo" className="" />
      </div>
      <h1 className="text-[18px] text-[var(--white)] font-[700]">ATour</h1>
    </div>
  );
};

export default HeaderLogo;
