import React from "react";

//utils
import { chooseLogo } from "../../../../utils/utils";

import close from "../../../../assets/icons/close.svg";

const SelectedFlightHeader = ({ data, onClose }) => {
  return (
    <div className="w-full relative h-fit flex items-center justify-between">
      <div className="flex  items-center gap-3">
        <div className="w-[30px] h-[30px]">{chooseLogo(data?.airline)}</div>
        <div className="flex flex-col">
          <h1 className="text-[length:--title] font-[600]">{data?.airline}</h1>
          <p className="text-[length:--body-s] font-[400] text-[var(--gray5)]">
            {data?.class}
          </p>
        </div>
      </div>
      <div className="w-6 h-6 cursor-pointer" onClick={onClose}>
        <img src={close} alt="close-icon" className="w-full h-full" />
      </div>
    </div>
  );
};

export default SelectedFlightHeader;
