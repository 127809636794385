import React from "react";

import messenger from "../../assets/icons/fb_messenger.svg";
import viber from "../../assets/icons/viber.svg";
import wechat from "../../assets/icons/wechat.svg";
import telegram from "../../assets/icons/telegram.svg";
import { useNavigate } from "react-router-dom";

const ICONS = [messenger, viber, wechat, telegram];

const Footer = ({ children }) => {
  const navigate = useNavigate();
  return (
    <div className="footer flex flex-col  items-center justify-center w-full">
      {children}
      <div className="icons flex gap-5">
        {ICONS.map((icon, index) => (
          <img
            key={index}
            src={icon}
            alt="icon"
            onClick={() => {
              if (index === 0) {
                navigate("/link");
              }
            }}
            className="cursor-pointer"
          />
        ))}
      </div>
    </div>
  );
};

export default Footer;
