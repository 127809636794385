import React from "react";
import { useTranslation } from "react-i18next";

const PassengerCard = ({ info, isNrc, border }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative w-full flex flex-col bg-[var(--gray6)] py-6 px-4 rounded-[4px]`}
    >
      <h1 className="text-[length:--title] font-[600]">
        {isNrc ? info?.name : `${info?.firstName} ${info?.lastName}`}
      </h1>
      {isNrc ? (
        <p className="text-[length:--body-s] text-[var(--gray7)]">
          {t("traveller-info.nrc")}:{" "}
          {`${info?.nrc_prefix?.value}${info?.nrc_middle?.value} (${info?.nrc_suffix?.value}) ${info?.nrcNumber}`}{" "}
        </p>
      ) : (
        <p className="text-[length:--body-s] text-[var(--accent)]">
          {t("traveller-info.passport")}: {info?.passport}
        </p>
      )}
      {info?.contactPhone !== undefined && (
        <div className="flex w-full items-center">
          <p className="text-[length:--body-s] text-[var(--black)]">
            {info?.contactPhone}
          </p>
          <div className="flex w-[1px] mx-1 border-l-[1px] border-[var(--black)] h-[15px]" />
          <p className="text-[length:--body-s] text-[var(--black)]">
            {info?.contactMail}
          </p>
        </div>
      )}
      {border && (
        <div className="absolute bottom-0 left-0 w-full border-b-[1px] border-[var(--gray4)] " />
      )}
    </div>
  );
};

export default PassengerCard;
