import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "../data/en.json";
import myTranslation from "../data/mm.json";
import zhTranslation from "../data/zh_cn.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslation,
    },
    my: {
      translation: myTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
