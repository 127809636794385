import React from "react";

const HomeInputBox = ({
  icon,
  label,
  value,
  handleChange,
  classes,
  onClick,
  name,
  autoFocus,
}) => {
  return (
    <div
      className={`icon-input-box relative flex items-center gap-4 ${classes} cursor-pointer`}
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <img src={icon} alt="icon" />
        {label && (
          <label className="text-[length:--title-s] text-[var(--balck)] opacity-[50%] font-[600]">
            {label}:
          </label>
        )}
      </div>
      <input
        type="text"
        onChange={handleChange}
        name={name}
        value={value}
        onClick={onClick}
        autoFocus={autoFocus}
        className="bg-[transparent] flex-1 w-[65%] truncate absolute left-[100px] focus:outline-none text-[length:--body] font-[400]"
      />
    </div>
  );
};

export default HomeInputBox;
