import React, { useRef } from "react";

import Select from "react-select";

import "./DropDownInput.scss";
import { useTranslation } from "react-i18next";

const DropdownInput = ({
  label,
  data,
  onChange,
  readOnly,
  options,
  formNumber,
  required,
}) => {
  const dropDownRef = useRef(null);
  const {t} = useTranslation();

  return (
    <div className="relative w-full h-fit flex flex-col gap-2">
      {label && (
        <label className="font-[600] text-[length:--body-s]">{label}</label>
      )}
      <Select
        required={required}
        isDisabled={readOnly}
        openMenuOnFocus={true}
        value={data?.value}
        onChange={(e) => {
          const parameter = {
            target: {
              name: e.name,
              value: e.value,
              label: e.label,
              nrcCode: e.nrc_code,
              formNumber: formNumber,
            },
          };
          onChange(parameter);
        }}
        ref={dropDownRef}
        placeholder={data?.placeHolder ?? t("select")}
        options={options}
        className="select-box"
      />
    </div>
  );
};

export default DropdownInput;
