import React from "react";
import { capitalizeFirstLetter } from "../../utils/utils";

import { People } from "../../data/icon";
import backArrow from "../../assets/icons/back-arrow-white.svg";

const HistoryCard = ({ data, onClick, onBack, color }) => {
  const headerText = () => {
    if (data?.tripType === "one way") {
      return `${data?.from} > ${data?.to}`;
    } else {
      return `${data?.from} > ${data?.to} > ${data?.from}`;
    }
  };
  return (
    <div
      className="history-card text-[var(--gray8)] history-card w-full flex h-fit rounded-[8px] p-4 
      border-[1px] border-[var(--gray0)] cursor-pointer bg-[var(--white)]"
      onClick={onClick}
    >
      {onBack !== undefined && (
        <div className="mr-4 ml-1 flex items-center">
          <img
            src={backArrow}
            alt="back-arrow"
            onClick={onBack}
            className="cursor-pointer"
          />
        </div>
      )}
      <div className="flex flex-col">
        <p className="font-[600] text-[length:--title-s]">{headerText()}</p>
        <div className="flex items-center font-[400] text-[length:--body-s]">
          <span className="border-r-[1px] trip-info border-[var(--gray8)] pr-2 mr-2">{`${capitalizeFirstLetter(
            data?.tripType
          )} (${data?.startDate} ${
            data?.endDate !== null ? `- ${data?.endDate}` : ""
          })`}</span>
          <div className="flex items-center gap-2 justify-center">
            <People color={color} />
            <p className="relative bottom-[-2px]">{data?.count}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryCard;
