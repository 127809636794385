import { createSlice } from "@reduxjs/toolkit";

const initialState = "English";

export const languageSlice = createSlice({
  name: "currency",
  initialState: initialState,
  reducers: {
    setlanguage: (state, action) => {
      return action.payload;
    },
  },
});

export const { setlanguage } = languageSlice.actions;

export default languageSlice.reducer;
