import React from "react";
import TextInput from "../../../../components/inputs/text-input/TextInput";
import DropdownInput from "../../../../components/inputs/dropdown-input/DropDownInput";
import { genders, nrc_prefixs, nrc_suffixs } from "../../../../data/const";
import { getNrcRegions } from "../../../../utils/utils";

import minu from "../../../../assets/icons/minus1.svg";
import { useTranslation } from "react-i18next";

const PassengerForm = ({
  data,
  onChange,
  formNumber,
  isShowNrc,
  count,
  onRemove,
}) => {
  const { t } = useTranslation();
  const translatedOptions = genders.map((option) => {
    return {
      ...option,
      label: t(`${option.label}`),
    };
  });
  console.log(data?.personType)
  return (
    <div className="relative w-full h-full flex flex-col border-[1px] border-[var(--gray0)] px-3 py-4 rounded-[8px]">
      <div className="flex justify-between items-center mb-4">
        <p className="flex items-center justify-center box-border h-[24px] w-[81px] bg-[var(--gray8)] text-[length:--title-xs] text-[var(--white)] rounded-[2px] ">
          {t(`traveller-info.${data?.personType}`)} {count}
        </p>
        {data?.contactPhone !== undefined ? (
          <p className="text-[length:--title-xs] text-[var(--green2)] font-[600]">
            *{t("traveller-info.contact_person")}
          </p>
        ) : (
          <img
            src={minu}
            alt="minus"
            onClick={() => onRemove(formNumber, data?.personType)}
            className="cursor-pointer"
          />
        )}
      </div>
      {isShowNrc ? (
        <div className="w-full flex flex-col gap-2">
          <div className="flex w-full items-center justify-between">
            <TextInput
              label={t("traveller-info.name")}
              data={{ value: data?.name, name: "name" }}
              formNumber={formNumber}
              onChange={onChange}
              type="text"
              required={true}
            />
          </div>
          <div className="flex w-full flex-col justify-between gap-2">
            <label className="font-[600] text-[length:--body-s]">
              {t("traveller-info.nrc")}
            </label>
            <div className="flex w-full gap-2">
              <div className="w-[66px]">
                <DropdownInput
                  data={{ value: data?.nrc_prefix }}
                  options={nrc_prefixs}
                  formNumber={formNumber}
                  onChange={onChange}
                  required={true}
                />
              </div>
              <div className="w-[84px]">
                <DropdownInput
                  data={{ value: data?.nrc_middle }}
                  options={getNrcRegions(data?.nrc_prefix?.nrcCode)}
                  formNumber={formNumber}
                  onChange={onChange}
                  required={true}
                />
              </div>
              <div className="w-[70px]">
                <DropdownInput
                  data={{ value: data?.nrc_suffix }}
                  options={nrc_suffixs}
                  formNumber={formNumber}
                  onChange={onChange}
                  required={true}
                />
              </div>
              <div className="w-[104px]">
                <TextInput
                  data={{ value: data?.nrcNumber, name: "nrcNumber" }}
                  formNumber={formNumber}
                  onChange={onChange}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full items-center justify-between">
            <DropdownInput
              label={t("traveller-info.gender")}
              data={{ value: data?.gender }}
              options={translatedOptions}
              formNumber={formNumber}
              onChange={onChange}
              required={true}
            />
          </div>
          {data?.contactPhone !== undefined && (
            <div className="flex w-full items-center justify-between gap-3">
              <TextInput
                label={t("traveller-info.contact_phone")}
                data={{ value: data?.contactPhone, name: "contactPhone" }}
                formNumber={formNumber}
                onChange={onChange}
                type="number"
                required={true}
              />
            </div>
          )}
          {data?.contactMail !== undefined && (
            <div className="flex w-full items-center justify-between gap-3">
              <TextInput
                label={t("traveller-info.contact_mail")}
                data={{ value: data?.contactMail, name: "contactMail" }}
                formNumber={formNumber}
                onChange={onChange}
                type="text"
                required={true}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex flex-col gap-2">
          <div className="flex w-full items-center justify-between gap-3">
            <div className="w-[153.5px]">
              <TextInput
                label={t("traveller-info.first_name")}
                data={{ value: data?.firstName, name: "firstName" }}
                formNumber={formNumber}
                onChange={onChange}
                required={true}
              />
            </div>
            <div className="w-[153.5px]">
              <TextInput
                label={t("traveller-info.last_name")}
                data={{ value: data?.lastName, name: "lastName" }}
                formNumber={formNumber}
                onChange={onChange}
                required={true}
              />
            </div>
          </div>
          <div className="flex w-full items-center justify-between gap-3">
            <div className="w-[153.5px]">
              <TextInput
                label={t("traveller-info.passport")}
                data={{ value: data?.passport, name: "passport" }}
                formNumber={formNumber}
                onChange={onChange}
                required={true}
              />
            </div>
            <div className="w-[153.5px]">
              <DropdownInput
                label={t("traveller-info.gender")}
                data={{ value: data?.gender }}
                options={translatedOptions}
                formNumber={formNumber}
                onChange={onChange}
                required={true}
              />
            </div>
          </div>
          {data?.contactPhone !== undefined && (
            <div className="flex w-full items-center justify-between gap-3">
              <TextInput
                label={t("traveller-info.contact_phone")}
                data={{ value: data?.contactPhone, name: "contactPhone" }}
                formNumber={formNumber}
                onChange={onChange}
                type="number"
                required={true}
              />
            </div>
          )}
          {data?.contactMail !== undefined && (
            <div className="flex w-full items-center justify-between gap-3">
              <TextInput
                label={t("traveller-info.contact_mail")}
                data={{ value: data?.contactMail, name: "contactMail" }}
                formNumber={formNumber}
                onChange={onChange}
                type="text"
                required={true}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PassengerForm;
