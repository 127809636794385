import React from "react";

import plus from "../../assets/icons/plus1.svg";
import { useTranslation } from "react-i18next";

const ADDPERSON = [
  {
    text: "add_adult",
    personType: "adult",
  },
  {
    text: "add_child",
    personType: "child",
  },
  {
    text: "add_infant",
    personType: "infant",
  },
];

const AddPerson = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="add-person w-full h-full">
      <h2 className="text-[length:--title-s] w-full flex text-center justify-center font-[600] text-[lenth:--title-s] mb-[20px]">
        {t("add_passenger.add_person")}
      </h2>
      <div className="flex flex-col gap-3">
        {ADDPERSON.map((item, index) => (
          <button
            key={index}
            className="flex w-full gap-4 px-6 py-[14px] bg-[var(--white1)] rounded-[5px]"
            onClick={() => onClick(item.personType)}
          >
            <img src={plus} alt="plus-icon" />
            <span className="font-[600] text-[length:--title]">
              {t(`add_passenger.${item.text}`)}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AddPerson;
