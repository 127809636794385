import React from "react";

import IconButton from "../../../../components/buttons/icon-button/IconButton";
import { RadioButton } from "../../../../components/radio/radio-button/RadioButton";

import { PERSON } from "../../../../data/enums";

//icons
import minus from "../../../../assets/icons/minus.svg";
import plus from "../../../../assets/icons/plus.svg";
import { useTranslation } from "react-i18next";

const DISPLAY_TEXT = {
  adult: "adult_range",
  child: "child_range",
  infant: "infant_range",
};

const QuantityPicker = ({
  persons,
  setPersons,
  onClick,
  radioButtons,
  setRadioButtons,
}) => {
  const { t } = useTranslation();
  const handleRadioClick = (value) => {
    setRadioButtons((prev) => {
      return prev.map((item) => {
        if (item.name === value.name) {
          return {
            ...item,
            isChecked: true,
          };
        }
        return {
          ...item,
          isChecked: false,
        };
      });
    });
  };
  return (
    <div className="quantity-picker flex w-full h-full flex-col">
      <div className="radio-group flex gap-3 mb-[32px]">
        {radioButtons.map((item) => (
          <RadioButton
            translation_key={"traveller-info"}
            key={item.key}
            onClick={(e) => {
              e.stopPropagation();
              handleRadioClick(item);
            }}
            item={item}
            color={item.isChecked ? "#FF3D00" : "none"}
          />
        ))}
      </div>
      <div className="quantity-picker-wrapper mb-[35px] flex flex-col gap-[32px]">
        {Object.entries(PERSON).map(([key, value]) => {
          return (
            <div key={key} className="flex w-full items-center">
              <IconButton
                onClick={() => {
                  setPersons((prev) => {
                    if (value.name === "adult" && prev[value.name] === 1) {
                      return { ...prev };
                    } else if (prev[value.name] > 0) {
                      return {
                        ...prev,
                        [value.name]: prev[value.name] - 1,
                      };
                    }
                    return { ...prev };
                  });
                }}
                classes="bg-[var(--gray0)] px-[16px] rounded-tl-[5px] rounded-bl-[5px]  py-[14px]"
                icon={minus}
              />
              <div className="flex flex-col flex-1 justify-center items-center">
                <h4 className="text-[length:--number]">
                  {persons[value.name]}
                </h4>
                <p className="text-[var(--gray2)] text-[length:--body-s]">
                  {t(`progress.${DISPLAY_TEXT[value.name]}`)}
                </p>
              </div>
              <IconButton
                onClick={() => {
                  setPersons((prev) => {
                    return {
                      ...prev,
                      [value.name]: prev[value.name] + 1,
                    };
                  });
                }}
                classes="bg-[var(--gray0)] px-[16px] rounded-rl-[5px] rounded-br-[5px]  py-[14px]"
                icon={plus}
              />
            </div>
          );
        })}
      </div>
      <div className="w-full flex items-center justify-center">
        <button
          className="flex box-border w-[319px] h-[48px] items-center justify-center
                   bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
          onClick={onClick}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default QuantityPicker;
