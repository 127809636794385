import React from "react";

import "./TextInput.scss";

const TextInput = ({
  data,
  label,
  onChange,
  readOnly,
  formNumber,
  type = "text",
  required,
}) => {
  return (
    <div className="relative w-full h-fit flex flex-col gap-2">
      {label && (
        <label className="font-[600] text-[length:--body-s]">{label}</label>
      )}
      <div className="w-full flex items-center justify-center h-[48px] px-2 bg-[var(--gray9)] rounded-[5px]">
        <input
          required={required}
          type={type}
          readOnly={readOnly}
          value={data?.value}
          name={data?.name}
          placeholder={data?.placeHolder}
          onChange={(e) => {
            const parameter = {
              target: {
                value: e.target.value,
                name: e.target.name,
                formNumber: formNumber,
              },
            };
            onChange(parameter);
          }}
          className="appearance-none font-[400] w-full relative bg-[var(--gray9)] text-[length:--body] focus:outline-none rounded-[5px]"
        />
      </div>
    </div>
  );
};

export default TextInput;
