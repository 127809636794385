import React, { useMemo, useState } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

//components
import DateCard from "../../../../components/date-card/DateCard";
import FlightCard from "../../../../components/flight-card/FlightCard";
import {
  DropDownMenu,
  DropDownMenuContent,
  DropDownMenuTrigger,
} from "../../../../components/dropdown-menu/dropdown-menu";
import Filter from "../filter/Filter";
import SelectedFlight from "../selected-flight/SelectedFlight";

//utils
import { formatDateHeader } from "../../../../utils/format";
import { daysFormatForSelectedDates, getDate } from "../../../../utils/utils";

//icons
import close from "../../../../assets/icons/close.svg";
import dropdownRed from "../../../../assets/icons/dropdown-red.svg";

//const
import {
  filters_values,
  layover_data,
  one_way_flights_data,
  return_flights_data,
} from "../../../../data/data";
import { departure_flights_data } from "../../../../data/data";

//css
import "./AvailableFlights.scss";
import Layover from "../../../../components/layover-flights/Layover";

const AvailableFlights = ({
  // availableDates,
  selectedDates,
  setSelectedDates,
  setSelectedFlights,
  selectedFlights,
  flight_type,
  currency,
  changeTab,
  tabs,
  activeTab,
  isLayover,
}) => {
  const { t } = useTranslation();
  const header_text =
    flight_type === "departure"
      ? `${t("search_page.depart_from")}`
      : `${t("search_page.arrive_to")}`;
  const { startDate, endDate, tripType } = useSelector(
    (state) => state.flightInfo.flightInfo
  );
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [flight, setFlight] = useState(null);
  const [filters, setFilters] = useState({
    class: "All",
    price_range: "All",
    flight_duration: "All",
    time_of_flight: "All",
    layover_time: "All",
  });
  const [flightDetailOpen, setFlightDetailOpen] = useState(false);
  const availabel_dates = useMemo(() => {
    if (tripType === "one way") {
      return getDate(startDate);
    }
    return daysFormatForSelectedDates(startDate, endDate);
  }, [startDate, endDate, tripType]);

  const onResetFilter = () => {
    setFilters((prev) => {
      const updatedFilters = {};
      Object.entries(prev).forEach(([key, value]) => {
        updatedFilters[key] = "All";
      });
      return updatedFilters;
    });
  };

  const onClose = () => {
    setFlightDetailOpen(false);
  };

  const chooseFlightData = () => {
    if (isLayover) {
      return layover_data;
    } else {
      if (tabs?.length === 4) {
        if (flight_type === "departure") {
          return departure_flights_data;
        } else {
          return return_flights_data;
        }
      } else {
        return one_way_flights_data;
      }
    }
  };
  return (
    <div className="available-flights relative w-full h-full available-flight">
      <div className="w-full h-fit my-3 mb-5">
        <Swiper spaceBetween={8} slidesPerView={4}>
          {availabel_dates.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="box-border w-[85px] h-[50px]">
                <DateCard
                  classses={`cursor-pointer flex flex-col items-center justify-center rounded-[4px]  border-[1px] 
                    ${
                      item?.date === selectedDates?.[flight_type]
                        ? "border-[var(--accent)] bg-[#fbe7e1]"
                        : "border-[var(--gray3)]"
                    }`}
                  data={item}
                  currency={currency}
                  isSelected={item?.date === selectedDates?.[flight_type]}
                  onClick={() => {
                    setSelectedDates((prev) => {
                      return {
                        ...prev,
                        [flight_type]: item?.date,
                      };
                    });
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-full flex h-fit relative items-center justify-between pb-2 px-6">
        <div className="flex items-center flex-col">
          <div className="flex relative h-full">
            <h1 className="text-[length:--title-s] font-[600]">
              {header_text}
            </h1>
            <div className="border-l-[2px] border-[var(--black)] mx-2" />
            <p className="text-[length:--title-s] font-[600]">
              {formatDateHeader(selectedDates[flight_type], true)}
            </p>
          </div>
          <p className="text-[length:--title-xs] w-full font-[600] text-[var(--gray2)]">
            {t("progress.single_person_price")}
          </p>
        </div>
        <div className="flex">
          <DropDownMenu classes="relative">
            <DropDownMenuTrigger
              classes="flex relative items-center gap-[6px]"
              onClick={() => {
                setIsFilterOpen((prev) => !prev);
              }}
            >
              <p className="text-[length:--title-s] text-[var(--accent)] font-[600]">
                {t("filter.filter")}
              </p>
              <img src={dropdownRed} alt="drop-down" />
            </DropDownMenuTrigger>
            <DropDownMenuContent
              classes="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
              isShow={isFilterOpen}
            >
              <div className="filter-wrapper w-[375px] relative  h-full">
                <div
                  className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
                  onClick={() => setIsFilterOpen(false)}
                />
                <div className="absolute rounded-t-[12px] shadow-lg bottom-0 w-full h-[510px] bg-[var(--white)]">
                  <div className="px-[24px] pt-[30px] relative w-full h-full">
                    <div className="absolute top-4 right-6">
                      <img
                        src={close}
                        alt="close"
                        className="cursor-pointer"
                        onClick={() => setIsFilterOpen(false)}
                      />
                    </div>
                    <h2 className="text-[length:--title-s] flex items-center justify-center font-[600]">
                      {t("filter.select_filter")}
                    </h2>
                    <div className="mt[29px] box-border w-full flex gap-4 flex-col">
                      {Object.entries(filters_values).map(([key, value]) => {
                        return (
                          <div key={key} className="w-full h-fit">
                            <Filter
                              data={{
                                name: value?.name,
                                filters: value.values,
                              }}
                              onClick={(e) =>
                                setFilters((prev) => {
                                  return {
                                    ...prev,
                                    [key]: e.target.value,
                                  };
                                })
                              }
                              value={filters[key]}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="flex mt-[28px] justify-between">
                      <button
                        className="w-[155px] h-[48px] text-center border-[1px]
                        rounded-[6px] border-[var(--accent)] text-[length:--title] text-[var(--accent)]"
                        onClick={onResetFilter}
                      >
                        {t("filter.reset_filter")}
                      </button>
                      <button
                        className="w-[155px] h-[48px] text-center border-[1px]
                        rounded-[6px] bg-[var(--accent)] text-[length:--title] text-[var(--white)]"
                        onClick={() => setIsFilterOpen(false)}
                      >
                        {t("filter.apply_filter")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </DropDownMenuContent>
          </DropDownMenu>
        </div>
      </div>
      <div className="flights-data-wrapper flex gap-4 flex-col px-4 py-4">
        {chooseFlightData().map((item, index) => (
          <FlightCard
            key={index}
            data={item}
            currency={currency}
            isSelected={selectedFlights?.[flight_type]?.id === item?.id}
            onClick={() => {
              setFlightDetailOpen(true);
              setFlight(item);
            }}
          />
        ))}
      </div>
      {flightDetailOpen && (
        <div className="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full">
          <div className="flight-detail-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
            <div
              className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
              onClick={onClose}
            />
            {isLayover ? (
              <div className="absolute h-full w-full top-0 left-0 bg-[var(--white)] shadow-lg">
                <Layover
                  onClose={onClose}
                  flight={flight}
                  onClick={(item) => {
                    setSelectedFlights((prev) => {
                      return {
                        ...prev,
                        [flight_type]: item,
                      };
                    });
                    setFlightDetailOpen(false);
                    const indexValue = tabs.indexOf(activeTab) + 1;
                    changeTab(tabs[indexValue]);
                  }}
                />
              </div>
            ) : (
              <div className="absolute px-[22px] py-[20px] rounded-t-[12px] shadow-lg bottom-0 w-full h-[60%] overflow-y-scroll overflow-x-hidden bg-[var(--white)]">
                <SelectedFlight
                  currency={currency}
                  data={flight}
                  onClick={(item) => {
                    setSelectedFlights((prev) => {
                      return {
                        ...prev,
                        [flight_type]: item,
                      };
                    });
                    setFlightDetailOpen(false);
                    const indexValue = tabs.indexOf(activeTab) + 1;
                    changeTab(tabs[indexValue]);
                  }}
                  onClose={onClose}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableFlights;
