import React from "react";
import Flag from "react-world-flags";

const AirportCard = ({ airport, border, name, handleClick, handleBack }) => {
  return (
    <div
      className={`airport-card flex items-center gap-3 w-full h-fit cursor-pointer p-3 ${
        border && "border-b-[1px] border-b-[var(--black)]-[100]"
      }`}
      onClick={() => {
        const e = {
          target: {
            value: `${airport.city},${airport.name}`,
            name: name,
            city: airport?.city,
            country: airport?.country,
            airport_code : airport?.airport_code
          },
        };
        handleClick(e);
        handleBack();
      }}
    >
      <Flag code={airport.country_code} className="h-[24px] w-[24px]" />
      <div className="flex flex-col">
        <h2 className="text-[length:--body] font-[400]">{airport.name}</h2>
        <div className="flex gap-3 text-[length:--body-s] text-[var(--gray2)] font-[400]">
          <span>{airport.city}</span> <span>|</span>{" "}
          <span>{airport.country}</span>
        </div>
      </div>
    </div>
  );
};

export default AirportCard;
