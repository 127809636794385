export const PERSON = {
  ADULT: {
    name: "adult",
    age: {
      from: 12,
      to: 200,
    },
  },
  CHILD: {
    name: "child",
    age: {
      from: 2,
      to: 11,
    },
  },
  INFANT: {
    name: "infant",
    age: {
      from: 0,
      to: 2,
    },
  },
};