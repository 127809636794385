import React, { useMemo, useState } from "react";
import TravellerInfoHeader from "../traveller-info-header/TravellerInfoHeader";
import PassengerForm from "../passenger-form/PassengerForm";
import { Popup, PopupContent } from "../../../../components/popup/popup";

import "./TravellerInfo.scss";
import { useSelector } from "react-redux";
import {
  DropDownMenu,
  DropDownMenuContent,
} from "../../../../components/dropdown-menu/dropdown-menu";
import AddPerson from "../../../../components/add-person/AddPerson";
import useInfo from "../../../../hooks/useInfo";
import { useTranslation } from "react-i18next";

const TravellerInfo = ({ data, setData, changeTab, isDomestic }) => {
  const { t } = useTranslation();
  const [isAdd, setIsAdd] = useState(false);
  const { tripType, trip, startDate, endDate, persons, citizens } = useSelector(
    (state) => state.flightInfo.flightInfo
  );
  const [people, setPeople] = useState(persons);
  console.log(persons);
  let peopleCount = {
    adult: 0,
    child: 0,
    infant: 0,
  };
  const isResident = useMemo(() => {
    const isChecked = citizens?.find((item) => item?.key === "myanmar citizen");
    return isChecked?.isChecked;
  }, [citizens]);

  const initialFormState = useMemo(() => {
    const noOfForm = Object.values(persons).reduce(
      (total, value) => total + value,
      0
    );
    let adultCount = 0;
    let childCount = 0;
    if (data?.length > 0) {
      const copyData = data;
      setData([]);
      return copyData;
    }
    if (isDomestic && isResident) {
      return Array.from({ length: noOfForm }, (_, index) => {
        let personType = "";
        if (adultCount < persons?.adult) {
          personType = "adult";
          adultCount++;
        } else if (childCount < persons?.child) {
          personType = "child";
          childCount++;
        } else {
          personType = "infant";
        }
        if (index === 0) {
          return {
            id: index,
            personType: personType,
            name: "",
            nrc_prefix: null,
            nrc_middle: null,
            nrc_suffix: null,
            nrcNumber: "",
            gender: null,
            contactPhone: "",
            contactMail: "",
          };
        } else {
          return {
            id: index,
            personType: personType,
            name: "",
            nrc_prefix: null,
            nrc_middle: null,
            nrc_suffix: null,
            nrcNumber: "",
            gender: null,
          };
        }
      });
    }
    return Array.from({ length: noOfForm }, (_, index) => {
      let personType = "";
      if (adultCount < persons?.adult) {
        personType = "adult";
        adultCount++;
      } else if (childCount < persons?.child) {
        personType = "child";
        childCount++;
      } else {
        personType = "infant";
      }
      if (index === 0) {
        return {
          id: index,
          personType: personType,
          firstName: "",
          lastName: "",
          passport: "",
          gender: null,
          contactPhone: "",
          contactMail: "",
        };
      } else {
        return {
          id: index,
          personType: personType,
          firstName: "",
          lastName: "",
          passport: "",
          gender: null,
        };
      }
    });
    // eslint-disable-next-line
  }, [persons, isDomestic, isResident]);

  const [form, setForm] = useState(initialFormState);
  const [isPopUp, setIsPopUp] = useState(false);

  const addForm = (personType) => {
    let copyForm = [...form];
    let lastIndex = form
      .map((item) => item?.personType)
      .lastIndexOf(personType);
    if (lastIndex === -1) {
      lastIndex = form?.length - 1;
    }
    if (isDomestic && isResident) {
      copyForm.splice(lastIndex + 1, 0, {
        id: lastIndex,
        personType: personType,
        name: "",
        nrc_prefix: null,
        nrc_middle: null,
        nrc_suffix: null,
        nrcNumber: "",
        gender: null,
      });
    } else {
      copyForm.splice(lastIndex + 1, 0, {
        id: lastIndex,
        personType: personType,
        firstName: "",
        lastName: "",
        passport: "",
        gender: null,
      });
    }
    setForm(copyForm);
  };

  const onRemove = (index) => {
    let copyForm = [...form];
    copyForm.splice(index, 1);
    setForm(copyForm);
  };

  const checkValidations = () => {
    for (const item of form) {
      for (const value of Object.values(item)) {
        if (value === "" || value === undefined || value === null) {
          return false;
        }
      }
    }
    return true;
  };

  const handleChange = (e) => {
    setForm((perv) => {
      return perv.map((item, index) => {
        if (index === e.target?.formNumber) {
          if (e.target.name === "gender" || e.target.name.includes("nrc_")) {
            if (e.target.name === "nrc_prefix") {
              return {
                ...item,
                nrc_middle: null,
                [e.target.name]: e.target,
              };
            }
            return {
              ...item,
              [e.target.name]: e.target,
            };
          }
          return {
            ...item,
            [e.target.name]: e.target.value,
          };
        }
        return { ...item };
      });
    });
  };

  useInfo({
    tripType,
    trip,
    startDate,
    endDate,
    persons: people,
    citizens,
  });
  return (
    <div className="traveller-info w-full h-full flex flex-col">
      <div className="flex flex-col px-5 py-4 pb-0">
        <TravellerInfoHeader
          persons={persons}
          onAdd={() => setIsAdd((prev) => !prev)}
        />
      </div>

      <form className="flex flex-col traveller-info-wrapper">
        <div className="flex flex-col justify-center items-center gap-4 px-4 py-4">
          {form.map((item, index) => {
            peopleCount[item?.personType]++;
            return (
              <div key={index} className="relative w-[343px] box-border">
                <PassengerForm
                  formNumber={index}
                  count={peopleCount[item?.personType]}
                  data={item}
                  onChange={handleChange}
                  onRemove={(formNumber, person) => {
                    setPeople((prev) => {
                      return {
                        ...prev,
                        [person]: prev[person] - 1,
                      };
                    });
                    onRemove(formNumber);
                  }}
                  isShowNrc={isDomestic && isResident}
                />
              </div>
            );
          })}
        </div>
        <div className="flex w-full justify-center pb-4">
          <button
            type="button"
            className={`flex box-border w-[327px] h-[48px] items-center justify-center
              text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]
              ${
                checkValidations() ? "bg-[var(--accent)]" : "bg-[var(--gray4)]"
              }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (checkValidations()) {
                setData(form);
                const timer = setInterval(() => {
                  changeTab("make_payment");
                  clearInterval(timer);
                }, 500);
              } else {
                setIsPopUp(true);
              }
            }}
          >
            {t("next")}
          </button>
        </div>
      </form>
      {isPopUp && (
        <Popup classes="relative">
          <PopupContent
            isShow={isPopUp}
            classes={
              "fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
            }
          >
            <div className="home-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
              <div className="absolute z-[-1] w-full h-full bg-[var(--black)] opacity-[50%]" />
              <div className="box-border px-[46px] pt-[36px] w-[311px] h-[270px] bg-[var(--white)] rounded-[4px]">
                <h1 className="font-[700] mb-[32px] flex justify-center text-[length:--title]">
                  {t("incomplete_information.title")}
                </h1>
                <p
                  className="flex flex-wrap text-center text-[var(--black)] text-[length:--body] font-[400] 
                                items-center pb-[30px] justify-center"
                >
                  {t("incomplete_information.content")}
                </p>
                <div className="flex w-full justify-center">
                  <button
                    className="flex box-border h-[48px] w-[163px] items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsPopUp(false);
                    }}
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </div>
          </PopupContent>
        </Popup>
      )}
      {isAdd && (
        <DropDownMenu classes="relative">
          <DropDownMenuContent
            isShow={isAdd}
            classes="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
          >
            <div className="quantity-picker-wrapper w-[375px] relative  h-full">
              <div
                className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
                onClick={() => setIsAdd(false)}
              />
              <div className="absolute rounded-t-[12px] p-[30px] pt-[24px] shadow-lg bottom-0 w-full h-[294px] bg-[var(--white)]">
                <AddPerson
                  onClick={(person) => {
                    addForm(person);
                    setPeople((prev) => {
                      return {
                        ...prev,
                        [person]: prev[person] + 1,
                      };
                    });
                    setIsAdd((prev) => !prev);
                  }}
                />
              </div>
            </div>
          </DropDownMenuContent>
        </DropDownMenu>
      )}
    </div>
  );
};

export default TravellerInfo;
