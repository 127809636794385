import React from "react";
import { formatDateHeader } from "../../../../utils/format";
import FlightCard from "../../../../components/flight-card/FlightCard";
import { useTranslation } from "react-i18next";

const FlightPaymentInfo = ({
  flight_type,
  flight,
  selectedDates,
  currency,
  quantity,
  // tripType,
}) => {
  const { t } = useTranslation();
  let exchangeRate = 3900;
  const headerText =
    flight_type === "departure"
      ? `${t("progress.departure_flight")}`
      : `${t("progress.return_flight")}`;
  return (
    <div className="w-full h-full relative flex flex-col">
      <div className="flex mb-3">
        <h4 className="text-[length:--title-s] font-[600]">{headerText}</h4>
        <div className="border-l-[2px] border-[var(--black)] mx-2" />
        <p className="text-[length:--title-s] font-[600]">
          {formatDateHeader(selectedDates?.[flight_type], true)}
        </p>
      </div>
      <div className="flex mb-3">
        <FlightCard data={flight} currency={currency} onClick={() => {}} />
      </div>
      <div className="flex flex-col px-4">
        <div className="flex w-full justify-between">
          <p className="text-[length:--body] text-[var(--gray7)]">
            {t("confirm_ticker.ticket_price")}
          </p>
          <p className="text-[length:--body] text-[var(--gray7)]">
            {flight?.discount_price !== null
              ? `${
                  currency.key !== "Ks" ? currency.key : ""
                }${flight?.discount_price[
                  currency?.value?.toLowerCase()
                ]?.toLocaleString()}${
                  currency.key === "Ks" ? currency?.key : ""
                }`
              : `${currency.key !== "Ks" ? currency.key : ""}${flight?.price[
                  currency?.value?.toLowerCase()
                ]?.toLocaleString()}${
                  currency.key === "Ks" ? currency?.key : ""
                }`}
          </p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-[length:--body] text-[var(--gray7)]">
            {t("confirm_ticker.quantity")}
          </p>
          <p className="text-[length:--body] text-[var(--gray7)]">
            {`${quantity} ${quantity > 1 ? "tickets" : "ticket"}`}{" "}
          </p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-[length:--body] text-[var(--gray7)]">
            {t("confirm_ticker.exchange_rate")}
          </p>
          <p className="text-[length:--body] text-[var(--gray7)]">
            {exchangeRate.toLocaleString()}
          </p>
        </div>
        <div className="flex w-full justify-between">
          <p className="text-[length:--body] text-[var(--gray7)]">
            {t("confirm_ticker.tax")}
          </p>
          <p className="text-[length:--body] text-[var(--gray7)]">
            {flight?.tax}
          </p>
        </div>
        <div className="border-[var(--gray4)] my-2 border-b-[1px] w-full" />
        <div className="flex w-full justify-between">
          <p className="text-[length:--body] text-[var(--gray7)]">
            {t("confirm_ticker.total_myanmar_ks")}
          </p>
          <p className="text-[length:--body] text-[var(--gray7)]">
            {flight?.total_price?.mmk?.toLocaleString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FlightPaymentInfo;
