import React from "react";

import loadIcon from "../../assets/lottie-json/ibZyBVZwM2.json";
import Lottie from "lottie-react";

const Loader = ({ classes, text }) => {
  return (
    <div
      className={`loader w-full flex flex-col items-center justify-end ${classes}`}
    >
      <div className="loader-image w-[187px] h-[141px] mb-[14px]">
        <Lottie animationData={loadIcon} loop={true} />
      </div>
      <p className="text-[length:--body-s] text-[var(--gray2)]">{text}</p>
    </div>
  );
};

export default Loader;
