import React from "react";
import { useNavigate } from "react-router-dom";

import Image from "../../assets/images/icon-link.svg";
import Header from "../../assets/images/icon-link-header.svg";

const Link = () => {
  const navigate = useNavigate();
  return (
    <div
      className="relative w-full h-full flex-col cursor-pointer"
      onClick={() => navigate("/")}
    >
      <img src={Header} alt="header" className="w-full" />
      <img
        src={Image}
        className="w-full h-full absolute top-0"
        alt="icon-link"
      />
    </div>
  );
};

export default Link;
