import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import InfoHeader from "../../components/info-header/InfoHeader";
import ProfileCard from "../../components/profile-card/ProfileCard";
import { TabContent, TabList, TabTrigger } from "../../components/tabs/Tabs";
import HistoryCard from "../../components/history-card/HistoryCard";
import Layout from "../../layouts/Layout";

//scss
import "./Profile.scss";

//utils
import { formatDateString } from "../../utils/format";

//const
import { history_detail_data, upcoming_history } from "../../data/data";
import PassengerCard from "../../components/pessenger-card/PassengerCard";
import FlightPaymentInfo from "../flightlist/components/flight-payment/FlightPaymentInfo";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";

const BENEFITS = ["benifit_1", "benifit_2", "benifit_3"];

const AGGREMENTS = ["agree_1"];

const TABS = ["upcoming", "previous_booking"];

const Profile = () => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.currency);
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.user);
  // eslint-disable-next-line
  const [account, setAccount] = useState(userProfile);
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [detailShow, setDetailShow] = useState(false);
  const [historyDetail, setHistoryDetail] = useState(null);

  return (
    <div className="profile flex w-full h-full bg-[var(--white1)] flex-col">
      <div className="py-6 px-5">
        <InfoHeader
          title={t("profile.my_profile")}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="w-full h-[172px] px-4 mb-[48px]">
        <ProfileCard
          account={account}
          onClick={() => navigate("/profile/edit")}
        />
      </div>
      {account === null ? (
        <div className="flex flex-col gap-6 px-8">
          <div className="flex flex-col">
            <h2 className="text-[length:--title-s] font-[600]">
              {t("profile.account_benefit")}
            </h2>
            <ul className="list-disc">
              {BENEFITS.map((item, index) => (
                <li
                  key={index}
                  className="flex gap-1 text-[length:--body-s] font-[400] leading-5"
                >
                  {t(`profile.${item}`)}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-1">
            <h2 className="text-[length:--title-s] font-[600]">
              {t(`agreement.agreement`)}
            </h2>
            <div className="flex flex-col gap-6">
              {AGGREMENTS.map((item, index) => (
                <p
                  key={index}
                  className="flex gap-1 text-[length:--body-s] font-[400] leading-5"
                >
                  {t(`agreement.${item}`)}
                </p>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="bookings flex flex-col w-full h-full relative">
          <h2 className="text-[length:--title-s] px-8 font-[700] mb-5 ">
            {t("profile.my_booking")}
          </h2>
          <TabList classes={"relative"}>
            <div className="px-8 flex gap-[9px]">
              {TABS.map((item, index) => (
                <div key={index} className="w-fit h-fit">
                  <TabTrigger
                    onClick={() => setActiveTab(item)}
                    classes={`px-[10px] py-[5px] flex justify-center items-center rounded-[3px] border-[var(--gray5)]
                  ${
                    activeTab === item
                      ? "bg-[var(--accent)] border-[0px] py-[6px]"
                      : "bg-[var(--white1)] border-[1px]"
                  }`}
                  >
                    <span
                      className={`text-[length:--body-s] ${
                        activeTab === item
                          ? "text-[var(--white)]"
                          : "text-[var(--gray5)]"
                      }`}
                    >
                      {t(`profile.${item}`)}
                    </span>
                  </TabTrigger>
                </div>
              ))}
            </div>
            <div className="tab-content-wrapper w-full py-4 px-8">
              <TabContent isShow={activeTab === TABS[0]}>
                {upcoming_history.map((trip, index) => (
                  <HistoryCard
                    key={index}
                    color={"var(--black)"}
                    data={{
                      from: trip?.trip?.from?.city,
                      to: trip?.trip?.to?.city,
                      tripType: trip?.tripType,
                      startDate: formatDateString(trip?.startDate, false),
                      endDate: formatDateString(trip?.endDate, false),
                      count: Object.values(trip?.persons).reduce(
                        (total, value) => total + value,
                        0
                      ),
                    }}
                    onClick={() => {
                      setDetailShow((prev) => !prev);
                      setHistoryDetail(trip);
                    }}
                  />
                ))}
              </TabContent>
            </div>
          </TabList>
        </div>
      )}
      {detailShow && (
        <div className="history-detail fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full">
          <Layout>
            <div className="relative mt-2">
              <HistoryCard
                onBack={() => {
                  setDetailShow(false);
                }}
                color={"var(--white)"}
                data={{
                  from: historyDetail?.trip?.from?.city,
                  to: historyDetail?.trip?.to?.city,
                  tripType: historyDetail?.tripType,
                  startDate: formatDateString(historyDetail?.startDate, false),
                  endDate: formatDateString(historyDetail?.endDate, false),
                  count: Object.values(historyDetail?.persons).reduce(
                    (total, value) => total + value,
                    0
                  ),
                }}
                onClick={() => {}}
              />
            </div>
            <div className="history-detail-body bg-[var(--white1)] flex flex-col w-full">
              <div className="w-full h-full pt-6 overflow-scroll  relative px-4 pb-12">
                <h4 className="text-[length:--body-s] text-[var(--gray7)] pb-3">
                  {t("confirm_ticker.passenger_info")}
                </h4>
                <div className="w-full flex flex-col mb-[56px]">
                  {history_detail_data?.persons?.map((item, index) => (
                    <PassengerCard
                      info={item}
                      key={index}
                      isNrc={
                        history_detail_data.isDomestic &&
                        history_detail_data.isResident
                      }
                      border={
                        index !== history_detail_data?.persons?.length - 1
                      }
                    />
                  ))}
                </div>
                <div className="w-full flex flex-col gap-[56px] mb-[105px]">
                  {Object.entries(history_detail_data.flights)?.map(
                    ([key, value]) => {
                      if (value !== null) {
                        return (
                          <FlightPaymentInfo
                            key={key}
                            flight_type={key}
                            flight={value}
                            selectedDates={history_detail_data.dateInfo}
                            currency={currency}
                            quantity={history_detail_data.persons?.length}
                          />
                        );
                      }
                      return null;
                    }
                  )}
                </div>
                <Footer>
                  <div className="flex flex-col justify-center items-center">
                    <p className="text-[length:--body-s] text-center font-[400]">
                      {t(`payment.paid_offline`)}
                    </p>
                  </div>
                </Footer>
              </div>
            </div>
          </Layout>
        </div>
      )}
    </div>
  );
};

export default Profile;
