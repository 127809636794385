import React from "react";
import { formatDateString } from "../../utils/format";
import { useTranslation } from "react-i18next";

const DateCard = ({ data, classses, isSelected, onClick, currency }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-full h-full ${classses} ${
        isSelected ? "text-[var(--accent)]" : "text-[var(--gray3)]"
      }`}
      onClick={() => {
        if (data?.isFlight) {
          onClick();
        }
        return;
      }}
    >
      <p className="text-[length:--body-s] font-[400]">
        {formatDateString(data?.date, false)}
      </p>
      {data?.isFlight ? (
        <p className="w-full flex font-[600] justify-center text-[length:--title-s]">
          {`${
            currency.key !== "Ks" ? currency.key : ""
          }${data?.cost.toLocaleString()} ${
            currency.key === "Ks" ? currency?.key : ""
          }`}
        </p>
      ) : (
        <p className="w-full font-[600] flex justify-center text-[length:--title-s] ">
          {t("progress.no_flight")}
        </p>
      )}
    </div>
  );
};

export default DateCard;
