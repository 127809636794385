import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { useNavigate } from "react-router-dom";

import HeaderLogo from "../../components/header_logo/HeaderLogo";
import {
  DropDownMenu,
  DropDownMenuContent,
  DropDownMenuItem,
  DropDownMenuTrigger,
} from "../../components/dropdown-menu/dropdown-menu";
import HomeInputBox from "./components/home-inputbox/HomeInputBox";
import { RadioButton } from "../../components/radio/radio-button/RadioButton";
import Footer from "../../components/footer/Footer";
import DatePicker from "../../components/datepicker/DatePicker";
import Loader from "../../components/Loader/Loader";
import Header from "../../components/header/Header";
import QuantityPicker from "./components/quantity-picker/QuantityPicker";
import { useDispatch, useSelector } from "react-redux";
import {
  Popup,
  PopupContent,
  PopupTrigger,
} from "../../components/popup/popup";
import { setlanguage } from "../../reducers/languageReducer";

//hooks
import useInfo from "../../hooks/useInfo";

//scss
import "./Home.scss";

//images and icons
import dropdown from "../../assets/icons/dropdown.svg";
import profile from "../../assets/images/profile.svg";
import people from "../../assets/icons/people.svg";
import polygonDropDown from "../../assets/icons/polygon-dropdown.svg";
import flightFrom from "../../assets/icons/flight-takeoff-line.svg";
import flightTo from "../../assets/icons/flight-land-line.svg";
import calendar from "../../assets/icons/calendar.svg";
import check from "../../assets/icons/check-fill.svg";

//const
import {
  home_dropdown,
  home_radio_buttons,
  quantity_picker_radio_buttons,
} from "../../data/const";
import SearchFlight from "./search-flight/SearchFlight";

//utils
import {
  formatDate,
  formatDateHeader,
  storeFormatDate,
} from "../../utils/format";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const flightInfo = useSelector((state) => state.flightInfo.flightInfo);
  const languageValue = useSelector((state) => state.language);
  const [language, setLanguage] = useState(languageValue);
  const [tripType, setTripType] = useState(
    flightInfo?.tripType || "round trip"
  );
  const [radioButtons, setRadioButtons] = useState(
    flightInfo?.tripType
      ? home_radio_buttons.map((item) => {
          if (item.key === flightInfo?.tripType) {
            return {
              ...item,
              isChecked: true,
            };
          }
          return { ...item, isChecked: false };
        })
      : home_radio_buttons
  );
  const [trip, setTrip] = useState(flightInfo?.trip);
  const [startDate, setStartDate] = useState(new Date(flightInfo?.startDate));
  const [endDate, setEndDate] = useState(
    flightInfo?.endDate ? new Date(flightInfo?.endDate) : null
  );
  const [isSearchFlight, setIsSearchFlight] = useState(false);
  const [flightType, setFlightType] = useState("from");
  const [citizenRadioButtons, setCitizenRadioButtons] = useState(
    quantity_picker_radio_buttons
  );
  const [persons, setPersons] = useState({
    adult: 1,
    child: 0,
    infant: 0,
  });
  const [isChoose, setIsChoose] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //translation
  const { t, i18n } = useTranslation();

  useInfo({
    tripType,
    trip,
    startDate: storeFormatDate(startDate),
    endDate: storeFormatDate(endDate),
    persons,
    citizens: citizenRadioButtons,
  });

  const handleRadioClick = (value) => {
    setStartDate(new Date());
    setEndDate(null);
    setRadioButtons((prev) => {
      return prev.map((item) => {
        if (item.name === value.name) {
          setTripType(item.key);
          return {
            ...item,
            isChecked: true,
          };
        }
        return {
          ...item,
          isChecked: false,
        };
      });
    });
  };

  const handleDateChange = (date) => {
    if (tripType === "round trip") {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const handleChange = (e) => {
    setTrip((prev) => {
      return {
        ...prev,
        [e.target.name]: {
          airport: e.target.value,
          city: e.target.city,
          country: e.target.country,
          airport_code: e.target.airport_code,
        },
      };
    });
  };

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const checkValidation = () => {
    if (tripType === "round trip" && (startDate === null || endDate === null)) {
      return false;
    } else if (tripType === "one way" && startDate === null) {
      return false;
    } else if (trip.from.airport.length === 0 || trip.to.airport.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSearch = (e) => {
    e.stopPropagation();
    if (!checkValidation()) {
      setIsPopup(true);
      return;
    }
    setIsLoading(true);
    const timer = setInterval(() => {
      setIsLoading(false);
      navigate("/flight-list");
      clearInterval(timer);
    }, 3000);
  };

  if (isLoading) {
    return (
      <div className="home-loader w-full h-full shadow-lg flex flex-col">
        <div className="pt-6">
          <Header
            data={{
              from: trip.from.city,
              to: trip.to.city,
              tripType,
              startDate: formatDateHeader(storeFormatDate(startDate)),
              endDate: formatDateHeader(storeFormatDate(endDate)),
              count: Object.values(persons).reduce(
                (total, value) => total + value,
                0
              ),
            }}
            onClick={() => setIsLoading(false)}
          />
        </div>
        <Loader
          classes={"absolute bottom-[104px]"}
          text={t("loading.get_airline")}
        />
      </div>
    );
  }

  return (
    <section id="home">
      <div className="flex p-6 justify-between items-center">
        <HeaderLogo />
        <div className="flex gap-6">
          <DropDownMenu classes="relative">
            <DropDownMenuTrigger
              classes="flex relative items-center gap-[5px]"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            >
              <p className="text-[16px] text-[var(--white)] font-[500]">
                {t(`dropdown-show.${language}`)}
              </p>
              <img src={dropdown} alt="drop-down" />
            </DropDownMenuTrigger>
            <DropDownMenuContent
              classes="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
              isShow={isOpen}
            >
              <div className="language-picker w-[375px] relative  h-full">
                <div
                  className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
                  onClick={() => setIsOpen(false)}
                />
                <div className="absolute px-[24px] pt-[30px] pb-[55px] rounded-t-[12px] shadow-lg bottom-0 w-full h-[294px] bg-[var(--white)]">
                  <h2 className="text-[length:--title-s] flex items-center justify-center font-[600] mb-[20px]">
                    {t(`dropdown-show.language`)}
                  </h2>
                  <div className="flex flex-col gap-3">
                    {home_dropdown.map((item) => (
                      <DropDownMenuItem
                        key={item.key}
                        classes={`relative hover:cursor-pointer text-[length:--title-s] box-border px-[24px] py-[14px] h-[52px]
                                rounded-[5px] flex items-center justify-between
                               ${
                                 item.value === language
                                   ? "border-[1px] bg-[#FF3D001A] border-[var(--accent)] text-[var(--accent)]"
                                   : "bg-[var(--white1)]"
                               }`}
                        onClick={() => {
                          setLanguage(item.value);
                          dispatch(setlanguage(item.value));
                          setIsOpen((prev) => !prev);
                          switchLanguage(item.name);
                        }}
                      >
                        <div className="flex gap-[16px] ">
                          <Flag code={item.key} className="h-[24px] w-[24px]" />
                          <p>{t(`home-dropdown.${item.value}`)}</p>
                        </div>
                        {item.value === language && (
                          <img src={check} alt="check" />
                        )}
                      </DropDownMenuItem>
                    ))}
                  </div>
                </div>
              </div>
            </DropDownMenuContent>
          </DropDownMenu>
          <div onClick={() => navigate("/profile")} className="cursor-pointer">
            <img src={profile} alt="profile" />
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <div className="scroll-container .no-scrollbar">
          <div className="relative w-full mb-6 h-auto flex items-center justify-center">
            <div
              className={`home-body shadow-lg ${
                isSearchFlight && "shadow-none"
              } `}
            />
            <div className="relative flex flex-col box-border p-3 w-[343px]  h-full ">
              <div className="flex justify-between items-center mb-3">
                <div className="radio-group flex gap-3">
                  {radioButtons.map((item) => (
                    <RadioButton
                      translation_key={"search_page"}
                      key={item.key}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRadioClick(item);
                      }}
                      item={item}
                      color={item.isChecked ? "#FF3D00" : "none"}
                    />
                  ))}
                </div>
                <DropDownMenu>
                  <DropDownMenuTrigger
                    onClick={() => setIsChoose((prev) => !prev)}
                    classes="bg-[var(--accent)] w-[83px] h-[33px] items-center
                flex rounded-[100px] p-[10px] justify-between"
                  >
                    <div className="flex items-center gap-1">
                      <img src={people} alt="people" />
                      <p className="text-[length:--title] text-[var(--white)] font-[600]">
                        {Object.values(persons).reduce(
                          (total, value) => total + value,
                          0
                        )}
                      </p>
                    </div>
                    <img src={polygonDropDown} alt="drop-down" />
                  </DropDownMenuTrigger>
                  <DropDownMenuContent
                    isShow={isChoose}
                    classes="fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
                  >
                    <div className="quantity-picker-wrapper w-[375px] relative  h-full">
                      <div
                        className="relative w-full h-full bg-[var(--black)] opacity-[50%]"
                        onClick={() => setIsChoose(false)}
                      />
                      <div className="absolute rounded-t-[12px] p-[30px] pt-[24px] shadow-lg bottom-0 w-full h-[432px] bg-[var(--white)]">
                        <QuantityPicker
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsChoose(false);
                          }}
                          setPersons={setPersons}
                          persons={persons}
                          radioButtons={citizenRadioButtons}
                          setRadioButtons={setCitizenRadioButtons}
                        />
                      </div>
                    </div>
                  </DropDownMenuContent>
                </DropDownMenu>
              </div>
              <HomeInputBox
                autoFocus={false}
                handleChange={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSearchFlight(true);
                  setFlightType("from");
                }}
                value={trip.from.airport}
                name="from"
                label={t(`search_page.from`)}
                flag={trip.from.flag}
                icon={flightFrom}
                classes={
                  "box-border w-[319px] bg-[var(--white)] h-[52px] p-3 mb-3 rounded-[5px]"
                }
              />
              <HomeInputBox
                autoFocus={false}
                value={trip.to.airport}
                handleChange={() => {}}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsSearchFlight(true);
                  setFlightType("to");
                }}
                name="to"
                label={t(`search_page.to`)}
                icon={flightTo}
                flag={trip.to.flag}
                classes={
                  "box-border w-[319px] bg-[var(--white)] h-[52px] p-3 mb-3 rounded-[5px]"
                }
              />
              <div className="flex flex-col mb-4">
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  selectRange={tripType === "round trip" ? true : false}
                  onChange={handleDateChange}
                />
              </div>

              {tripType !== "round trip" && endDate === null ? (
                <p className="flex text-[length:--title-s] font-[600] mb-4 ml-7">
                  {formatDate(startDate)}
                </p>
              ) : endDate === null ? (
                <p className="flex justify-center mb-4 text-[length:--title-s] text-[var(--accent)] font-[600]">
                  {t(`search_page.tap_to_date`)}
                </p>
              ) : (
                endDate !== null && (
                  <p className="flex ml-7 text-[length:--title-s] font-[600] mb-4">{`${formatDate(
                    startDate
                  )} - ${formatDate(endDate)}`}</p>
                )
              )}

              <Popup classes={"relative"}>
                <PopupTrigger
                  classes={`flex box-border w-[319px] h-[48px] items-center justify-center
              text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]
            ${checkValidation() ? "bg-[var(--accent)]" : "bg-[var(--gray4)]"}`}
                  onClick={handleSearch}
                >
                  {t("search")}
                </PopupTrigger>
                <PopupContent
                  isShow={isPopup}
                  classes={
                    "fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
                  }
                >
                  <div className="home-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
                    <div className="absolute z-[-1] w-full h-full bg-[var(--black)] opacity-[50%]" />
                    <div className="box-border px-[46px] pt-[36px] w-[311px] h-[270px] bg-[var(--white)] rounded-[4px]">
                      <h1 className="font-[600] mb-[32px] flex justify-center text-[length:--title]">
                        {t("info_need.title")}
                      </h1>
                      <div className="flex w-full items-center justify-center gap-4 mb-[16px]">
                        <img src={flightFrom} alt="flight-from" />
                        <img src={calendar} alt="calendar" />
                        <img src={flightTo} alt="flight-to" />
                      </div>
                      <p
                        className="flex flex-wrap text-center text-[var(--black)] text-[length:--body] font-[400] 
                                items-center pb-[30px] justify-center"
                      >
                        {t("info_need.content")}
                      </p>
                      <div className="flex w-full justify-center">
                        <button
                          className="flex box-border h-[48px] w-[163px] items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsPopup(false);
                          }}
                        >
                          {t("ok")}
                        </button>
                      </div>
                    </div>
                  </div>
                </PopupContent>
              </Popup>
            </div>
          </div>
          <div className="pb-4">
            <Footer>
              <p className="text-[length:--body-s] mb-4 font-[400]">
                {t(`search_page.contact_us`)}
              </p>
            </Footer>
          </div>
        </div>
      </div>
      {/* flight search part */}
      {isSearchFlight && (
        <div className="fixed top-0 z-[99] left-0 w-full h-full flex justify-center">
          <div className="search-flight-wrapper w-[375px] relative h-full">
            <SearchFlight
              flightType={flightType}
              handleBack={() => setIsSearchFlight(false)}
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Home;
