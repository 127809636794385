import React from "react";

import BackgroundImage from "../assets/images/bg-image.svg";

import "./Layout.scss";

const Layout = ({ children }) => {
  return (
    <div className="w-[375px] layout h-full relative">
      <div className="absolute w-full top-0 left-0 z-[-1]">
        <img src={BackgroundImage} className="bg-img w-full" alt="background" />
        <div className="gradient-overlay"></div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
