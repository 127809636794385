import React from "react";

import backArrow from "../../assets/icons/back-arrow.svg";

const InfoHeader = ({ title, onClick, styles }) => {
  return (
    <div className={`flex gap-4`}>
      <img
        src={backArrow}
        alt="back-arrow"
        onClick={onClick}
        className="cursor-pointer"
      />
      <h2 className={`text-[length:--title] flex-1 font-[600] ${styles}`}>
        {title}
      </h2>
    </div>
  );
};

export default InfoHeader;
