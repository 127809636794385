export const nrcData = [
    {
        id: "1",
        name_en: "AhGaYa",
        name_mm: "အဂယ",
        name_long: "(အဂယ) အင်ဂျန်းယန်",
        nrc_code: "1"
    },
    {
        id: "2",
        name_en: "BaMaNa",
        name_mm: "ဗမန",
        name_long: "(ဗမန) ဗန်းမော်",
        nrc_code: "1"
    },
    {
        id: "3",
        name_en: "KhaPhaNa",
        name_mm: "ခဖန",
        name_long: "(ခဖန) ချီဖွေ",
        nrc_code: "1"
    },
    {
        id: "4",
        name_en: "DaPhaYa",
        name_mm: "ဒဖယ",
        name_long: "(ဒဖယ) ဒေါ့ဖုန်းယန်",
        nrc_code: "1"
    },
    {
        id: "5",
        name_en: "HaPaNa",
        name_mm: "ဟပန",
        name_long: "(ဟပန) ဟိုပင်",
        nrc_code: "1"
    },
    {
        id: "6",
        name_en: "KaMaNa",
        name_mm: "ကမန",
        name_long: "(ကမန) ကာမီ",
        nrc_code: "1"
    },    
    {
        id: "7",
        name_en: "KhaLaPha",
        name_mm: "ခလဖ",
        name_long: "(ခလဖ) ခေါင်လန်ဖူး",
        nrc_code: "1"
    },    
    {
        id: "8",
        name_en: "LaGaNa",
        name_mm: "လဂန",
        name_long: "(လဂန) လွယ်ဂျယ်",
        nrc_code: "1"
    },
    {
        id: "9",
        name_en: "MaKhaBa",
        name_mm: "မခဘ",
        name_long: "(မခဘ) မချမ်းဘော",
        nrc_code: "1"
    },
    {
        id: "10",
        name_en: "MaSaNa",
        name_mm: "မစန",
        name_long: "(မစန) မံစီ",
        nrc_code: "1"
    },
    {
        id: "11",
        name_en: "MaNyaNa",
        name_mm: "မညန",
        name_long: "(မညန) မိုးညင်း",
        nrc_code: "1"
    },
    {
        id: "12",
        name_en: "MaKaTa",
        name_mm: "မကတ",
        name_long: "(မကတ) မိုးကောင်း",
        nrc_code: "1"
    },
    {
        id: "13",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မိုးမောက်",
        nrc_code: "1"
    },
    {
        id: "14",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မြစ်ကြီးနား",
        nrc_code: "1"
    },
    {
        id: "15",
        name_en: "NaMaNa",
        name_mm: "နမန",
        name_long: "(နမန) နောင်မွန်း",
        nrc_code: "1"
    },
    {
        id: "16",
        name_en: "PhaKaNa",
        name_mm: "ဖကန",
        name_long: "(ဖကန) ဖားကန့်",
        nrc_code: "1"
    },
    {
        id: "17",
        name_en: "PaTaAh",
        name_mm: "ပတအ",
        name_long: "(ပတအ) ပူတာအို",
        nrc_code: "1"
    },
    {
        id: "18",
        name_en: "YaKaNa",
        name_mm: "ရကန",
        name_long: "(ရကန) ရွှေကူ",
        nrc_code: "1"
    },
    {
        id: "19",
        name_en: "SaBaNa",
        name_mm: "ဆဘန",
        name_long: "(ဆဘန) ဆင်ဘို",
        nrc_code: "1"
    },
    {
        id: "20",
        name_en: "SaLaNa",
        name_mm: "ဆလန",
        name_long: "(ဆလန) ဆော့လော",
        nrc_code: "1"
    },
    {
        id: "21",
        name_en: "SaPaBa",
        name_mm: "ဆပဘ",
        name_long: "(ဆပဘ) ဆွမ်ပရာဘွမ်",
        nrc_code: "1"
    },
    {
        id: "22",
        name_en: "TaNaNa",
        name_mm: "တနန",
        name_long: "(တနန) တနိုင်း",
        nrc_code: "1"
    },
    {
        id: "23",
        name_en: "WaMaNa",
        name_mm: "ဝမန",
        name_long: "(ဝမန) ဝင်းမော်",
        nrc_code: "1"
    },
    {
        id: "24",
        name_en: "BaLaKha",
        name_mm: "ဘလခ",
        name_long: "(ဘလခ) ဘော်လခဲ",
        nrc_code: "2"
    },
    {
        id: "25",
        name_en: "DaMaSa",
        name_mm: "ဒမဆ",
        name_long: "(ဒမဆ) ဒီမောဆိုး",
        nrc_code: "2"
    },
    {
        id: "26",
        name_en: "LaKaNa",
        name_mm: "လကန",
        name_long: "(လကန) လွိုင်ကော်",
        nrc_code: "2"
    },
    {
        id: "27",
        name_en: "MaSaNa",
        name_mm: "မဆန",
        name_long: "(မဆန) မယ်ဆည်နန်",
        nrc_code: "2"
    },
    {
        id: "28",
        name_en: "PhaSaNa",
        name_mm: "ဖဆန",
        name_long: "(ဖဆန) ဖားဆောင်း",
        nrc_code: "2"
    },
    {
        id: "29",
        name_en: "PhaYaSa",
        name_mm: "ဖရဆ",
        name_long: "(ဖရဆ) ဖရူးဆိုး",
        nrc_code: "2"
    },
    {
        id: "30",
        name_en: "YaTaNa",
        name_mm: "ရတန",
        name_long: "(ရတန) ရှားတော်",
        nrc_code: "2"
    },
    {
        id: "31",
        name_en: "LaBaNa",
        name_mm: "လဘန",
        name_long: "(လဘန) လှိုင်းဘွဲ့",
        nrc_code: "3"
    },
    {
        id: "32",
        name_en: "KaKaYa",
        name_mm: "ကကရ",
        name_long: "(ကကရ) ကော့ကရိတ်",
        nrc_code: "3"
    },
    {
        id: "33",
        name_en: "KaSaKa",
        name_mm: "ကဆက",
        name_long: "(ကဆက) ကြာအင်းဆိပ်ကြီး",
        nrc_code: "3"
    },
    {
        id: "34",
        name_en: "KaDaNa",
        name_mm: "ကဒန",
        name_long: "(ကဒန) ကျုံဒိုး",
        nrc_code: "3"
    },
    {
        id: "35",
        name_en: "MaWaTa",
        name_mm: "မဝတ",
        name_long: "(မဝတ) မြဝတီ",
        nrc_code: "3"
    },    
    {
        id: "36",
        name_en: "PhaAhNa",
        name_mm: "ဖအန",
        name_long: "(ဖအန) ဖားအံ",
        nrc_code: "3"
    },
    {
        id: "37",
        name_en: "BaAhNa",
        name_mm: "ဘအန",
        name_long: "(ဘအန) ဘားအံ",
        nrc_code: "3"
    },
    {
        id: "38",
        name_en: "PhaPaNa",
        name_mm: "ဖပန",
        name_long: "(ဖပန) ဖျာပွန်",
        nrc_code: "3"
    },
    {
        id: "39",
        name_en: "ThaTaNa",
        name_mm: "သတန",
        name_long: "(သတန) သံတောင်",
        nrc_code: "3"
    },
    {
        id: "40",
        name_en: "HaKhaNa",
        name_mm: "ဟခန",
        name_long: "(ဟခန) ဟားခါး",
        nrc_code: "4"
    },
    {
        id: "41",
        name_en: "HtaTaLa",
        name_mm: "ထတလ",
        name_long: "(ထတလ) ထန်တလန်",
        nrc_code: "4"
    },
    {
        id: "42",
        name_en: "KaPaLa",
        name_mm: "ကပလ",
        name_long: "(ကပလ) ကန်ပက်လက်",
        nrc_code: "4"
    },
    {
        id: "43",
        name_en: "MaTaPa",
        name_mm: "မတပ",
        name_long: "(မတပ) မတူပီ",
        nrc_code: "4"
    },
    {
        id: "44",
        name_en: "MaTaNa",
        name_mm: "မတန",
        name_long: "(မတန) မင်းတပ်",
        nrc_code: "4"
    },
    {
        id: "45",
        name_en: "PhaLaNa",
        name_mm: "ဖလန",
        name_long: "(ဖလန) ဖလမ်း",
        nrc_code: "4"
    },
    {
        id: "46",
        name_en: "PaLaWa",
        name_mm: "ပလဝ",
        name_long: "(ပလဝ) ပလက်ဝ",
        nrc_code: "4"
    },
    {
        id: "47",
        name_en: "TaTaNa",
        name_mm: "တတန",
        name_long: "(တတန) တီးတိန်",
        nrc_code: "4"
    },
    {
        id: "48",
        name_en: "TaZaNa",
        name_mm: "တဇန",
        name_long: "(တဇန) တွန်းဇံ",
        nrc_code: "4"
    },
    {
        id: "49",
        name_en: "AhYaTa",
        name_mm: "အရတ",
        name_long: "(အရတ) အရာတော်",
        nrc_code: "5"
    },
    {
        id: "50",
        name_en: "BaMaNa",
        name_mm: "ဗမန",
        name_long: "(ဗမန) ဗန်းမောက်",
        nrc_code: "5"
    },
    {
        id: "51",
        name_en: "BaTaLa",
        name_mm: "ဘတလ",
        name_long: "(ဘတလ) ဘုတလင်",
        nrc_code: "5"
    },
    {
        id: "52",
        name_en: "KhaOuNa",
        name_mm: "ခဥန",
        name_long: "(ခဥန) ချောင်းဦး",
        nrc_code: "5"
    },
    {
        id: "53",
        name_en: "DaPaYa",
        name_mm: "ဒပယ",
        name_long: "(ဒပယ) ဒီပဲယင်း",
        nrc_code: "5"
    },
    {
        id: "54",
        name_en: "HaMaLa",
        name_mm: "ဟမလ",
        name_long: "(ဟမလ) ဟုမ္မလင်း",
        nrc_code: "5"
    },
    {
        id: "55",
        name_en: "HtaKhaNa",
        name_mm: "ထခန",
        name_long: "(ထခန) ထီးချိုင့်",
        nrc_code: "5"
    },
    {
        id: "56",
        name_en: "AhTaNa",
        name_mm: "အတန",
        name_long: "(အတန) အင်းတော်",
        nrc_code: "5"
    },
    {
        id: "57",
        name_en: "KaNaNa",
        name_mm: "ကနန",
        name_long: "(ကနန) ကနီ",
        nrc_code: "5"
    },
    {
        id: "58",
        name_en: "KaThaNa",
        name_mm: "ကသန",
        name_long: "(ကသန) ကသာ",
        nrc_code: "5"
    },
    {
        id: "59",
        name_en: "KaLaHta",
        name_mm: "ကလထ",
        name_long: "(ကလထ) ကလေး",
        nrc_code: "5"
    },
    {
        id: "60",
        name_en: "KaLaWa",
        name_mm: "ကလဝ",
        name_long: "(ကလဝ) ကလေးဝ",
        nrc_code: "5"
    },    
    {
        id: "61",
        name_en: "KaBaLa",
        name_mm: "ကဘန",
        name_long: "(ကဘန) ကန့်ဘလူ",
        nrc_code: "5"
    },
    {
        id: "62",
        name_en: "KaLaTa",
        name_mm: "ကလတ",
        name_long: "(ကလတ) ကောလင်း",
        nrc_code: "5"
    },
    {
        id: "63",
        name_en: "KhaTaNa",
        name_mm: "ခတန",
        name_long: "(ခတန) ခန္တီး",
        nrc_code: "5"
    },
    {
        id: "64",
        name_en: "KhaOuTa",
        name_mm: "ခဥတ",
        name_long: "(ခဥတ) ခင်ဦး",
        nrc_code: "5"
    },
    {
        id: "65",
        name_en: "KaLaNa",
        name_mm: "ကလန",
        name_long: "(ကလန) ကျွန်းလှ",
        nrc_code: "5"
    },
    {
        id: "66",
        name_en: "MaLaNa",
        name_mm: "မလန",
        name_long: "(မလန) မော်လိုက်",
        nrc_code: "5"
    },
    {
        id: "67",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မင်းကင်း",
        nrc_code: "5"
    },
    {
        id: "68",
        name_en: "MaYaNa",
        name_mm: "မရန",
        name_long: "(မရန) မုံရွာ",
        nrc_code: "5"
    },
    {
        id: "69",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မြောင်",
        nrc_code: "5"
    },
    {
        id: "70",
        name_en: "NaYaNa",
        name_mm: "နယန",
        name_long: "(နယန) နန်းယွန်း",
        nrc_code: "5"
    },
    {
        id: "71",
        name_en: "NgaZaNa",
        name_mm: "ငဇန",
        name_long: "(ငဇန) ငါးန်ဇွန်",
        nrc_code: "5"
    },
    {
        id: "72",
        name_en: "PaLaNa",
        name_mm: "ပလန",
        name_long: "(ပလန) ပုလဲ",
        nrc_code: "5"
    },
    {
        id: "73",
        name_en: "PhaPaNa",
        name_mm: "ဖပန",
        name_long: "(ဖပန) ဖောင်ပျင်း",
        nrc_code: "5"
    },
    {
        id: "74",
        name_en: "PaLanBa",
        name_mm: "ပလဘ",
        name_long: "(ပလဘ) ပင်လယ်ဘူး",
        nrc_code: "5"
    },
    {
        id: "75",
        name_en: "SaKaNa",
        name_mm: "စကန",
        name_long: "(စကန) စစ်ကိုင်း",
        nrc_code: "5"
    },
    {
        id: "76",
        name_en: "SaLaKa",
        name_mm: "ဆလက",
        name_long: "(ဆလက) ဆားလင်းကြီး",
        nrc_code: "5"
    },
    {
        id: "77",
        name_en: "YaBaNa",
        name_mm: "ရဘန",
        name_long: "(ရဘန) ရွှေဘို"
    },
    {
        id: "78",
        name_en: "TaMaNa",
        name_mm: "တမန",
        name_long: "(တမန) တမူး"
    },
    {
        id: "79",
        name_en: "TaSaNa",
        name_mm: "တဆန",
        name_long: "(တဆန) တန့်ဆည်"
    },
    {
        id: "80",
        name_en: "WaLaNa",
        name_mm: "ဝလန",
        name_long: "(ဝလန) ဝက်လက်"
    },
    {
        id: "81",
        name_en: "WaThaNa",
        name_mm: "ဝသန",
        name_long: "(ဝသန) ဝမ်းသို"
    },    
    {
        id: "82",
        name_en: "YaOuNa",
        name_mm: "ရဥန",
        name_long: "(ရဥန) ရေဦး",
        nrc_code: "5"
    },
    {
        id: "83",
        name_en: "YaMaPa",
        name_mm: "ယမပ",
        name_long: "(ယမပ) ယင်းမာပင်",
        nrc_code: "5"
    },
    {
        id: "84",
        name_en: "YaThaKa",
        name_mm: "ရသက",
        name_long: "(ရသက) ရွာသစ်ကြီး",
        nrc_code: "5"
    },
    {
        id: "85",
        name_en: "BaPaNa",
        name_mm: "ဘပန",
        name_long: "(ဘပန) ဘုတ်ပြင်း",
        nrc_code: "6"
    },
    {
        id: "86",
        name_en: "HtaWaNa",
        name_mm: "ထဝန",
        name_long: "(ထဝန) ထားဝယ်",
        nrc_code: "6"
    },
    {
        id: "87",
        name_en: "KaThaNa",
        name_mm: "ကသန",
        name_long: "(ကသန) ကော့သောင်း",
        nrc_code: "6"
    },
    {
        id: "88",
        name_en: "KaSaNa",
        name_mm: "ကစန",
        name_long: "(ကစန) ကျွန်းစု",
        nrc_code: "6"
    },
    {
        id: "89",
        name_en: "LaLaNa",
        name_mm: "လလန",
        name_long: "(လလန) လောင်းလုံ",
        nrc_code: "6"
    },
    {
        id: "90",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မြိတ်",
        nrc_code: "6"
    },
    {
        id: "91",
        name_en: "MaAhYa",
        name_mm: "မအရ",
        name_long: "(မအရ) မြိတ်အရှေ့",
        nrc_code: "6"
    },
    {
        id: "92",
        name_en: "NgaYaKa",
        name_mm: "ငရက",
        name_long: "(ငရက) ငရုတ်ကောင်း",
        nrc_code: "6"
    },
    {
        id: "93",
        name_en: "PaLaNa",
        name_mm: "ပလန",
        name_long: "(ပလန) ပုလော",
        nrc_code: "6"
    },
    {
        id: "94",
        name_en: "TaNaTha",
        name_mm: "တနသ",
        name_long: "(တနသ) တနသာၤရီ",
        nrc_code: "6"
    },
    {
        id: "95",
        name_en: "TaThaYa",
        name_mm: "တသရ",
        name_long: "(တသရ) တနသာၤရီ",
        nrc_code: "6"
    },
    {
        id: "96",
        name_en: "ThaYaKha",
        name_mm: "သရခ",
        name_long: "(သရခ) သရက်ချောင်း",
        nrc_code: "6"
    },
    {
        id: "97",
        name_en: "YaPhaNa",
        name_mm: "ရဖန",
        name_long: "(ရဖန) ရေဖြူ",
        nrc_code: "6"
    },
    {
        id: "98",
        name_en: "AhPhaNa",
        name_mm: "အဖန",
        name_long: "(အဖန) အုတ်ဖို",
        nrc_code: "7"
    },
    {
        id: "99",
        name_en: "AhPhaNa",
        name_mm: "အဖန",
        name_long: "(အဖန) အုတ်ဖြတ်",
        nrc_code: "7"
    },
    {
        id: "100",
        name_en: "AhTaNa",
        name_mm: "အတန",
        name_long: "(အတန) အုတ်တွင်း",
        nrc_code: "7"
    },    
    {
        id: "101",
        name_en: "DaOuNa",
        name_mm: "ဒဥန",
        name_long: "(ဒဥန) ဒိုက်ဦး",
        nrc_code: "7"
    },
    {
        id: "102",
        name_en: "HtaTaPa",
        name_mm: "ထတပ",
        name_long: "(ထတပ) ထန်းတပင်",
        nrc_code: "7"
    },
    {
        id: "103",
        name_en: "KaTaTa",
        name_mm: "ကတတ",
        name_long: "(ကတတ) ကေတုမတီ",
        nrc_code: "7"
    },
    {
        id: "104",
        name_en: "KaPaKa",
        name_mm: "ကပက",
        name_long: "(ကပက) ကြို့ပင်ကောက်",
        nrc_code: "7"
    },
    {
        id: "105",
        name_en: "KaKaNa",
        name_mm: "ကကန",
        name_long: "(ကကန) ကျောက်ကြီး",
        nrc_code: "7"
    },
    {
        id: "106",
        name_en: "KaTaKha",
        name_mm: "ကတခ",
        name_long: "(ကတခ) ကျောက်တံခါး",
        nrc_code: "7"
    },
    {
        id: "107",
        name_en: "KaKaNa",
        name_mm: "ကကန",
        name_long: "(ကကန) ကျောက်ကုန်း",
        nrc_code: "7"
    },
    {
        id: "108",
        name_en: "MaDaNa",
        name_mm: "မဒန",
        name_long: "(မဒန) မဒေါက်",
        nrc_code: "7"
    },
    {
        id: "109",
        name_en: "MaLaNa",
        name_mm: "မလန",
        name_long: "(မလန) မင်းလှ",
        nrc_code: "7"
    },
    {
        id: "110",
        name_en: "MaNyaNa",
        name_mm: "မညန",
        name_long: "(မညန) မိုးညို",
        nrc_code: "7"
    },
    {
        id: "111",
        name_en: "NaTaLa",
        name_mm: "နတလ",
        name_long: "(နတလ) နတ်တလင်း",
        nrc_code: "7"
    },
    {
        id: "112",
        name_en: "NyaLaPa",
        name_mm: "ညလပ",
        name_long: "(ညလပ) ညောင်လေးပင်",
        nrc_code: "7"
    },
    {
        id: "113",
        name_en: "PaNaKa",
        name_mm: "ပနက",
        name_long: "(ပနက) ပဲနွယ်ကုန်း",
        nrc_code: "7"
    },
    {
        id: "114",
        name_en: "PaKhaNa",
        name_mm: "ပခန",
        name_long: "(ပခန) ပဲခူး",
        nrc_code: "7"
    },
    {
        id: "115",
        name_en: "PaTaNa",
        name_mm: "ပတန",
        name_long: "(ပတန) ပန်တောင်း",
        nrc_code: "7"
    },
    {
        id: "116",
        name_en: "PaKhaTa",
        name_mm: "ပခန",
        name_long: "(ပခန) ပေါက်ခေါင်း",
        nrc_code: "7"
    },
    {
        id: "117",
        name_en: "PaTaTa",
        name_mm: "ပတတ",
        name_long: "(ပတတ) ပေါင်းတည်",
        nrc_code: "7"
    },
    {
        id: "118",
        name_en: "PhaMaNa",
        name_mm: "ဖမန",
        name_long: "(ဖမန) ဖြူး",
        nrc_code: "7"
    },    
    {
        id: "119",
        name_en: "PaMaNa",
        name_mm: "ပမန",
        name_long: "(ပမန) ပြည်",
        nrc_code: "7"
    },
    {
        id: "120",
        name_en: "PaTaSa",
        name_mm: "ပတစ",
        name_long: "(ပတစ) ပြွန်တဆာ",
        nrc_code: "7"
    },
    {
        id: "121",
        name_en: "YaKaNa",
        name_mm: "ရကန",
        name_long: "(ရကန) ရွှေကျင်",
        nrc_code: "7"
    },
    {
        id: "122",
        name_en: "YaTaNa",
        name_mm: "ရတန",
        name_long: "(ရတန) ရွှေတောင်",
        nrc_code: "7"
    },
    {
        id: "123",
        name_en: "TaNgaNa",
        name_mm: "တငန",
        name_long: "(တငန) တောင်ငူ",
        nrc_code: "7"
    },
    {
        id: "124",
        name_en: "ThaNaPa",
        name_mm: "သနပ",
        name_long: "(သနပ) သနပ်ပင်",
        nrc_code: "7"
    },
    {
        id: "125",
        name_en: "ThaKaNa",
        name_mm: "သကန",
        name_long: "(သကန) သဲကုန်း",
        nrc_code: "7"
    },
    {
        id: "126",
        name_en: "ThaWaTa",
        name_mm: "သဝတ",
        name_long: "(သဝတ) သာယာဝတီ",
        nrc_code: "7"
    },
    {
        id: "127",
        name_en: "ThaSaNa",
        name_mm: "သဆန",
        name_long: "(သဆန) သုံးဆယ်",
        nrc_code: "7"
    },
    {
        id: "128",
        name_en: "WaMaNa",
        name_mm: "ဝမန",
        name_long: "(ဝမန) ဝေါ",
        nrc_code: "7"
    },
    {
        id: "129",
        name_en: "YaTaYa",
        name_mm: "ရတရ",
        name_long: "(ရတရ) ရေတာရှည်",
        nrc_code: "7"
    },
    {
        id: "130",
        name_en: "ZaKaNa",
        name_mm: "ဇကန",
        name_long: "(ဇကန) ဇီးကုန်း",
        nrc_code: "7"
    },    
    {
        id: "131",
        name_en: "AhLaNa",
        name_mm: "အလန",
        name_long: "(အလန) အောင်လံ",
        nrc_code: "8"
    },
    {
        id: "132",
        name_en: "KhaMaNa",
        name_mm: "ခမန",
        name_long: "(ခမန) ချောက်",
        nrc_code: "8"
    },
    {
        id: "133",
        name_en: "GaGaNa",
        name_mm: "ဂဂန",
        name_long: "(ဂဂန) ဂန့်ဂေါ",
        nrc_code: "8"
    },
    {
        id: "134",
        name_en: "SaPhaNa",
        name_mm: "ဆဖန",
        name_long: "(ဆဖန) ဆိပ်ဖြူ",
        nrc_code: "8"
    },
    {
        id: "135",
        name_en: "SaPaWa",
        name_mm: "ဆပဝ",
        name_long: "(ဆပဝ) ဆင်ပေါင်ဝဲ",
        nrc_code: "8"
    },
    {
        id: "136",
        name_en: "HtaLaNa",
        name_mm: "ထလန",
        name_long: "(ထလန) ထီးလင်း",
        nrc_code: "8"
    },
    {
        id: "137",
        name_en: "KaMaNa",
        name_mm: "ကမန",
        name_long: "(ကမန) ကံမ",
        nrc_code: "8"
    },
    {
        id: "138",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မကွေး",
        nrc_code: "8"
    },
    {
        id: "139",
        name_en: "MaBaNa",
        name_mm: "မဘန",
        name_long: "(မဘန) မင်းဘူး",
        nrc_code: "8"
    },
    {
        id: "140",
        name_en: "MaLaNa",
        name_mm: "မလန",
        name_long: "(မလန) မင်းလှ",
        nrc_code: "8"
    },
    {
        id: "141",
        name_en: "MaTaNa",
        name_mm: "မတန",
        name_long: "(မတန) မင်းတုန်း",
        nrc_code: "8"
    },
    {
        id: "142",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မြိုင်",
        nrc_code: "8"
    },
    {
        id: "143",
        name_en: "MaHtaNa",
        name_mm: "မထန",
        name_long: "(မထန) မြေထဲ",
        nrc_code: "8"
    },
    {
        id: "144",
        name_en: "MaThaNa",
        name_mm: "မသန",
        name_long: "(မသန) မြို့သစ်",
        nrc_code: "8"
    },
    {
        id: "145",
        name_en: "NaMaNa",
        name_mm: "နမန",
        name_long: "(နမန) နတ်မောက်",
        nrc_code: "8"
    },
    {
        id: "146",
        name_en: "NgaPhaNa",
        name_mm: "ငဖန",
        name_long: "(ငဖန) ငါးဖယ်",
        nrc_code: "8"
    },
    {
        id: "147",
        name_en: "PaKhaKa",
        name_mm: "ပခက",
        name_long: "(ပခက) ပခုက္ကူ",
        nrc_code: "8"
    },
    {
        id: "148",
        name_en: "PaMaNa",
        name_mm: "ပမန",
        name_long: "(ပမန) ပေါက်",
        nrc_code: "8"
    },
    {
        id: "149",
        name_en: "PaPhaNa",
        name_mm: "ပဖန",
        name_long: "(ပဖန) ပွင့်ဖြူ",
        nrc_code: "8"
    },
    {
        id: "150",
        name_en: "SaLaNa",
        name_mm: "စလန",
        name_long: "(စလန) စလင်း",
        nrc_code: "8"
    },    
    {
        id: "151",
        name_en: "SaTaYa",
        name_mm: "စတရ",
        name_long: "(စတရ) စေတုတ္တရာ",
        nrc_code: "8"
    },
    {
        id: "152",
        name_en: "SaKaNa",
        name_mm: "စကန",
        name_long: "(စကန) စကု",
        nrc_code: "8"
    },
    {
        id: "153",
        name_en: "TaTaKa",
        name_mm: "တတက",
        name_long: "(တတက) တောင်တွင်းကြီး",
        nrc_code: "8"
    },
    {
        id: "154",
        name_en: "ThaYaNa",
        name_mm: "သရန",
        name_long: "(သရန) သရက်",
        nrc_code: "8"
    },
    {
        id: "155",
        name_en: "SaMaNa",
        name_mm: "ဆမန",
        name_long: "(ဆမန) ဆော",
        nrc_code: "8"
    },
    {
        id: "156",
        name_en: "YaNaKha",
        name_mm: "ရနခ",
        name_long: "(ရနခ) ရေနံချောင်း",
        nrc_code: "8"
    },
    {
        id: "157",
        name_en: "YaSaKa",
        name_mm: "ရစက",
        name_long: "(ရစက) ရေစကြို",
        nrc_code: "8"
    },
    {
        id: "158",
        name_en: "DaKhaTha",
        name_mm: "ဒခသ",
        name_long: "(ဒခသ) ဒက္ခိဏသီရိ",
        nrc_code: "9"
    },
    {
        id: "159",
        name_en: "LaWaNa",
        name_mm: "လဝန",
        name_long: "(လဝန) လယ်ဝေး",
        nrc_code: "9"
    },
    {
        id: "160",
        name_en: "OuTaTha",
        name_mm: "ဥတသ",
        name_long: "(ဥတသ) ဥတ္တရသီရိ",
        nrc_code: "9"
    },
    {
        id: "161",
        name_en: "PaBaTha",
        name_mm: "ပဗသ",
        name_long: "(ပဗသ) ပုဗ္ဗသီရိ",
        nrc_code: "9"
    },
    {
        id: "162",
        name_en: "PaMaNa",
        name_mm: "ပမန",
        name_long: "(ပမန) ပျဉ်းမနား",
        nrc_code: "9"
    },
    {
        id: "163",
        name_en: "TaKaNa",
        name_mm: "တကန",
        name_long: "(တကန) တပ်ကုန်း",
        nrc_code: "9"
    },
    {
        id: "164",
        name_en: "ZaBaTha",
        name_mm: "ဇဗသ",
        name_long: "(ဇဗသ) ဇမ္ဗူသီရိ",
        nrc_code: "9"
    },
    {
        id: "165",
        name_en: "ZaYaTha",
        name_mm: "ဇယသ",
        name_long: "(ဇယသ) ဇေယျာသီရိ",
        nrc_code: "9"
    },
    {
        id: "166",
        name_en: "AhMaYa",
        name_mm: "အမရ",
        name_long: "(အမရ) အမရပူရ",
        nrc_code: "9"
    },
    {
        id: "167",
        name_en: "AhMaZa",
        name_mm: "အမဇ",
        name_long: "(အမဇ) အောင်မြေသာဇံ",
        nrc_code: "9"
    },
    {
        id: "168",
        name_en: "KhaAhZa",
        name_mm: "ခအစ",
        name_long: "(ခအစ) ချမ်းအေးသာစည်",
        nrc_code: "9"
    },
    {
        id: "169",
        name_en: "KhaMaSa",
        name_mm: "ခမစ",
        name_long: "(ခမစ) ချမ်းမြသာစည်",
        nrc_code: "9"
    },
    {
        id: "170",
        name_en: "KaPaTa",
        name_mm: "ကပတ",
        name_long: "(ကပတ) ကျောက်ပန်းတောင်း",
        nrc_code: "9"
    },
    {
        id: "171",
        name_en: "KaSaNa",
        name_mm: "ကဆန",
        name_long: "(ကဆန) ကျောက်ဆည်",
        nrc_code: "9"
    },
    {
        id: "172",
        name_en: "MaLaNa",
        name_mm: "မလန",
        name_long: "(မလန) မလိူင်",
        nrc_code: "9"
    },
    {
        id: "173",
        name_en: "MaHaMa",
        name_mm: "မဟမ",
        name_long: "(မဟမ) မဟာအောင်မြေ",
        nrc_code: "9"
    },
    {
        id: "174",
        name_en: "MaNaMa",
        name_mm: "မနမ",
        name_long: "(မနမ) မန်းနောက်မြောက်",
        nrc_code: "9"
    },
    {
        id: "175",
        name_en: "MaNaTa",
        name_mm: "မနတ",
        name_long: "(မနတ) မန်းနောက်တောင်",
        nrc_code: "9"
    },
    {
        id: "176",
        name_en: "MaYaMa",
        name_mm: "မရမ",
        name_long: "(မရမ) မန်းရှေ့မြောက်",
        nrc_code: "9"
    },
    {
        id: "177",
        name_en: "MaYaTa",
        name_mm: "မရတ",
        name_long: "(မရတ) မန်းရှေ့တောင်",
        nrc_code: "9"
    },
    {
        id: "178",
        name_en: "MaTaYa",
        name_mm: "မတရ",
        name_long: "(မတရ) မတ္တရာ",
        nrc_code: "9"
    },
    {
        id: "179",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မေမြို့",
        nrc_code: "9"
    },
    {
        id: "180",
        name_en: "MaHtaLa",
        name_mm: "မထလ",
        name_long: "(မထလ) မိတ္ထီလာ",
        nrc_code: "9"
    },    
    {
        id: "181",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မိုးကုတ်",
        nrc_code: "9"
    },
    {
        id: "182",
        name_en: "MaKhaNa",
        name_mm: "မခန",
        name_long: "(မခန) မြင်းခြံ",
        nrc_code: "9"
    },
    {
        id: "183",
        name_en: "MaThaNa",
        name_mm: "မသန",
        name_long: "(မသန) မြစ်သား",
        nrc_code: "9"
    },
    {
        id: "184",
        name_en: "NaHtaKa",
        name_mm: "နထက",
        name_long: "(နထက) နွားထိုးကြီး",
        nrc_code: "9"
    },
    {
        id: "185",
        name_en: "NgaTaYa",
        name_mm: "ငသရ",
        name_long: "(ငသရ) င့ါသရောက်",
        nrc_code: "9"
    },
    {
        id: "186",
        name_en: "NyaOuNa",
        name_mm: "ညဥန",
        name_long: "(ညဥန) ညောင်ဦး",
        nrc_code: "9"
    },
    {
        id: "187",
        name_en: "PaLaNa",
        name_mm: "ပလန",
        name_long: "(ပလန) ပုလိပ်",
        nrc_code: "9"
    },
    {
        id: "188",
        name_en: "PaThaKa",
        name_mm: "ပသက",
        name_long: "(ပသက) ပုသိမ်ကြီး",
        nrc_code: "9"
    },
    {
        id: "189",
        name_en: "PaBaNa",
        name_mm: "ပဖန",
        name_long: "(ပဖန) ပျော်ဖွယ်",
        nrc_code: "9"
    },
    {
        id: "190",
        name_en: "PaKaKha",
        name_mm: "ပကခ",
        name_long: "(ပကခ) ပြည်ကြီးတံခွန်",
        nrc_code: "9"
    },
    {
        id: "191",
        name_en: "PaOuLa",
        name_mm: "ပဥလ",
        name_long: "(ပဥလ) ပြင်ဦးလွင်",
        nrc_code: "9"
    },
    {
        id: "192",
        name_en: "PaMaNa",
        name_mm: "ပမန",
        name_long: "(ပမန) ပျဉ်းမနား",
        nrc_code: "9"
    },
    {
        id: "193",
        name_en: "SaKaTa",
        name_mm: "စကတ",
        name_long: "(စကတ) စဉ့်ကိုင်",
        nrc_code: "9"
    },
    {
        id: "194",
        name_en: "SaKaNa",
        name_mm: "ဆကန",
        name_long: "(ဆကန) စဉ့်ကူး",
        nrc_code: "9"
    },
    {
        id: "195",
        name_en: "TaKaNa",
        name_mm: "တကန",
        name_long: "(တကန) တကောင်း",
        nrc_code: "9"
    },
    {
        id: "196",
        name_en: "TaTaOu",
        name_mm: "တတဥ",
        name_long: "(တတဥ) တံတားဦး",
        nrc_code: "9"
    },
    {
        id: "197",
        name_en: "TaThaNa",
        name_mm: "တသန",
        name_long: "(တသန) တောင်သာ",
        nrc_code: "9"
    },
    {
        id: "198",
        name_en: "ThaPaKa",
        name_mm: "သပက",
        name_long: "(သပက) သပိတ်ကျင်း",
        nrc_code: "9"
    },
    {
        id: "199",
        name_en: "ThaSaNa",
        name_mm: "သစန",
        name_long: "(သစန) သာစည်",
        nrc_code: "9"
    },
    {
        id: "200",
        name_en: "WaTaNa",
        name_mm: "ဝတန",
        name_long: "(ဝတန) ဝမ်းတွင်း",
        nrc_code: "9"
    },    
    {
        id: "201",
        name_en: "YaMaTha",
        name_mm: "ရမသ",
        name_long: "(ရမသ) ရမည်းသင်း",
        nrc_code: "9"
    },
    {
        id: "202",
        name_en: "BaLaNa",
        name_mm: "ဘလန",
        name_long: "(ဘလန) ဘီးလင်း",
        nrc_code: "10"
    },
    {
        id: "203",
        name_en: "KhaSaNa",
        name_mm: "ခဆန",
        name_long: "(ခဆန) ချောင်းဆုံ",
        nrc_code: "10"
    },
    {
        id: "204",
        name_en: "KaMaYa",
        name_mm: "ကမရ",
        name_long: "(ကမရ) ကျိုက်မရော",
        nrc_code: "10"
    },
    {
        id: "205",
        name_en: "KaHtaNa",
        name_mm: "ကထန",
        name_long: "(ကထန) ကျိုက်ထို",
        nrc_code: "10"
    },
    {
        id: "206",
        name_en: "MaLaMa",
        name_mm: "မလမ",
        name_long: "(မလမ) မော်လမြိုင်",
        nrc_code: "10"
    },
    {
        id: "207",
        name_en: "MaDaNa",
        name_mm: "မဒန",
        name_long: "(မဒန) မုဒုံ",
        nrc_code: "10"
    },
    {
        id: "208",
        name_en: "PaMaNa",
        name_mm: "ပမန",
        name_long: "(ပမန) ပေါင်",
        nrc_code: "10"
    },
    {
        id: "209",
        name_en: "ThaPhaYa",
        name_mm: "သဖရ",
        name_long: "(သဖရ) သံဖြူဇရပ်",
        nrc_code: "10"
    },
    {
        id: "210",
        name_en: "ThaHtaNa",
        name_mm: "သထန",
        name_long: "(သထန) သထုံ",
        nrc_code: "10"
    },
    {
        id: "211",
        name_en: "KhaZaNa",
        name_mm: "ခဇန",
        name_long: "(ခဇန) ခေါဇာ",
        nrc_code: "10"
    },
    {
        id: "212",
        name_en: "LaMaNa",
        name_mm: "လမန",
        name_long: "(လမန) လမိုင်း",
        nrc_code: "10"
    },
    {
        id: "213",
        name_en: "YaMaNa",
        name_mm: "ရမန",
        name_long: "(ရမန) ရေး",
        nrc_code: "10"
    },
    {
        id: "214",
        name_en: "AaMaNa",
        name_mm: "အမန",
        name_long: "(အမန) အမ်း",
        nrc_code: "11"
    },
    {
        id: "215",
        name_en: "BaThaTa",
        name_mm: "ဘသတ",
        name_long: "(ဘသတ) ဘူးသီးတောင်",
        nrc_code: "11"
    },
    {
        id: "216",
        name_en: "GaMaNa",
        name_mm: "ဂမန",
        name_long: "(ဂမန) ဂွ",
        nrc_code: "11"
    },
    {
        id: "217",
        name_en: "KaPhaNa",
        name_mm: "ကဖန",
        name_long: "(ကဖန) ကျောက်ဖြူ",
        nrc_code: "11"
    },
    {
        id: "218",
        name_en: "KaTaNa",
        name_mm: "ကတန",
        name_long: "(ကတန) ကျောက်တော်",
        nrc_code: "11"
    },
    {
        id: "219",
        name_en: "MaAhNa",
        name_mm: "မအန",
        name_long: "(မအန) မာန်အောင်",
        nrc_code: "11"
    },
    {
        id: "220",
        name_en: "MaTaNa",
        name_mm: "မတန",
        name_long: "(မတန) မောင်းတော",
        nrc_code: "11"
    },
    {
        id: "221",
        name_en: "MaPaNa",
        name_mm: "မပန",
        name_long: "(မပန) မင်းပြား",
        nrc_code: "11"
    },
    {
        id: "222",
        name_en: "MaOuNa",
        name_mm: "မဥန",
        name_long: "(မဥန) မြောက်ဦး",
        nrc_code: "11"
    },
    {
        id: "223",
        name_en: "MaPaTa",
        name_mm: "မပတ",
        name_long: "(မပတ) မြေပုံ",
        nrc_code: "11"
    },
    {
        id: "224",
        name_en: "PaTaNa",
        name_mm: "ပတန",
        name_long: "(ပတန) ပေါက်တော",
        nrc_code: "11"
    },
    {
        id: "225",
        name_en: "PaNaKa",
        name_mm: "ပဏက",
        name_long: "(ပဏက) ပုဏ္ဏကျွန်း",
        nrc_code: "11"
    },
    {
        id: "226",
        name_en: "SaTaNa",
        name_mm: "စတန",
        name_long: "(စတန) စစ်တွေ",
        nrc_code: "11"
    },
    {
        id: "227",
        name_en: "TaKaNa",
        name_mm: "တကန",
        name_long: "(တကန) တောင်ကုတ်",
        nrc_code: "11"
    },
    {
        id: "228",
        name_en: "ThaTaNa",
        name_mm: "သတန",
        name_long: "(သတန) သံတွဲ",
        nrc_code: "11"
    },
    {
        id: "229",
        name_en: "YaBaNa",
        name_mm: "ရဗန",
        name_long: "(ရဗန) ရမ်းဗြဲ"
    },
    {
        id: "230",
        name_en: "YaThaTa",
        name_mm: "ရသတ",
        name_long: "(ရသတ) ရသေ့တောင်"
    },
    {
        id: "231",
        name_en: "AaLaNa",
        name_mm: "အလန",
        name_long: "(အလန) အလုံ",
        nrc_code: "12"
    },
    {
        id: "232",
        name_en: "BaHaNa",
        name_mm: "ဗဟန",
        name_long: "(ဗဟန) ဗဟန်း",
        nrc_code: "12"
    },
    {
        id: "233",
        name_en: "BaTaHta",
        name_mm: "ဗတထ",
        name_long: "(ဗတထ) ဗိုလ်တထောင်",
        nrc_code: "12"
    },
    {
        id: "234",
        name_en: "KaKaKa",
        name_mm: "ကကက",
        name_long: "(ကကက) ကိုကိုးကျွန်း",
        nrc_code: "12"
    },
    {
        id: "235",
        name_en: "DaGaNa",
        name_mm: "ဒဂန",
        name_long: "(ဒဂန) ဒဂုံ",
        nrc_code: "12"
    },
    {
        id: "236",
        name_en: "DaGaYa",
        name_mm: "ဒဂရ",
        name_long: "(ဒဂရ) ဒဂုံမြို့သစ်(အရှေ့ပိုင်း)",
        nrc_code: "12"
    },
    {
        id: "237",
        name_en: "DaGaMa",
        name_mm: "ဒဂမ",
        name_long: "(ဒဂမ) ဒဂုံမြို့သစ်(မြောက်ပိုင်း)",
        nrc_code: "12"
    },
    {
        id: "238",
        name_en: "DaSaKa",
        name_mm: "ဒဆက",
        name_long: "(ဒဆက) ဒဂုံမြို့သစ်ဆိပ်ကမ်း",
        nrc_code: "12"
    },
    {
        id: "239",
        name_en: "DaGaTa",
        name_mm: "ဒဂတ",
        name_long: "(ဒဂတ) ဒဂုံမြို့သစ်(တောင်ပိုင်း)",
        nrc_code: "12"
    },
    {
        id: "240",
        name_en: "DaLaNa",
        name_mm: "ဒလန",
        name_long: "(ဒလန) ဒလ",
        nrc_code: "12"
    },
    {
        id: "241",
        name_en: "DaPaNa",
        name_mm: "ဒပန",
        name_long: "(ဒပန) ဒေါပုံ",
        nrc_code: "12"
    },
    {
        id: "242",
        name_en: "LaMaNa",
        name_mm: "လမန",
        name_long: "(လမန) လှိုင်",
        nrc_code: "12"
    },
    {
        id: "243",
        name_en: "LaThaYa",
        name_mm: "လသယ",
        name_long: "(လသယ) လှိုင်သာယာ",
        nrc_code: "12"
    },
    {
        id: "244",
        name_en: "LaKaNa",
        name_mm: "လကန",
        name_long: "(လကန) လှည်းကူး",
        nrc_code: "12"
    },
    {
        id: "245",
        name_en: "MaBaNa",
        name_mm: "မဘန",
        name_long: "(မဘန) မှော်ဘီ",
        nrc_code: "12"
    },
    {
        id: "246",
        name_en: "HtaTaPa",
        name_mm: "ထတပ",
        name_long: "(ထတပ) ထန်းတပင်",
        nrc_code: "12"
    },
    {
        id: "247",
        name_en: "AhSaNa",
        name_mm: "အစန",
        name_long: "(အစန) အင်းစိန်",
        nrc_code: "12"
    },
    {
        id: "248",
        name_en: "KaMaYa",
        name_mm: "ကမရ",
        name_long: "(ကမရ) ကမာရွတ်",
        nrc_code: "12"
    },
    {
        id: "249",
        name_en: "KaMaNa",
        name_mm: "ကမန",
        name_long: "(ကမန) ကော့မှုး",
        nrc_code: "12"
    },
    {
        id: "250",
        name_en: "KhaYaNa",
        name_mm: "ခရန",
        name_long: "(ခရန) ခရမ်း",
        nrc_code: "12"
    },
    {
        id: "251",
        name_en: "KaKhaKa",
        name_mm: "ကခက",
        name_long: "(ကခက) ကွမ်းခြံကုန်း",
        nrc_code: "12"
    },
    {
        id: "252",
        name_en: "KaTaTa",
        name_mm: "ကတတ",
        name_long: "(ကတတ) ကျောက်တံတား",
        nrc_code: "12"
    },
    {
        id: "253",
        name_en: "KaTaNa",
        name_mm: "ကတန",
        name_long: "(ကတန) ကျောက်တန်း",
        nrc_code: "12"
    },
    {
        id: "254",
        name_en: "KaMaTa",
        name_mm: "ကမတ",
        name_long: "(ကမတ) ကြည့်မြင်တိုင်",
        nrc_code: "12"
    },
    {
        id: "255",
        name_en: "LaMaTa",
        name_mm: "လမတ",
        name_long: "(လမတ) လမ်းမတော်",
        nrc_code: "12"
    },
    {
        id: "256",
        name_en: "LaThaNa",
        name_mm: "လသန",
        name_long: "(လသန) လသာ",
        nrc_code: "12"
    },
    {
        id: "257",
        name_en: "MaYaKa",
        name_mm: "မရက",
        name_long: "(မရက) မရမ်းကုန်း",
        nrc_code: "12"
    },
    {
        id: "258",
        name_en: "MaGaTa",
        name_mm: "မဂတ",
        name_long: "(မဂတ) မင်္ဂလာတောင်ညွှန့်",
        nrc_code: "12"
    },
    {
        id: "259",
        name_en: "MaGaDa",
        name_mm: "မဂဒ",
        name_long: "(မဂဒ) မင်္ဂလာဒုံ",
        nrc_code: "12"
    },
    {
        id: "260",
        name_en: "OuKaMa",
        name_mm: "ဥကမ",
        name_long: "(ဥကမ) မြောက်ဥက္ကလာပ",
        nrc_code: "12"
    },
    {
        id: "261",
        name_en: "PaBaTa",
        name_mm: "ပဘတ",
        name_long: "(ပဘတ) ပန်းဘဲတန်း",
        nrc_code: "12"
    },
    {
        id: "262",
        name_en: "PaZaDa",
        name_mm: "ပဇတ",
        name_long: "(ပဇတ) ပုဇွန်တောင်",
        nrc_code: "12"
    },
    {
        id: "263",
        name_en: "SaKhaNa",
        name_mm: "စခန",
        name_long: "(စခန) စမ်းချောင်း",
        nrc_code: "12"
    },
    {
        id: "264",
        name_en: "SaKakha",
        name_mm: "ဆကခ",
        name_long: "(ဆကခ) ဆိပ်ကြီးခနောင်တို",
        nrc_code: "12"
    },
    {
        id: "265",
        name_en: "SaKaNa",
        name_mm: "ဆကန",
        name_long: "(ဆကန) ဆိပ်ကမ်း",
        nrc_code: "12"
    },
    {
        id: "266",
        name_en: "YaPaKa",
        name_mm: "ရပက",
        name_long: "(ရပက) ရွှေပေါက်ကံ",
        nrc_code: "12"
    },
    {
        id: "267",
        name_en: "YaPaTha",
        name_mm: "ရပသ",
        name_long: "(ရပသ) ရွှေပြည်သာ",
        nrc_code: "12"
    },
    {
        id: "268",
        name_en: "OuKaTa",
        name_mm: "ဥကတ",
        name_long: "(ဥကတ) တောင်ဥက္ကလာပ",
        nrc_code: "12"
    },
    {
        id: "269",
        name_en: "TaKaNa",
        name_mm: "တကန",
        name_long: "(တကန) တိုက်ကြီး",
        nrc_code: "12"
    },
    {
        id: "270",
        name_en: "TaMaNa",
        name_mm: "တမန",
        name_long: "(တမန) တာမွေ",
        nrc_code: "12"
    },
    {
        id: "271",
        name_en: "ThaKaTa",
        name_mm: "သကတ",
        name_long: "(သကတ) သာကေတ",
        nrc_code: "12"
    },
    {
        id: "272",
        name_en: "ThaLaNa",
        name_mm: "သလန",
        name_long: "(သလန) သန်လျင်",
        nrc_code: "12"
    },
    {
        id: "273",
        name_en: "ThaGaKa",
        name_mm: "သဃက",
        name_long: "(သဃက) သင်္ဃန်းကျွန်း",
        nrc_code: "12"
    },
    {
        id: "274",
        name_en: "ThaKhaNa",
        name_mm: "သခန",
        name_long: "(သခန) သုံးခွ",
        nrc_code: "12"
    },
    {
        id: "275",
        name_en: "TaTaNa",
        name_mm: "တတန",
        name_long: "(တတန) တွံတေး",
        nrc_code: "12"
    },
    {
        id: "276",
        name_en: "YaKaNa",
        name_mm: "ရကန",
        name_long: "(ရကန) ရန်ကင်း",
        nrc_code: "12"
    },
    {
        id: "277",
        name_en: "KhaYaHa",
        name_mm: "ခရဟ",
        name_long: "(ခရဟ) ချင်းရွှေဟော်",
        nrc_code: "13"
    },
    {
        id: "278",
        name_en: "HaPaTa",
        name_mm: "ဟပတ",
        name_long: "(ဟပတ) ဟိုပန်",
        nrc_code: "13"
    },
    {
        id: "279",
        name_en: "HaPaNa",
        name_mm: "ဟပန",
        name_long: "(ဟပန) ဟိုပုံး",
        nrc_code: "13"
    },
    {
        id: "280",
        name_en: "KaLaNa",
        name_mm: "ကလန",
        name_long: "(ကလန) ကလော",
        nrc_code: "13"
    },
    {
        id: "281",
        name_en: "KaLaTa",
        name_mm: "ကလတ",
        name_long: "(ကလတ) ကွမ်းလုံ",
        nrc_code: "13"
    },
    {
        id: "282",
        name_en: "KaHaNa",
        name_mm: "ကဟန",
        name_long: "(ကဟန) ကွန်ဟိန်း",
        nrc_code: "13"
    },
    {
        id: "283",
        name_en: "KaThaNa",
        name_mm: "ကသန",
        name_long: "(ကသန) ကျေးသီး",
        nrc_code: "13"
    },
    {
        id: "284",
        name_en: "KaTaTa",
        name_mm: "ကတတ",
        name_long: "(ကတတ) ကျိုင်းတောင်း",
        nrc_code: "13"
    },
    {
        id: "285",
        name_en: "KaTaNa",
        name_mm: "ကတန",
        name_long: "(ကတန) ကျိုင်းတုံ",
        nrc_code: "13"
    },
    {
        id: "286",
        name_en: "KaMaNa",
        name_mm: "ကမန",
        name_long: "(ကမန) ကျောက်မဲ",
        nrc_code: "13"
    },
    {
        id: "287",
        name_en: "KaKhaNa",
        name_mm: "ကခန",
        name_long: "(ကခန) ကွတ်ခိုင်",
        nrc_code: "13"
    },
    {
        id: "288",
        name_en: "LaYaNa",
        name_mm: "လရန",
        name_long: "(လရန) လားရှိုး",
        nrc_code: "13"
    },
    {
        id: "289",
        name_en: "LaKaNa",
        name_mm: "လကန",
        name_long: "(လကန) လောက်ကိုင်",
        nrc_code: "13"
    },
    {
        id: "290",
        name_en: "LaKhaTa",
        name_mm: "လခတ",
        name_long: "(လခတ) လဲချား",
        nrc_code: "13"
    },
    {
        id: "291",
        name_en: "LaKhaNa",
        name_mm: "လခန",
        name_long: "(လခန) လင်းခေး",
        nrc_code: "13"
    },
    {
        id: "292",
        name_en: "LaLaNa",
        name_mm: "လလန",
        name_long: "(လလန) လွိုင်လင်",
        nrc_code: "13"
    },
    {
        id: "293",
        name_en: "MaBaNa",
        name_mm: "မဘန",
        name_long: "(မဘန) မဘိမ်း",
        nrc_code: "13"
    },
    {
        id: "294",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မိုင်းကိုင်",
        nrc_code: "13"
    },
    {
        id: "295",
        name_en: "MaKhaNa",
        name_mm: "မခန",
        name_long: "(မခန) မိုင်းခတ်",
        nrc_code: "13"
    },
    {
        id: "296",
        name_en: "MaPHaNa",
        name_mm: "မဖန",
        name_long: "(မဖန) မိုင်းဖြတ်",
        nrc_code: "13"
    },
    {
        id: "297",
        name_en: "MaPaTa",
        name_mm: "မပတ",
        name_long: "(မပတ) မိုင်းပြင်း",
        nrc_code: "13"
    },
    {
        id: "298",
        name_en: "MaSaNa",
        name_mm: "မဆန",
        name_long: "(မဆန) မိုင်းဆတ်",
        nrc_code: "13"
    },
    {
        id: "299",
        name_en: "MaYaNa",
        name_mm: "မရန",
        name_long: "(မရန) မိုင်းရှုး",
        nrc_code: "13"
    },
    {
        id: "300",
        name_en: "MaYaTa",
        name_mm: "မရတ",
        name_long: "(မရတ) မိုင်းရယ်",
        nrc_code: "13"
    },
    {
        id: "301",
        name_en: "MaTaTa",
        name_mm: "မတတ",
        name_long: "(မတတ) မန်တုန်",
        nrc_code: "13"
    },
    {
        id: "302",
        name_en: "MaMaTa",
        name_mm: "မမတ",
        name_long: "(မမတ) မိုးမိတ်",
        nrc_code: "13"
    },
    {
        id: "303",
        name_en: "MaNaNa",
        name_mm: "မနန",
        name_long: "(မနန) မိုးနဲ",
        nrc_code: "13"
    },
    {
        id: "304",
        name_en: "MaKaNa",
        name_mm: "မကန",
        name_long: "(မကန) မုန်းကိုး",
        nrc_code: "13"
    },
    {
        id: "305",
        name_en: "MaSaTa",
        name_mm: "မဆတ",
        name_long: "(မဆတ) မူဆယ်",
        nrc_code: "13"
    },
    {
        id: "306",
        name_en: "NaMaTa",
        name_mm: "နမတ",
        name_long: "(နမတ) နမ့်မတူ",
        nrc_code: "13"
    },
    {
        id: "307",
        name_en: "NaKhaNa",
        name_mm: "နခန",
        name_long: "(နခန) နမ့်ခမ်း",
        nrc_code: "13"
    },
    {
        id: "308",
        name_en: "NaSaNa",
        name_mm: "နဆန",
        name_long: "(နဆန) နမ့်ဆန်",
        nrc_code: "13"
    },
    {
        id: "309",
        name_en: "NaPaNa",
        name_mm: "နဖန",
        name_long: "(နဖန) နမ့်ဖိုင်",
        nrc_code: "13"
    },
    {
        id: "310",
        name_en: "NaKhaTa",
        name_mm: "နခတ",
        name_long: "(နခတ) နောင်ချို",
        nrc_code: "13"
    },
    {
        id: "311",
        name_en: "NyaYaNa",
        name_mm: "ညရန",
        name_long: "(ညရန) ညောင်ရွှေ",
        nrc_code: "13"
    },
    {
        id: "312",
        name_en: "PhaKhaNa",
        name_mm: "ဖခန",
        name_long: "(ဖခန) ဖယ်ခုံ",
        nrc_code: "13"
    },
    {
        id: "313",
        name_en: "PaLaNa",
        name_mm: "ပလန",
        name_long: "(ပလန) ပင်လုံ",
        nrc_code: "13"
    },
    {
        id: "314",
        name_en: "PaTaYa",
        name_mm: "ပတရ",
        name_long: "(ပတရ) ပင်းတယ",
        nrc_code: "13"
    },
    {
        id: "315",
        name_en: "SaSaNa",
        name_mm: "ဆဆန",
        name_long: "(ဆဆန) ဆီဆိုင်",
        nrc_code: "13"
    },
    {
        id: "316",
        name_en: "YaNyaNa",
        name_mm: "ရညန",
        name_long: "(ရညန) ရွှေညောင်",
        nrc_code: "13"
    },
    {
        id: "317",
        name_en: "TaYaNa",
        name_mm: "တယန",
        name_long: "(တယန) တန့်ယန်း",
        nrc_code: "13"
    },
    {
        id: "318",
        name_en: "TaMaNya",
        name_mm: "တမည",
        name_long: "(တမည) တာမိုးညဲ",
        nrc_code: "13"
    },
    {
        id: "319",
        name_en: "TaKhaLa",
        name_mm: "တခလ",
        name_long: "(တခလ) တာချီလိတ်",
        nrc_code: "13"
    },
    {
        id: "320",
        name_en: "TaLaNa",
        name_mm: "တလန",
        name_long: "(တလန) တာလေ",
        nrc_code: "13"
    },
    {
        id: "321",
        name_en: "TaKaNa",
        name_mm: "တကန",
        name_long: "(တကန) တောင်ကြီး",
        nrc_code: "13"
    },
    {
        id: "322",
        name_en: "ThaNaNa",
        name_mm: "သနန",
        name_long: "(သနန) သိန္နီ",
        nrc_code: "13"
    },
    {
        id: "323",
        name_en: "ThaPaNa",
        name_mm: "သပန",
        name_long: "(သပန) သီပေါ",
        nrc_code: "13"
    },
    {
        id: "324",
        name_en: "YaNgaNa",
        name_mm: "ရငန",
        name_long: "(ရငန) ရွာငံ",
        nrc_code: "13"
    },
    {
        id: "325",
        name_en: "YaSaNa",
        name_mm: "ရစန",
        name_long: "(ရစန) ရပ်စောက်",
        nrc_code: "13"
    },
    {
        id: "326",
        name_en: "BaKaLa",
        name_mm: "ဘကလ",
        name_long: "(ဘကလ) ဘိုကလေး",
        nrc_code: "14"
    },
    {
        id: "327",
        name_en: "DaNaPha",
        name_mm: "ဓနဖ",
        name_long: "(ဓနဖ) ဓနုဖြူ",
        nrc_code: "14"
    },
    {
        id: "328",
        name_en: "DaDaYa",
        name_mm: "ဒဒရ",
        name_long: "(ဒဒရ) ဒေးဒရဲ",
        nrc_code: "14"
    },
    {
        id: "329",
        name_en: "PaThaYa",
        name_mm: "ပသရ",
        name_long: "(ပသရ) ပုသိမ်(အရှေ့)",
        nrc_code: "14"
    },
    {
        id: "330",
        name_en: "AhMaNa",
        name_mm: "အမန",
        name_long: "(အမန) အိမ်မဲ",
        nrc_code: "14"
    },
    {
        id: "331",
        name_en: "HaKaKa",
        name_mm: "ဟကက",
        name_long: "(ဟကက) ဟိုင်းကြီးကျွန်း",
        nrc_code: "14"
    },
    {
        id: "332",
        name_en: "HaThaTa",
        name_mm: "ဟသတ",
        name_long: "(ဟသတ) ဟင်္သာတ",
        nrc_code: "14"
    },
    {
        id: "333",
        name_en: "AhGaPa",
        name_mm: "အဂပ",
        name_long: "(အဂပ) အင်္ဂပူ",
        nrc_code: "14"
    },
    {
        id: "334",
        name_en: "KaNaNa",
        name_mm: "ခနန",
        name_long: "(ခနန) ခနောင်",
        nrc_code: "14"
    },
    {
        id: "335",
        name_en: "KaLaNa",
        name_mm: "ကလန",
        name_long: "(ကလန) ကျိုက်လတ်",
        nrc_code: "14"
    },
    {
        id: "336",
        name_en: "KaKhaNa",
        name_mm: "ကခန",
        name_long: "(ကခန) ကြံခင်း",
        nrc_code: "14"
    },
    {
        id: "337",
        name_en: "KaKaNa",
        name_mm: "ကကန",
        name_long: "(ကကန) ကျောင်းကုန်း",
        nrc_code: "14"
    },
    {
        id: "338",
        name_en: "KaPaNa",
        name_mm: "ကပန",
        name_long: "(ကပန) ကျုံပျော်",
        nrc_code: "14"
    },
    {
        id: "339",
        name_en: "LaPaTa",
        name_mm: "လပတ",
        name_long: "(လပတ) လပွတ္တာ",
        nrc_code: "14"
    },
    {
        id: "340",
        name_en: "LaMaNa",
        name_mm: "လမန",
        name_long: "(လမန) လေးမျက်နှာ",
        nrc_code: "14"
    },
    {
        id: "341",
        name_en: "MaAhPa",
        name_mm: "မအပ",
        name_long: "(မအပ) မအူပင်",
        nrc_code: "14"
    },
    {
        id: "342",
        name_en: "MaMaKa",
        name_mm: "မမက",
        name_long: "(မမက) မော်လမြိုင်ကျွန်း",
        nrc_code: "14"
    },
    {
        id: "343",
        name_en: "MaAhaNa",
        name_mm: "မအန",
        name_long: "(မအန) မြန်အောင်",
        nrc_code: "14"
    },
    {
        id: "344",
        name_en: "MaMaNa",
        name_mm: "မမန",
        name_long: "(မမန) မြောင်းမြ",
        nrc_code: "14"
    },
    {
        id: "345",
        name_en: "NgaPaTa",
        name_mm: "ငပတ",
        name_long: "(ငပတ) ငပုတော",
        nrc_code: "14"
    },
    {
        id: "346",
        name_en: "NgaThaKha",
        name_mm: "ငသခ",
        name_long: "(ငသခ) ငါးသိုင်းချောင်း",
        nrc_code: "14"
    },
    {
        id: "347",
        name_en: "NyaTaNa",
        name_mm: "ညတန",
        name_long: "(ညတန) ညောင်တုန်း",
        nrc_code: "14"
    },
    {
        id: "348",
        name_en: "PaTaNa",
        name_mm: "ပတန",
        name_long: "(ပတန) ပန်းတနော်",
        nrc_code: "14"
    },
    {
        id: "349",
        name_en: "PhaPaNa",
        name_mm: "ဖပန",
        name_long: "(ဖပန) ဖျာပုံ",
        nrc_code: "14"
    },
    {
        id: "350",
        name_en: "ThaPaNa",
        name_mm: "သပန",
        name_long: "(သပန) သာပေါင်း",
        nrc_code: "14"
    },
    {
        id: "351",
        name_en: "WaKhaMa",
        name_mm: "ဝခမ",
        name_long: "(ဝခမ) ဝါးခယ်မ",
        nrc_code: "14"
    },
    {
        id: "352",
        name_en: "PaThaNa",
        name_mm: "ပသန",
        name_long: "(ပသန) ပုသိမ်(အနောက်)",
        nrc_code: "14"
    },
    {
        id: "353",
        name_en: "YaKaNa",
        name_mm: "ရကန",
        name_long: "(ရကန) ရေကြည်",
        nrc_code: "14"
    },
    {
        id: "354",
        name_en: "ZaLaNa",
        name_mm: "ဇလန",
        name_long: "(ဇလန) ဇလွန်",
        nrc_code: "14"
    },
    {
        id: "355",
        name_en: "KaKaHta",
        name_mm: "ကကထ",
        name_long: "(ကကထ) ကန်ကြီးထောင့်",
        nrc_code: "14"
    },
    {
        id: "356",
        name_en: "KhaMaKa",
        name_mm: "ခမက",
        name_long: "(ခမက) ခမောက်ကြီး",
        nrc_code: "6"
    },
    {
        id: "357",
        name_en: "KaMaTa",
        name_mm: "ကမတ",
        name_long: "(ကမတ) ကာမိုင်း",
        nrc_code: "1"
    },
    {
        id: "358",
        name_en: "KaPaTa",
        name_mm: "ကပတ",
        name_long: "(ကပတ) ကန်ပိုက်တီ",
        nrc_code: "1"
    },
    {
        id: "359",
        name_en: "MaLaNa",
        name_mm: "မလန",
        name_long: "(မလန) မြို့လှ",
        nrc_code: "1"
    },
    {
        id: "360",
        name_en: "PaNaDa",
        name_mm: "ပနဒ",
        name_long: "(ပနဒ) ပန်နန်းဒင်",
        nrc_code: "1"
    },
    {
        id: "361",
        name_en: "PaWaNa",
        name_mm: "ပဝန",
        name_long: "(ပဝန) ပန်ဝါ",
        nrc_code: "1"
    },
    {
        id: "362",
        name_en: "SaDaNa",
        name_mm: "ဆဒန",
        name_long: "(ဆဒန) ဆဒုံး",
        nrc_code: "1"
    },
    {
        id: "363",
        name_en: "YaBaYa",
        name_mm: "ရဘယ",
        name_long: "(ရဘယ) ရှင်ဘွေယန်",
        nrc_code: "1"
    },
    {
        id: "364",
        name_en: "MaSaNa",
        name_mm: "မစန",
        name_long: "(မစန) မယ်စဲ့",
        nrc_code: "2"
    },
    {
        id: "365",
        name_en: "YaThaNa",
        name_mm: "ရသန",
        name_long: "(ရသန) ရွာသစ်",
        nrc_code: "2"
    },
    {
        id: "366",
        name_en: "BaGaLa",
        name_mm: "ဘဂလ",
        name_long: "(ဘဂလ) ဘောဂလိ",
        nrc_code: "3"
    },
    {
        id: "367",
        name_en: "BaThaSa",
        name_mm: "ဘသဆ",
        name_long: "(ဘသဆ) ဘုရားသုံးဆူ",
        nrc_code: "3"
    },
    {
        id: "371",
        name_en: "ThaTaKa",
        name_mm: "သတက",
        name_long: "(သတက) သံတောင်ကြီး",
        nrc_code: "3"
    },
    {
        id: "372",
        name_en: "WaLaMa",
        name_mm: "ဝလမ",
        name_long: "(ဝလမ) ဝေါလေမြိုင်",
        nrc_code: "3"
    },
    {
        id: "373",
        name_en: "YaYaTha",
        name_mm: "ရရသ",
        name_long: "(ရရသ) ရှမ်းရွာသစ်",
        nrc_code: "3"
    },
    {
        id: "374",
        name_en: "KaKhaNa",
        name_mm: "ကခန",
        name_long: "(ကခန) ကျီခါး",
        nrc_code: "4"
    },
    {
        id: "375",
        name_en: "SaMaNa",
        name_mm: "ဆမန",
        name_long: "(ဆမန) ဆမီး",
        nrc_code: "4"
    },
    {
        id: "376",
        name_en: "YaKhaDa",
        name_mm: "ရခဒ",
        name_long: "(ရခဒ) ရိဒ်ခေါဒါရ်",
        nrc_code: "4"
    },
    {
        id: "377",
        name_en: "YaZaNa",
        name_mm: "ရဇန",
        name_long: "(ရဇန) ရေဇွာ",
        nrc_code: "4"
    },
    {
        id: "378",
        name_en: "DaHaNa",
        name_mm: "ဒဟန",
        name_long: "(ဒဟန) ဒုံဟီး",
        nrc_code: "5"
    },
    {
        id: "379",
        name_en: "SaMaYa",
        name_mm: "ဆမရ",
        name_long: "(ဆမရ) ဆွမ္မရာ",
        nrc_code: "5"
    },
    {
        id: "380",
        name_en: "HtaPaKha",
        name_mm: "ထပခ",
        name_long: "(ထပခ) ထန်ပါခွေ",
        nrc_code: "5"
    },
    {
        id: "381",
        name_en: "KaMaNa",
        name_mm: "ကမန",
        name_long: "(ကမန) ကျောက်မြောင်း",
        nrc_code: "5"
    },
    {
        id: "382",
        name_en: "KhaPaNa",
        name_mm: "ခပန",
        name_long: "(ခပန) ခမ်းပတ်",
        nrc_code: "5"
    },
    {
        id: "383",
        name_en: "LaHaNa",
        name_mm: "လဟန",
        name_long: "(လဟန) လဟယ်",
        nrc_code: "5"
    },
    {
        id: "384",
        name_en: "LaYaNa",
        name_mm: "လရန",
        name_long: "(လရန) လေရှီး",
        nrc_code: "5"
    },
    {
        id: "385",
        name_en: "MaMaNa",
        name_mm: "မမတ",
        name_long: "(မမတ) မြင်းမူ",
        nrc_code: "5"
    },
    {
        id: "386",
        name_en: "MaPaLa",
        name_mm: "မပလ",
        name_long: "(မပလ) မိုပိုင်းလွတ်",
        nrc_code: "5"
    },
    {
        id: "387",
        name_en: "MaThaNa",
        name_mm: "မသန",
        name_long: "(မသန) မြို့သစ်",
        nrc_code: "5"
    },
    {
        id: "388",
        name_en: "PaSaNa",
        name_mm: "ပဆန",
        name_long: "(ပဆန) ပန်ဆောင်",
        nrc_code: "5"
    },
    {
        id: "389",
        name_en: "KaLaAh",
        name_mm: "ကလအ",
        name_long: "(ကလအ) ကလိန်အောင်",
        nrc_code: "6"
    },
    {
        id: "390",
        name_en: "KaYaYa",
        name_mm: "ကရရ",
        name_long: "(ကရရ) ကရသူရိ",
        nrc_code: "6"
    },
    {
        id: "391",
        name_en: "MaAhNa",
        name_mm: "မအန",
        name_long: "(မအန) မြိတ်အနောက်",
        nrc_code: "6"
    },
    {
        id: "392",
        name_en: "PaKaMa",
        name_mm: "ပကမ",
        name_long: "(ပကမ) ပြည်ကြီးမဏ္ဍိုင်",
        nrc_code: "6"
    },
    {
        id: "393",
        name_en: "PaLaTa",
        name_mm: "ပလတ",
        name_long: "(ပလတ) ပလောက်",
        nrc_code: "6"
    },
    {
        id: "394",
        name_en: "KaWaNa",
        name_mm: "ကဝန",
        name_long: "(ကဝန) ကဝ",
        nrc_code: "7"
    },
    {
        id: "395",
        name_en: "LaPATa",
        name_mm: "လပတ",
        name_long: "(လပတ) လက်ပံတန်း",
        nrc_code: "7"
    },
    {
        id: "396",
        name_en: "PaTaLa",
        name_mm: "ပတလ",
        name_long: "(ပတလ) ပေါင်းတလည်",
        nrc_code: "7"
    },
    {
        id: "397",
        name_en: "KaHtaNa",
        name_mm: "ကထန",
        name_long: "(ကထန) ကျောက်ထု",
        nrc_code: "8"
    },
    {
        id: "398",
        name_en: "NgaZaNa",
        name_mm: "ငဇန",
        name_long: "(ငဇန) ငါန်းဇွန်",
        nrc_code: "9"
    },
    {
        id: "399",
        name_en: "PaBaNa",
        name_mm: "ပဘန",
        name_long: "(ပဘန) ပျော်ဘွယ်",
        nrc_code: "9"
    },
    {
        id: "400",
        name_en: "OoTaYa",
        name_mm: "ဥတသ",
        name_long: "(ဥတသ) ဥတ္တရသီရိ",
        nrc_code: "9"
    },
    {
        id: "401",
        name_en: "KaKhaMa",
        name_mm: "(ကခမ) ကျိုက္ခမီ",
        nrc_code: "10"
    },
    {
        id: "402",
        name_en: "KaTaLa",
        name_mm: "(ကတလ) ကျိန္တလီ",
        nrc_code: "11"
    },
    {
        id: "403",
        name_en: "MaAhTa",
        name_mm: "(မအတ) မအီ",
        nrc_code: "11"
    },
    {
        id: "404",
        name_en: "TaPaWa",
        name_mm: "(တပဝ) တောင်ပြိုလက်ဝဲ",
        nrc_code: "11"
    },
    {
        id: "405",
        name_en: "AhPaNa",
        name_mm: "(အပန) အောင်ပန်း",
        nrc_code: "13"
    },
    {
        id: "406",
        name_en: "AhTaNa",
        name_mm: "(အတန) အင်တော",
        nrc_code: "13"
    },
    {
        id: "407",
        name_en: "AhTaYa",
        name_mm: "(အသယ) အေးသာယာ",
        nrc_code: "13"
    },
    {
        id: "408",
        name_en: "HaHaNa",
        name_mm: "(ဟဟန) ဟဲဟိုး",
        nrc_code: "13"
    },
    {
        id: "409",
        name_en: "HaMaNa",
        name_mm: "(ဟမန) ဟိုမိန်း",
        nrc_code: "13"
    },
    {
        id: "410",
        name_en: "KaLaHta",
        name_mm: "(ကလထ) ကျိူင်းလပ်",
        nrc_code: "13"
    },
    {
        id: "411",
        name_en: "KaLaNa",
        name_mm: "(ခလန) ခိုလမ်",
        nrc_code: "13"
    },
    {
        id: "412",
        name_en: "MaHtaNa",
        name_mm: "(မထန) မော်ထိုက်",
        nrc_code: "13"
    },
    {
        id: "413",
        name_en: "MaKhaTa",
        name_mm: "(မခတ) မိုင်းခုတ်",
        nrc_code: "13"
    },
    {
        id: "414",
        name_en: "MaNgaNa",
        name_mm: "(မငန) မိုင်းငေါ့",
        nrc_code: "13"
    },
    {
        id: "415",
        name_en: "MaPhaHta",
        name_mm: "(မဖထ) မိုင်းဖျန်",
        nrc_code: "13"
    },
    {
        id: "416",
        name_en: "NaTaYa",
        name_mm: "(နတရ) နောင်တရား",
        nrc_code: "13"
    },
    {
        id: "417",
        name_en: "PaPaKa",
        name_mm: "(ပပက) ပုံပါကျင်",
        nrc_code: "13"
    },
    {
        id: "418",
        name_en: "PaWaNa",
        name_mm: "(ပဝန) ပန်ဝိုင်",
        nrc_code: "13"
    },
    {
        id: "419",
        name_en: "TaTaNa",
        name_mm: "(တတန) တုံတာ",
        nrc_code: "13"
    },
    {
        id: "420",
        name_en: "AhMaTa",
        name_mm: "(အမတ) အမာ",
        nrc_code: "14"
    },
    {
        id: "421",
        name_en: "NgaYaKa",
        name_mm: "(ငရက) ငရုတ်ကောင်း",
        nrc_code: "14"
    },
    {
        id: "422",
        name_en: "PaSaLa",
        name_mm: "(ပစလ) ပြင်စလူ",
        nrc_code: "14"
    },
    {
        id: "423",
        name_en: "YaThaYa",
        name_mm: "(ရသယ) ရွှေသောင်ယံ",
        nrc_code: "14"
    },
    {
        id: "424",
        name_en: "KhAaHsa",
        name_mm: "(ခအဇ) ချမ်းအေးသာစံ",
        nrc_code: "9"
    },
    {
        id: "425",
        name_en: "TaTaHta",
        name_mm: "တတထ (တံတား)",
        nrc_code: "12"
    }
    
];