import React from "react";
import "./FlightCard.scss";

import line from "../../assets/icons/line.svg";

import suitCase from "../../assets/icons/suitcase.svg";
import food from "../../assets/icons/food.svg";
import detailArrow from "../../assets/icons/detail-arrow.svg";

import { chooseLogo } from "../../utils/utils";
import { formatDateHeader, formatDateString } from "../../utils/format";
import { useTranslation } from "react-i18next";

const FlightCard = ({
  data,
  currency,
  onClick,
  isSelected,
  isLayoverFlight,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${
        isSelected ? "border-[2px] border-[var(--accent)]" : ""
      } flight-card gap-5 w-full bg-[var(--white)] rounded-[5px] cursor-pointer h-fit py-[18px] px-[16px] flex flex-col`}
      onClick={onClick}
    >
      {isLayoverFlight && (
        <div className="flex justify-between border-b-[1px] border-[var(--gray3)] pb-6 border-dashed">
          <p className="text-[length:--body-s] text-[var(--black)] font-[400]">
            {data?.date?.length > 1
              ? `${formatDateHeader(data?.date[0])} - ${formatDateString(
                  data?.date[1],
                  false
                )}`
              : `${formatDateHeader(data?.date[0])}`}
          </p>
          <p className="text-[length:--body-s] text-[var(--black)] font-[400]">
            {data?.departure?.city} - {data?.arrival?.city}
          </p>
        </div>
      )}
      <div className="flex justify-between relative">
        <div className="flex items-center">
          <div
            className={`w-[63px] h-[50px] box-border flex flex-col justify-center  border-[1px] rounded-[4px]
               ${
                 data?.departure.time_indication === "Night"
                   ? "bg-[var(--gray3)] text-[var(--white)]"
                   : "bg-[var(--white)]"
               }`}
          >
            <h2 className="text-center text-[length:--number] font-[600]">
              {data?.departure?.time}
            </h2>
            <p
              className={`text-center text-[var(--gray3)] text-[length:--body-s]
               ${
                 data?.departure.time_indication === "Night" &&
                 "text-[var(--white)]"
               }`}
            >
              {data?.departure?.airport_code}
            </p>
          </div>
          <div className="relative w-[63px] h-[50px] box-border flex flex-col justify-center gap-2">
            <p className="text-center text-[length:--title-s] font-[600] text-[var(--gray3)]">
              {data?.flight_duration}
            </p>
            <div className="absolute left-[-3.5px]">
              <img src={line} alt="line" />
            </div>
            <p className="text-center text-[length:--title-xs] font-[600] text-[var(--gray3)]">
              {data?.layovers?.length > 0
                ? `${data?.layovers?.length - 1} ${t("progress.stops", {
                    count: data?.layovers?.length,
                  })}`
                : t("progress.direct")}
            </p>
          </div>
          <div
            className={`w-[63px] relative h-[50px] box-border flex flex-col justify-center  border-[1px] rounded-[4px]
            ${
              data?.arrival.time_indication === "Night"
                ? "bg-[var(--gray3)] text-[var(--white)]"
                : "bg-[var(--white)]"
            }`}
          >
            {data?.layovers?.length > 0 && (
              <div className="absolute w-[10px] h-[10px] bg-[var(--accent)] rounded-lg -top-1.5 -right-1 " />
            )}
            <h2 className="text-center text-[length:--number] font-[600]">
              {data?.arrival?.time}
            </h2>
            <p
              className={`text-center text-[var(--gray3)] text-[length:--body-s]
              ${
                data?.arrival.time_indication === "Night" &&
                "text-[var(--white)]"
              }`}
            >
              {data?.arrival?.airport_code}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          {isLayoverFlight ? (
            <div className="flex gap-2">
              <p className="text-[var(--accent)] text-[length:--title] font-[600]">
                {t("detail")}
              </p>
              <img src={detailArrow} alt="detail" />
            </div>
          ) : data?.discount_price !== null ? (
            <>
              <span className="text-center line-through text-[var(--gray3)] text-[11px]">
                {`${currency.key !== "Ks" ? currency.key : ""}${data?.price[
                  currency?.value?.toLowerCase()
                ]?.toLocaleString()} ${
                  currency.key === "Ks" ? currency?.key : ""
                }`}
              </span>
              <p className="text-center text-[var(--accent)] text-[length:--title]">
                {`${
                  currency.key !== "Ks" ? currency.key : ""
                }${data?.discount_price[
                  currency?.value?.toLowerCase()
                ]?.toLocaleString()} ${
                  currency.key === "Ks" ? currency?.key : ""
                }`}
              </p>
            </>
          ) : (
            <p className="text-center text-[var(--accent)] text-[length:--title]">
              {`${currency.key !== "Ks" ? currency.key : ""}${data?.price[
                currency?.value?.toLowerCase()
              ]?.toLocaleString()} ${
                currency.key === "Ks" ? currency?.key : ""
              }`}
            </p>
          )}
        </div>
      </div>
      {data?.layovers?.length > 0 && (
        <p className="relative text-[length:--title-s] text-[var(--gray7)] font-[600] -mt-4 left-[125px]">
          {formatDateHeader(data?.arrival?.date)}
        </p>
      )}
      <div className="flex h-fit justify-between">
        <div className="flex items-center gap-2">
          <div className="flex gap-1">
            {data?.layovers?.length > 0
              ? data?.airline?.map((item, index) => (
                  <div key={index}>{chooseLogo(item)}</div>
                ))
              : chooseLogo(data?.airline)}
          </div>
          <div className="flex items-center">
            <span className="text-[length:--body-s] h-full pr-2 text-[var(--gray3)] border-r-[1px] border-[var(--gray3)]">
              {data?.layovers?.length > 0
                ? `${data?.layovers?.length} Flights`
                : data?.airline_shortname}
            </span>
            <span className="pl-2 text-[length:--body-s] text-[var(--gray3)]">
              {data?.class}
            </span>
          </div>
        </div>
        <div className="flex gap-2">
          {data?.services?.included?.luggage?.isAllowed && (
            <img src={suitCase} alt="suit-case" />
          )}
          {data?.services?.included?.meal?.isIncluded && (
            <img src={food} alt="food" />
          )}
        </div>
      </div>
    </div>
  );
};

export default FlightCard;
