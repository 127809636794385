import React from "react";
import InfoHeader from "../../components/info-header/InfoHeader";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import { useTranslation } from "react-i18next";

import "./PaymentInfo.scss";

const HOW_THIS_WORKS = ["work_1", "work_2", "work_3", "work_4", "work_5"];

const BOOKING_INFO = ["find_1", "find_2"];

const PaymentInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <section className="payment-info flex w-full h-full bg-[var(--white1)] flex-col">
      <div className="py-6 px-5">
        <InfoHeader
          title={t("payment.payment")}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="flex flex-col scroll-content no-scrollbar pb-4">
        <div className="px-6 flex flex-col gap-5 mb-[74px]">
          <div className="flex flex-col gap-5 pb-8 border-b-[1px] border-dashed border-[var(--gray2)]">
            <div>
              <h4 className="text-[length:--body] text-center font-[400]">
                {t("payment.confirm_sms_payment")}
              </h4>
            </div>
            <div className="flex justify-center items-center bg-[var(--gray9)] rounded-[5px] pt-[18px] pb-[19px] ">
              <span className="text-[length:--title] font-[600]">
                TR - 010203 - 123
              </span>
            </div>
            <Footer />
          </div>
          <div className="flex flex-col px-3">
            <h1 className="text-[length:--title-s] font-[600]">
              {t("payment.how_this_work")}
            </h1>
            <ol type="1">
              {HOW_THIS_WORKS.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="flex gap-1 text-[length:--body-s] font-[400] leading-5"
                  >
                    <span>{t(`payment.${item}`)}</span>
                  </li>
                );
              })}
            </ol>

            <p></p>
          </div>
          <div className="flex flex-col px-3">
            <h1 className="text-[length:--title-s] font-[600]">
              {t("payment.how_to_find")}
            </h1>
            <ol>
              {BOOKING_INFO.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="flex gap-1 text-[length:--body-s] font-[400] leading-5"
                  >
                    <span>{t(`payment.${item}`)}</span>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
        <div className="flex px-6">
          <button className="w-full rounded-[6px] h-[48px] border-[1px] border-[var(--accent)] text-[var(--accent)] font-[600] text-[length:--title]">
            {t("payment.call_us", { phone: "+9591234567" })}
          </button>
        </div>
      </div>
    </section>
  );
};

export default PaymentInfo;
