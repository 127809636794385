export const SuitCase = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_1573_7345)">
        <path
          d="M17.9999 10C18.3679 10 18.6666 10.2987 18.6666 10.6667V12H21.9999C22.3679 12 22.6666 12.2987 22.6666 12.6667V21.3333C22.6666 21.7013 22.3679 22 21.9999 22H9.99992C9.63192 22 9.33325 21.7013 9.33325 21.3333V12.6667C9.33325 12.2987 9.63192 12 9.99992 12H13.3333V10.6667C13.3333 10.2987 13.6319 10 13.9999 10H17.9999ZM18.6666 13.3333H13.3333V20.6667H18.6666V13.3333ZM10.6666 13.3333V20.6667H11.9999V13.3333H10.6666ZM17.3333 11.3333H14.6666V12H17.3333V11.3333ZM19.9999 13.3333V20.6667H21.3333V13.3333H19.9999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1573_7345">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Food = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_1573_7340)">
        <path
          d="M22 9.33325V22.6666H20.6667V17.9999H18V13.3333C18 12.2724 18.4214 11.255 19.1716 10.5048C19.9217 9.75468 20.9391 9.33325 22 9.33325ZM20.6667 11.0199C20.1133 11.3333 19.3333 12.1133 19.3333 13.3333V16.6666H20.6667V11.0199ZM14 17.2666V22.6666H12.6667V17.2666C11.9139 17.1127 11.2373 16.7035 10.7514 16.1083C10.2655 15.513 10.0001 14.7683 10 13.9999V9.99992H11.3333V14.6666H12.6667V9.99992H14V14.6666H15.3333V9.99992H16.6667V13.9999C16.6666 14.7683 16.4011 15.513 15.9152 16.1083C15.4293 16.7035 14.7528 17.1127 14 17.2666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1573_7340">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AdditionalLuggage = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_1573_7350)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4001 23.3333H19.0668V22.6666H13.7335V23.3333H12.4001V22.6666H11.7335C10.9968 22.6666 10.4001 22.0699 10.4001 21.3333V12.6666C10.4001 11.9299 10.9968 11.3333 11.7335 11.3333H13.7335V9.99992C13.7335 9.63192 14.0321 9.33325 14.4001 9.33325H18.4001C18.7681 9.33325 19.0668 9.63192 19.0668 9.99992V11.3333V12.6666H11.7335V21.3333H21.0668V17.8H22.4001V21.3333C22.4001 22.0699 21.8035 22.6666 21.0668 22.6666H20.4001V23.3333ZM15.0668 10.6666H17.7335V11.3333H15.0668V10.6666Z"
          fill={color}
        />
        <path d="M22.4221 16V12H21.5332V16H22.4221Z" fill={color} />
        <path
          d="M20 14.3557L24 14.3557L24 13.4668L20 13.4668L20 14.3557Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1573_7350">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8.40015 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WheelChair = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="30.5"
        height="30.5"
        rx="15.25"
        stroke={color}
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_1573_7357)">
        <path
          d="M13.3334 14.8939V16.3566C12.5361 16.8179 12.0001 17.6799 12.0001 18.6666C12.0001 20.1399 13.1934 21.3333 14.6667 21.3333C15.6534 21.3333 16.5154 20.7973 16.9767 19.9999H18.4387C17.8901 21.5533 16.4081 22.6666 14.6667 22.6666C12.4574 22.6666 10.6667 20.8759 10.6667 18.6666C10.6667 16.9246 11.7801 15.4433 13.3334 14.8939ZM16.0001 19.3333C14.8954 19.3333 14.0001 18.4379 14.0001 17.3333V14.6666C14.0001 13.9706 14.3561 13.3573 14.8954 12.9993C14.3554 12.6426 14.0001 12.0293 14.0001 11.3333C14.0001 10.2286 14.8954 9.33325 16.0001 9.33325C17.1047 9.33325 18.0001 10.2286 18.0001 11.3333C18.0001 12.0293 17.6441 12.6426 17.1047 13.0006C17.6447 13.3573 18.0001 13.9706 18.0001 14.6666V17.9993L18.9561 17.9999C19.3881 17.9999 19.7914 18.2093 20.0401 18.5573L20.0994 18.6473L21.9047 21.6573L20.7621 22.3426L18.9554 19.3333L18.0001 19.3326L16.0001 19.3333ZM16.0001 13.9999C15.6321 13.9999 15.3334 14.2986 15.3334 14.6666V17.3333C15.3334 17.7013 15.6321 17.9999 16.0001 17.9999H16.6661L16.6667 14.6666C16.6667 14.2986 16.3681 13.9999 16.0001 13.9999ZM16.0001 10.6666C15.6321 10.6666 15.3334 10.9653 15.3334 11.3333C15.3334 11.7013 15.6321 11.9999 16.0001 11.9999C16.3681 11.9999 16.6667 11.7013 16.6667 11.3333C16.6667 10.9653 16.3681 10.6666 16.0001 10.6666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1573_7357">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ProfileSvg = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1227_3740)">
        <circle cx="16" cy="16" r="15" stroke={color} strokeWidth="2" />
        <path
          d="M7.11035 29.7777C7.11035 27.4203 8.04686 25.1593 9.71385 23.4923C11.3808 21.8253 13.6418 20.8888 15.9992 20.8888C18.3567 20.8888 20.6176 21.8253 22.2846 23.4923C23.9516 25.1593 24.8881 27.4203 24.8881 29.7777H22.6659C22.6659 28.0096 21.9635 26.3139 20.7133 25.0637C19.463 23.8134 17.7674 23.1111 15.9992 23.1111C14.2311 23.1111 12.5354 23.8134 11.2852 25.0637C10.035 26.3139 9.33257 28.0096 9.33257 29.7777H7.11035ZM15.9992 19.7777C12.3159 19.7777 9.33257 16.7944 9.33257 13.1111C9.33257 9.42773 12.3159 6.4444 15.9992 6.4444C19.6826 6.4444 22.6659 9.42773 22.6659 13.1111C22.6659 16.7944 19.6826 19.7777 15.9992 19.7777ZM15.9992 17.5555C18.4548 17.5555 20.4437 15.5666 20.4437 13.1111C20.4437 10.6555 18.4548 8.66662 15.9992 8.66662C13.5437 8.66662 11.5548 10.6555 11.5548 13.1111C11.5548 15.5666 13.5437 17.5555 15.9992 17.5555Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1227_3740">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const People = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_522_18329)">
        <path
          d="M4.66671 7.33334C4.27274 7.33334 3.88263 7.25575 3.51866 7.10498C3.15468 6.95422 2.82396 6.73324 2.54539 6.45466C2.26681 6.17609 2.04583 5.84537 1.89507 5.48139C1.7443 5.11742 1.66671 4.72731 1.66671 4.33334C1.66671 3.93938 1.7443 3.54927 1.89507 3.18529C2.04583 2.82132 2.26681 2.4906 2.54539 2.21202C2.82396 1.93345 3.15468 1.71247 3.51866 1.5617C3.88263 1.41094 4.27274 1.33334 4.66671 1.33334C5.46236 1.33334 6.22542 1.64941 6.78803 2.21202C7.35064 2.77463 7.66671 3.53769 7.66671 4.33334C7.66671 5.12899 7.35064 5.89205 6.78803 6.45466C6.22542 7.01727 5.46236 7.33334 4.66671 7.33334ZM11.6667 10C10.9595 10 10.2812 9.71906 9.78109 9.21896C9.28099 8.71886 9.00004 8.04059 9.00004 7.33334C9.00004 6.6261 9.28099 5.94782 9.78109 5.44773C10.2812 4.94763 10.9595 4.66668 11.6667 4.66668C12.374 4.66668 13.0522 4.94763 13.5523 5.44773C14.0524 5.94782 14.3334 6.6261 14.3334 7.33334C14.3334 8.04059 14.0524 8.71886 13.5523 9.21896C13.0522 9.71906 12.374 10 11.6667 10ZM11.6667 10.6667C12.4624 10.6667 13.2254 10.9827 13.788 11.5454C14.3506 12.108 14.6667 12.871 14.6667 13.6667V14H8.66671V13.6667C8.66671 12.871 8.98278 12.108 9.54539 11.5454C10.108 10.9827 10.8711 10.6667 11.6667 10.6667ZM4.66671 8.00001C5.10445 8.00001 5.5379 8.08623 5.94232 8.25375C6.34674 8.42126 6.7142 8.66679 7.02373 8.97632C7.33326 9.28585 7.57879 9.65331 7.74631 10.0577C7.91382 10.4622 8.00004 10.8956 8.00004 11.3333V14H1.33337V11.3333C1.33337 10.4493 1.68456 9.60144 2.30968 8.97632C2.93481 8.3512 3.78265 8.00001 4.66671 8.00001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_522_18329">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
