import React from "react";
import { useTranslation } from "react-i18next";

import SelectedFlightHeader from "../selected-flight-header/SelectedFlightHeader";

import line from "../../../../assets/icons/down-line.svg";
import ServiceCard from "../../../../components/service-card/ServiceCard";
import { add_ons, included } from "../../../../data/const";

const SelectedFlight = ({ data, onClick, onClose, currency, isLayover }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex items-center mb-6">
        <SelectedFlightHeader data={data} onClose={() => onClose(false)} />
      </div>
      <div className="airports mb-5 flex gap-6 items-start">
        <div className="flex flex-col gap-3">
          <p className="text-[length:--title-xs] font-[600]">
            {data?.departure?.time}
          </p>
          <div className="flex justify-center">
            <img src={line} alt="down-line" />
          </div>
          <p className="text-[length:--title-xs] font-[600]">
            {data?.arrival?.time}
          </p>
        </div>
        <div className="flex relative top-[-1px] flex-col gap-4">
          <div className="flex flex-col">
            <h3 className="text-[length:--title-s]">
              {data?.departure?.airport}
            </h3>
            <p className="text-[length:--title-xs] font-[600] text-[var(--gray5)]">
              {data?.departure?.city}
            </p>
          </div>
          <div className="flex items-center justify-between bg-[var(--gray5)] box-border w-fit h-[20px] p-[6px] ">
            <div className="flex gap-1 text-[length:--title-xs] font-[600] text-[var(--white)]">
              <span>{data?.flight_duration}</span>
              <div className="h-[15px] border-l-[2px] border-[var(--white)]" />
              <span>
                {data?.layovers?.length === 0 ? t("progress.direct_flight") : t("progress.layover")}
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3 className="text-[length:--title-s]">
              {data?.arrival?.airport}
            </h3>
            <p className="text-[length:--title-xs] font-[600] text-[var(--gray5)]">
              {data?.arrival?.city}
            </p>
          </div>
        </div>
      </div>
      <div className="services flex w-full flex-col">
        <h2 className="text-[length:--title-s] font-[600] mb-1 text-[var(--gray5)]">
          {t("progress.included")}
        </h2>
        <div className="flex flex-col mb-6">
          {Object.entries(data?.services?.included)?.map(
            ([key, value], index) => {
              return (
                <ServiceCard
                  key={key}
                  data={{
                    showText: included[index],
                    apiValues: value,
                  }}
                />
              );
            }
          )}
        </div>
        <h2 className="text-[length:--title-s] font-[600] mb-1 text-[var(--gray5)]">
          {t("progress.add_on")}
        </h2>
        <div className="flex flex-col mb-6">
          {Object.entries(data?.services?.addons)?.map(
            ([key, value], index) => {
              return (
                <ServiceCard
                  key={key}
                  data={{
                    showText: add_ons[index],
                    apiValues: value,
                  }}
                />
              );
            }
          )}
        </div>
      </div>
      {!isLayover && (
        <div className="flex w-full justify-center items-center pb-5">
          <button
            className="flex box-border h-[48px] w-full items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
            onClick={() => {
              onClick(data);
            }}
          >
            {t(`progress.select_flight_for`, {
              price: `${
                currency.key !== "Ks" ? currency.key : ""
              }${data?.total_price[
                currency?.value?.toLowerCase()
              ]?.toLocaleString()}${
                currency.key === "Ks" ? currency?.key : ""
              }`,
            })}
          </button>
        </div>
      )}
    </div>
  );
};

export default SelectedFlight;
