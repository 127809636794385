import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ServiceCard = ({ data }) => {
  const { t } = useTranslation();
  const currency = useSelector((state) => state.currency);
  return (
    <div className="flex w-full h-fit justify-between py-3 border-b-[1px] border-[var(--gray4)]">
      <div className="flex gap-3 items-center">
        <div className="w-8 h-8 flex items-center justify-center rounded-[100px]">
          {data?.showText?.icon}
        </div>
        <div className="flex flex-col">
          <h1 className="font-[500] text-[length:--title-s] text-[var(--gray8)]">
            {t(`progress.${data?.showText?.name}`)}
          </h1>
          <p className="font-[400] text-[length:--body-s] text-[var(--gray2)]">
            {t(`progress.${data?.apiValues?.description}`)}
          </p>
        </div>
      </div>
      <p
        className={`${
          data?.apiValues?.isAllowed || data?.apiValues?.isIncluded
            ? "text-[var(--green2)]"
            : "text-[var(--accent)]"
        } text-[length:--body-s] font-[700]`}
      >
        {data?.apiValues?.isAllowed || data?.apiValues?.isIncluded
          ? `${t("progress.included")}`
          : data?.apiValues?.cost
          ? `${
              currency.key !== "Ks" ? currency.key : ""
            }${data?.apiValues?.cost[
              currency?.value?.toLowerCase()
            ]?.toLocaleString()} ${currency.key === "Ks" ? currency?.key : ""}`
          : `${t("progress.not_included")}`}
      </p>
    </div>
  );
};

export default ServiceCard;
