import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setFlightInfo } from "../reducers/flightInfoReducer";

const useInfo = (data) => {
  const dispatch = useDispatch();
  const { tripType, trip, startDate, endDate, persons, citizens } = data;
  useEffect(() => {
    dispatch(setFlightInfo(data));
    // eslint-disable-next-line
  }, [tripType, trip, startDate, endDate, persons, citizens, dispatch]);
};

export default useInfo;
