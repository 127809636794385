import React, { useState } from "react";

import TextInput from "../inputs/text-input/TextInput";
import DropdownInput from "../inputs/dropdown-input/DropDownInput";

//const
import { genders, nrc_prefixs, nrc_suffixs } from "../../data/const";
import { Popup, PopupContent } from "../popup/popup";
import { getNrcRegions } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const UserForm = ({ data, onChange, onClick, isValidate }) => {
  const { t } = useTranslation();
  const [isPopUp, setIsPopUp] = useState(false);
  const translatedOptions = genders.map((option) => {
    return {
      ...option,
      label: t(`${option.label}`),
    };
  });
  return (
    <div className="relative w-full h-[620px] flex flex-col gap-6 border-[1px] border-[var(--gray0)] px-3 py-3 rounded-[8px]">
      <p className="flex gap-1 text-[length:--body-s] text-[var(--accent)] font-[400] leading-5">
        {t("profile.edit_profile_alert")}
      </p>
      <div className="flex flex-col gap-4">
        <div className="flex w-full items-center justify-between gap-3">
          <div>
            <TextInput
              label={t("traveller-info.first_name")}
              data={{ value: data?.firstName, name: "firstName" }}
              onChange={onChange}
              required={true}
            />
          </div>
          <div>
            <TextInput
              label={t("traveller-info.last_name")}
              data={{ value: data?.lastName, name: "lastName" }}
              onChange={onChange}
              required={true}
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-between gap-3">
          <div className="flex-1">
            <TextInput
              label={t("traveller-info.passport")}
              data={{ value: data?.passport, name: "passport" }}
              onChange={onChange}
              required={true}
            />
          </div>
          <div className="flex-1">
            <DropdownInput
              label={t("traveller-info.gender")}
              data={{ value: data?.gender }}
              options={translatedOptions}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="flex w-full flex-col justify-between gap-2">
          <label className="font-[600] text-[length:--body-s]">
            {t("traveller-info.nrc")}
          </label>
          <div className="flex w-full justify-between gap-2">
            <div className="w-[66px]">
              <DropdownInput
                data={{ value: data?.nrc_prefix }}
                options={nrc_prefixs}
                onChange={onChange}
              />
            </div>
            <div className="w-[84px]">
              <DropdownInput
                data={{ value: data?.nrc_middle }}
                options={getNrcRegions(data?.nrc_prefix?.nrcCode)}
                onChange={onChange}
              />
            </div>
            <div className="w-[70px]">
              <DropdownInput
                data={{ value: data?.nrc_suffix }}
                options={nrc_suffixs}
                onChange={onChange}
              />
            </div>
            <div className="w-[104px]">
              <TextInput
                data={{ value: data?.nrcNumber, name: "nrcNumber" }}
                onChange={onChange}
                required={true}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-between gap-3">
          <TextInput
            label={t("traveller-info.contact_phone")}
            data={{ value: data?.contactPhone, name: "contactPhone" }}
            onChange={onChange}
            type="number"
            required={true}
          />
        </div>
        <div className="flex w-full items-center justify-between gap-3">
          <TextInput
            label={t("traveller-info.contact_mail")}
            data={{ value: data?.contactMail, name: "contactMail" }}
            onChange={onChange}
            type="text"
            required={true}
          />
        </div>
      </div>
      <button
        type="button"
        className={`flex box-border h-[48px] w-full items-center justify-center
                    text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]
                    ${isValidate ? "bg-[var(--accent)]" : "bg-[var(--gray4)]"}`}
        onClick={(e) => {
          if (isValidate) {
            onClick(e);
          } else {
            setIsPopUp(true);
          }
        }}
      >
        {t("save")}
      </button>
      {isPopUp && (
        <Popup classes="relative">
          <PopupContent
            isShow={isPopUp}
            classes={
              "fixed z-[99] w-full flex justify-center items-center top-0 left-0 h-full"
            }
          >
            <div className="home-popup w-[375px] flex flex-col relative items-center justify-center  h-full">
              <div className="absolute z-[-1] w-full h-full bg-[var(--black)] opacity-[50%]" />
              <div className="box-border px-[46px] pt-[36px] w-[311px] h-[270px] bg-[var(--white)] rounded-[4px]">
                <h1 className="font-[700] mb-[32px] flex justify-center text-[length:--title]">
                  {t("incomplete_information.title")}
                </h1>
                <p
                  className="flex flex-wrap text-center text-[var(--black)] text-[length:--body] font-[400] 
                                items-center pb-[30px] justify-center"
                >
                  {t("profile.edit_profile_alert")}
                </p>
                <div className="flex w-full justify-center">
                  <button
                    className="flex box-border h-[48px] w-[163px] items-center justify-center
                    bg-[var(--accent)] text-[length:--title] rounded-[6px] text-[var(--white)] font-[600]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsPopUp(false);
                    }}
                  >
                    {t('ok')}
                  </button>
                </div>
              </div>
            </div>
          </PopupContent>
        </Popup>
      )}
    </div>
  );
};

export default UserForm;
