import { AdditionalLuggage, Food, SuitCase, WheelChair } from "./icon";

export const home_dropdown = [
  { name: "my", value: "Myanmar", key: "MM" },
  { name: "zh", value: "Chinese", key: "CHN" },
  { name: "en", value: "English", key: "USA" },
];

export const home_radio_buttons = [
  {
    name: "one_way",
    key: "one way",
    isChecked: false,
  },
  {
    name: "round_trip",
    key: "round trip",
    isChecked: true,
  },
];

export const quantity_picker_radio_buttons = [
  {
    name: "myanmar",
    key: "myanmar citizen",
    isChecked: true,
  },
  {
    name: "foreigner",
    key: "foreigner",
    isChecked: false,
  },
];

export const currency_data = [
  {
    flag: "MM",
    value: "MMK",
    key: "Ks",
    name: "mmk",
  },
  {
    flag: "US",
    value: "USD",
    key: "$",
    name: "usd",
  },
  {
    flag: "CHN",
    value: "RMB",
    key: "¥",
    name: "rmb",
  },
];

export const included = [
  {
    name: "luggage_allowance",
    icon: <SuitCase color={"var(--gray3)"} />,
  },
  {
    name: "meal",
    icon: <Food color={"var(--gray3)"} />,
  },
];

export const add_ons = [
  {
    name: "additional_luggage",
    icon: <AdditionalLuggage color={"var(--gray3)"} />,
  },
  {
    name: "chair_service",
    icon: <WheelChair color={"var(--gray3)"} />,
  },
];

export const genders = [
  {
    label: "male",
    value: "male",
    name: "gender",
  },
  {
    label: "female",
    value: "female",
    name: "gender",
  },
];

export const nrc_prefixs = [
  {
    label: "၁/",
    value: "၁/",
    nrc_code: "1",
    name: "nrc_prefix",
  },
  {
    label: "၂/",
    value: "၂/",
    nrc_code: "2",
    name: "nrc_prefix",
  },
  {
    label: "၃/",
    value: "၃/",
    nrc_code: "3",
    name: "nrc_prefix",
  },
  {
    label: "၄/",
    value: "၄/",
    nrc_code: "4",
    name: "nrc_prefix",
  },
  {
    label: "၅/",
    value: "၅/",
    nrc_code: "5",
    name: "nrc_prefix",
  },
  {
    label: "၆/",
    value: "၆/",
    nrc_code: "6",
    name: "nrc_prefix",
  },
  {
    label: "၇/",
    value: "၇/",
    nrc_code: "7",
    name: "nrc_prefix",
  },
  {
    label: "၈/",
    value: "၈/",
    nrc_code: "8",
    name: "nrc_prefix",
  },
  {
    label: "၉/",
    value: "၉/",
    nrc_code: "9",
    name: "nrc_prefix",
  },
  {
    label: "၁၀/",
    value: "၁၀/",
    nrc_code: "10",
    name: "nrc_prefix",
  },
  {
    label: "၁၁/",
    value: "၁၁/",
    nrc_code: "11",
    name: "nrc_prefix",
  },
  {
    label: "၁၂/",
    value: "၁၂/",
    nrc_code: "12",
    name: "nrc_prefix",
  },
  {
    label: "၁၃/",
    value: "၁၃/",
    nrc_code: "13",
    name: "nrc_prefix",
  },
  {
    label: "၁၄/",
    value: "၁၄/",
    nrc_code: "14",
    name: "nrc_prefix",
  },
];

export const nrc_middle = [
  {
    label: "ခအဇ",
    value: "ခအဇ",
    name: "nrc_middle",
  },
  {
    label: "ခမစ",
    value: "ခမစ",
    name: "nrc_middle",
  },
  {
    label: "မနမ",
    value: "မနမ",
    name: "nrc_middle",
  },
];

export const nrc_suffixs = [
  {
    label: "နိုင်",
    value: "နိုင်",
    name: "nrc_suffix",
  },
  {
    label: "ဧည့်",
    value: "ဧည့်",
    name: "nrc_suffix",
  },
  {
    label: "ပြု",
    value: "ပြု",
    name: "nrc_suffix",
  },
];
