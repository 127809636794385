import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flag: "US",
  value: "USD",
  key: "$",
  name: "USD ($)",
};

export const currencySlice = createSlice({
  name: "currency",
  initialState: initialState,
  reducers: {
    setCurrency: (state, action) => {
      return action.payload;
    },
  },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;
